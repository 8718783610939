import React from 'react';
import Typography, { TypographyProps } from '@mui/material/Typography';

type TypoVariants = TypographyProps['variant'] & ['small'];

const Typo: React.FC<
  { variant?: TypographyProps['variant'] } & TypographyProps
> = ({ variant, ...props }) => {
  return (
    <Typography {...props} variant={variant}>
      {props.children}
    </Typography>
  );
};

export default Typo;
