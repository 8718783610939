type ArchivePageState = {
  emergencies: any[];
  page: number;
  pageLimit: number;
  pageTotal: number;
};

export const ArchivePageEmptyState: ArchivePageState = {
  emergencies: [],
  page: 1,
  pageLimit: 25,
  pageTotal: 0,
};

export default ArchivePageState;
