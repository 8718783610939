import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import {
  Archive as ArchiveIcon,
  ChevronRight as ChevronRightIcon,
  Edit as EditIcon,
  NotificationsActive as NotificationsActiveIcon,
} from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  Box,
  CircularProgress,
  Container,
  Divider,
  Fade,
  IconButton,
  Stack,
} from '@mui/material';
import moment from 'moment';
import _ from 'lodash';
import { IEmergencyResponse } from 'common/interfaces/EmergencyResponse';
//import { IEmergencyDetails, IEmergencyRequest} from 'common/interfaces/EmergencyRequest';
import { responseIdFormat } from 'common/utils/String';
import { PaperLayout } from 'components/layouts';
import { Button, Modal, TitleText, Typo } from 'components/primitives';
import {
  AcceptResources,
  DismissOfferResources,
  RequestItemBox,
  ResponseItemBox,
  ProvidedResources,
} from 'components/fragments';
import { RequestResourceSnippet } from 'components/snippets';
import { ConfirmationModal } from 'components/modals';
import {
  EmergencyDetailsModal,
  EmergencyDetailsProps,
} from './EmergencyDetails.props';
import apiFetch, {
  updateEmergencyResponse,
  updateEmergencyRequest,
  sendNotificationReqeustByStation,
  sendNotificationRequestAll,
} from 'services/apiFetch';
import useStyles from './EmergencyDetails.styles';
import ResourceState from 'common/enums/ResourceState';

const ArchiveButton = ({
  active = false,
  awaitArchive = false,
  className,
  onClick,
}: {
  active?: boolean;
  awaitArchive?: boolean;
  className?: string;
  onClick: () => void;
}) => {
  return (
    <IconButton
      className={clsx(className)}
      disableRipple={active}
      onClick={onClick}
    >
      {awaitArchive ? (
        <CircularProgress size={18} />
      ) : (
        <ArchiveIcon fontSize="medium" color={active ? 'primary' : 'inherit'} />
      )}
    </IconButton>
  );
};

const emailTemplate =
  'Attention Chief and/or designee, thank you for your support and offer for the requested Firefighter Mobilization response. ' +
  'At this time, the response is full. Please continue to monitor for further requests as this incident continues to expand. ' +
  'Thank you for your continued support.';

const EmergencyDetailsView: React.FC<EmergencyDetailsProps> = ({
  awaitArchive,
  emergencyData,
  emergencyResponses = [],
  modalFocus,
  reqStationData,
  ...props
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const classes = useStyles();
  const [awaitSave, setAwaitSave] = useState<boolean>(false);
  const [emailMsg, setEmailMsg] = useState<string>(emailTemplate);
  const [remindStationName, setRemindStationName] = useState<string>('');
  const [reminders, setReminders] = useState<string[]>([]);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const archived = emergencyData?.archived === 1;
  const createdAt = emergencyData?.createDate;

  let isDetailsVisible = false;
  if (
    emergencyData &&
    emergencyData?.responseCount !== undefined &&
    emergencyData?.responseCount > 0
  ) {
    isDetailsVisible = true;
  }

  const counties = emergencyData?.details?.regions?.reduce(
    (sum, r: any) => sum + r.counties?.length,
    0
  );
  const regions = emergencyData?.details?.regions?.length;
  const requestNumber = emergencyData?.requestId || '--';
  const responses = emergencyResponses?.length;
  const eventName = emergencyData?.details?.eventName || '';
  const { equipment, personnel } = emergencyData?.details || {};
  const reqPersonnel = _.sumBy(personnel, (value: any) =>
    parseInt(value.number)
  );
  const reqEquipment = _.sumBy(equipment, (value: any) =>
    parseInt(value.number || 0)
  );
  const totalRequestCount: number = reqStationData?.length || 0;
  const combinePersonnel = emergencyData?.responseList?.flatMap((p: any) => {
    const pa = [];
    pa.push(p.details.personnel.flatMap((f: any) => f?.items));
    const acceptP = pa.flatMap((a: any) => a);
    return acceptP.filter((a: any) => a?.accepted);
  });
  const combineEquipment = emergencyData?.responseList?.flatMap((e: any) => {
    const eq = [];
    eq.push(e.details.equipment.flatMap((f: any) => f?.items));
    const acceptE = eq.flatMap((a: any) => a);
    return acceptE.filter((a: any) => a?.accepted);
  });
  const ttlApprovePersonnel: number = combinePersonnel?.length || 0;
  const ttlApproveEquipment: number = combineEquipment?.length || 0;

  useEffect(() => {
    if (emergencyData && emergencyData.responseList) {
      const nr = [...reminders];
      emergencyData?.responseList.map((v: any) => {
        if (v.stationId && parseInt(v.stationId) > 0) {
          nr.push(v.stationId);
        }
      });
      // console.log('nr:', nr);
      setReminders(nr);
    }
  }, [emergencyData]);

  const onEditRequest = () => {
    if (emergencyData) {
      navigate('edit', {
        state: { emergencyData },
      });
    }
  };
  const onOpenResponseModal = (
    accept: boolean,
    payload: IEmergencyResponse
  ) => {
    props.handleModalFocus({
      modal: accept
        ? EmergencyDetailsModal.ACCEPT_RESOURCES
        : EmergencyDetailsModal.DISMISS_RESOURCES,
      payload,
    });
  };
  const onOpenArchiveModal = () => {
    if (!archived) {
      props.handleModalFocus({ modal: EmergencyDetailsModal.ARCHIVE });
    }
  };
  const onOpenSendReminderModal = (stationName: string, payload: any) => {
    setRemindStationName(stationName);
    props.handleModalFocus({ modal: EmergencyDetailsModal.REMIND, payload });
  };
  const onOpenProvidedModal = (payload: any) => {
    if (payload?.responseCount && payload?.responseCount > 0)
      props.handleModalFocus({
        modal: EmergencyDetailsModal.PROVIDED_RESOUCES,
        payload,
      });
  };
  const onConfirmArchive = (confirm: boolean) => {
    if (confirm) {
      props.handleConfirmArchive();
    }
    props.resetModalFocus();
  };
  const onAcceptResources = async (accept: boolean, payload: any) => {
    if (accept && !awaitSave) {
      setAwaitSave(true);
      const details = payload.details;
      const { equipment, personnel } = details;
      const updatedEquipment = equipment.map((e: any) => {
        return {
          ...e,
          totalAcceptedEquipment: e.items.filter((c: any) => c.accepted).length,
        };
      });
      const newPayload = {
        ...payload,
        updateDate: new Date().toISOString(),
        details: {
          ...details,
          equipment: updatedEquipment,
          status: ResourceState.ACCEPT,
        },
      };
      const emergencyDataDetails = emergencyData?.details;
      const updateEmergency = {
        ...emergencyData,
        details: {
          ...emergencyDataDetails,
          equipment: updatedEquipment,
          personnel,
          status: ResourceState.ACCEPT,
        },
      };
      await apiFetch(
        updateEmergencyResponse(newPayload, {
          responseId: payload.responseId,
          authorize: true,
        })
      )
        .then(({ data, status }) => {
          // Todo: Do something after update of accept response
          console.log('Update Accept Response:', status);
          // Update emergency request with new response
          apiFetch(updateEmergencyRequest(updateEmergency));
        })
        .catch((error) => console.log(error))
        .finally(() => {
          setAwaitSave(false);
          props.refreshEmergencyData();
        });
    }
    setAwaitSave(false);
    props.refreshEmergencyData();
    props.resetModalFocus();
  };
  const onDeclineResources = async (accept: boolean, payload: any) => {
    if (accept && !awaitSave) {
      setAwaitSave(true);
      const details = payload.details;
      const newPayload = {
        ...payload,
        updateDate: new Date().toISOString(),
        details: {
          ...details,
          status: ResourceState.DECLINE,
          declineMessage: emailMsg,
        },
      };

      await apiFetch(
        updateEmergencyResponse(newPayload, {
          responseId: payload.responseId,
          authorize: true,
        })
      )
        .then(({ data, status }) => {
          // Todo: Do something after update of decline response
          console.log('Update Decline Response:', status);
        })
        .catch((error) => console.log(error))
        .finally(() => {
          setAwaitSave(false);
          props.refreshEmergencyData();
        });
    }
    props.resetModalFocus();
  };
  const onCloseProvidedResources = (processed: boolean) => {
    props.resetModalFocus();
    if (processed) {
      props.refreshEmergencyData();
    }
  };

  const changeTextEmailBody = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailMsg(e.target.value);
  };

  const onConfirmSendReminder = async (accept: boolean, payload: any) => {
    if (accept) {
      if (payload?.remindAll) {
        const endpoint = sendNotificationRequestAll({
          requestId: requestNumber,
        });
        const tempNr = reqStationData.map((s: any) => s.stationId);
        const resAll = await apiFetch(endpoint)
          .then((res) => res.status)
          .catch((e) => console.log(e));
        if (resAll) {
          const nr = reminders.concat(tempNr);
          setReminders(nr);
        }
        return props.resetModalFocus();
      }

      if (!payload?.remindAll) {
        const endpoint = sendNotificationReqeustByStation({
          requestId: requestNumber,
          stationId: payload?.stationId,
        });
        const res = await apiFetch(endpoint)
          .then(({ status }) => status)
          .catch((e) => console.log(e));
        if (res === 200) {
          const nr = [...reminders, payload?.stationId];
          setReminders(nr);
        }
        return props.resetModalFocus();
      }
    }
    props.resetModalFocus();
  };

  return (
    <React.Fragment>
      <Container component="main">
        <Fade in={!!emergencyData}>
          <Box mt={3}>
            <PaperLayout elevation={1} sx={{ p: 2 }}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typo variant="h4">{`${eventName} Request #${requestNumber}`}</Typo>
                {isMobile ? (
                  <ArchiveButton
                    active={archived}
                    awaitArchive={awaitArchive}
                    className={classes.archive}
                    onClick={onOpenArchiveModal}
                  />
                ) : (
                  <Box className={classes.titleMeta}>
                    <Typo variant="body2" color="textSecondary">
                      Created on:{' '}
                      <strong>
                        {createdAt ? moment(createdAt).format('L') : '--'}
                      </strong>
                      {' at '}
                      <strong>
                        {createdAt ? `${moment(createdAt).format('LT')}` : '--'}
                      </strong>
                    </Typo>
                    <Typo variant="body2" color="textSecondary">
                      Sent to:{' '}
                      <strong>{`${regions} Regions / ${counties} Counties`}</strong>
                    </Typo>
                    <Typo variant="body2" color="textSecondary">
                      Responses: <strong>{responses}</strong>
                    </Typo>
                  </Box>
                )}
              </Stack>
              {!isMobile && <Divider sx={{ my: 1 }} />}
              <Stack
                direction={isMobile ? 'column' : 'row'}
                alignItems="center"
                justifyContent="space-between"
                sx={{ mt: 1 }}
              >
                <Stack
                  direction="row"
                  columnGap={1}
                  className={classes.resourcesWrapper}
                >
                  <RequestResourceSnippet
                    title="Personnel"
                    className={classes.resourceItem}
                    meta={[
                      { label: 'Requested', value: reqPersonnel },
                      { label: 'Accepted', value: ttlApprovePersonnel },
                    ]}
                  />
                  <RequestResourceSnippet
                    title="Equipment"
                    className={classes.resourceItem}
                    meta={[
                      { label: 'Requested', value: reqEquipment },
                      { label: 'Accepted', value: ttlApproveEquipment },
                    ]}
                  />
                  {!isMobile ? (
                    isDetailsVisible ? (
                      <IconButton
                        className={classes.more}
                        onClick={() => onOpenProvidedModal(emergencyData)}
                      >
                        <ChevronRightIcon fontSize="small" />
                        <Typo
                          textAlign="center"
                          color="textSecondary"
                          variant="body2"
                        >
                          <small>See details</small>
                        </Typo>
                      </IconButton>
                    ) : null
                  ) : null}
                </Stack>
                <Stack
                  direction="row"
                  columnGap={1}
                  justifyContent="flex-end"
                  sx={{ width: { xs: '100%' } }}
                >
                  {!isMobile && (
                    <ArchiveButton
                      active={archived}
                      awaitArchive={awaitArchive}
                      className={classes.archive}
                      onClick={onOpenArchiveModal}
                    />
                  )}
                  <Button
                    sx={{ minWidth: '140px' }}
                    startIcon={<EditIcon fontSize="small" />}
                    onClick={onEditRequest}
                    fullWidth={isMobile}
                    alternate
                  >
                    Edit request
                  </Button>
                  <Button
                    sx={{ minWidth: '140px' }}
                    startIcon={<NotificationsActiveIcon fontSize="small" />}
                    fullWidth={isMobile}
                    onClick={() =>
                      onOpenSendReminderModal(
                        `${totalRequestCount} Firestations`,
                        {
                          remindAll: true,
                        }
                      )
                    }
                  >
                    Remind all
                  </Button>
                </Stack>
              </Stack>
            </PaperLayout>
          </Box>
        </Fade>

        {/* Emergency Response Area */}
        <Fade in={responses > 0}>
          <Box>
            <TitleText title="Offers">
              <Box className={classes.responseBadge}>
                <Typo variant="h4">{responses}</Typo>
              </Box>
            </TitleText>

            <Stack spacing={2}>
              {emergencyResponses.map((r: any) => {
                const countyName = r?.details.fireStationInfo?.county || '';
                const cityName = r?.details.fireStationInfo?.details.city || '';
                const stationName =
                  r?.details.fireStationInfo?.details.name || '';
                return (
                  <ResponseItemBox
                    key={r.responseId}
                    name={`Offer #${responseIdFormat(r.responseId)}`}
                    emergencyData={r}
                    // TODO: this will require addition data from the api
                    meta={[
                      {
                        label: 'Station',
                        value: stationName,
                      },
                      { label: 'City', value: cityName },
                      { label: 'County', value: countyName },
                    ]}
                    paperProps={{
                      elevation: 1,
                      sx: { px: 2, minHeight: 74 },
                    }}
                    sx={{ cursor: 'pointer' }}
                    onResponse={(ok) => onOpenResponseModal(ok, r)}
                    // viewProvided={() => onOpenProvidedModal(r)}
                  />
                );
              })}
            </Stack>
          </Box>
        </Fade>

        {/* Send Request Area */}
        <TitleText title="Sent requests">
          <Box className={classes.responseBadge}>
            <Typo variant="h4">{totalRequestCount}</Typo>
          </Box>
        </TitleText>
        <Stack mb={3} spacing={2}>
          {reqStationData?.map((d: any, i: number) => {
            // console.log('reqStationData: ', d);
            return (
              <RequestItemBox
                key={i}
                elevation={1}
                emergencyData={{}}
                name={d?.name}
                meta={[
                  {
                    label: `County ${d?.county}`,
                    value: `${d?.city}, SC`,
                  },
                  { label: 'Address', value: d?.address1 },
                  { label: 'Create Date', value: d?.updateDate },
                ]}
                paperProps={{ elevation: 1, sx: { px: 2, minHeight: '74px' } }}
                sx={{ cursor: 'pointer' }}
              >
                <Button
                  sx={{ minWidth: '120px' }}
                  startIcon={<NotificationsActiveIcon fontSize="small" />}
                  onClick={() => onOpenSendReminderModal(d?.name, d)}
                  disabled={reminders?.includes(d.stationId)}
                >
                  Remind
                </Button>
              </RequestItemBox>
            );
          })}
        </Stack>
      </Container>

      <ConfirmationModal
        open={modalFocus.modal === EmergencyDetailsModal.ARCHIVE}
        title="Archive confirmation"
        onConfirm={(e, ok) => onConfirmArchive(ok)}
      >
        <React.Fragment>
          <Typo variant="body1" color="common.black" mb={2}>
            Please confirm that you want to archive this incident
          </Typo>
          <Typo variant="body2" color="common.black">
            You can review the information in the archive section
          </Typo>
        </React.Fragment>
      </ConfirmationModal>

      <ConfirmationModal
        open={modalFocus.modal === EmergencyDetailsModal.REMIND}
        title="Send reminder?"
        onConfirm={(e, ok) => onConfirmSendReminder(ok, modalFocus?.payload)}
      >
        <React.Fragment>
          <Typo variant="body1" color="common.black" mb={3}>
            You are about to send a reminder to{' '}
            <strong>{remindStationName}</strong>. Do you want to continue?
          </Typo>
        </React.Fragment>
      </ConfirmationModal>

      <Modal
        open={modalFocus.modal === EmergencyDetailsModal.DISMISS_RESOURCES}
      >
        <DismissOfferResources
          onOk={(e, ok) => onDeclineResources(ok, modalFocus?.payload)}
          awaitSave={awaitSave}
          emailMsg={emailMsg}
          changeTextEmailBody={changeTextEmailBody}
        />
      </Modal>

      <Modal open={modalFocus.modal === EmergencyDetailsModal.ACCEPT_RESOURCES}>
        <AcceptResources
          emergencyResponse={modalFocus?.payload || undefined}
          onAccept={(x: boolean, payload: any) => onAcceptResources(x, payload)}
          awaitSave={awaitSave}
        />
      </Modal>

      <Modal
        open={modalFocus.modal === EmergencyDetailsModal.PROVIDED_RESOUCES}
      >
        <ProvidedResources
          emergencyResponse={modalFocus?.payload || undefined}
          onClose={(x: boolean) => onCloseProvidedResources(x)}
        />
      </Modal>
    </React.Fragment>
  );
};

export default EmergencyDetailsView;
