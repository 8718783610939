import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      position: 'relative',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      margin: 0,
      [theme.breakpoints.down('md')]: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        flexDirection: 'column',
      },
      '& .wrapper': {
        flex: 1,
      },
      '& .slider': {
        position: 'absolute',
        top: '100%',
        right: 0,
        transition: '0.5s',
        '& button': {
          minWidth: 100,
        },
      },
      '&:hover': {
        '& .slider': {
          top: 18,
          transition: '0.5s',
        },
      },
      '& .float-right': {
        position: 'absolute',
        top: 18,
        right: 0,
        '& button': {
          minWidth: 100,
        },
      },
    },
    meta: {
      columnGap: theme.spacing(1),
      '& strong': {
        marginLeft: theme.spacing(1),
        color: theme.palette.grey[700],
      },
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        padding: '8px 0',
      },
    },
    leftColumn: {
      flex: '0 1 auto',
      minWidth: 280,
      minHeight: 48,
      [theme.breakpoints.down('md')]: {
        minWidth: 'unset',
        width: '100%',
      },
    },
    rightColumn: {
      flex: 1,
      display: 'flex',
      justifyContent: 'flex-end',
      flexDirection: 'row',
      columnGap: '10px',
      [theme.breakpoints.down('md')]: {
        flex: 'unset',
        flexDirection: 'column',
        rowGap: theme.spacing(0.5),
      },
    },
    resources: {
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    greyBox: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: theme.spacing(1),
      width: '120px',
      height: '40px',
      borderRadius: '5px',
      backgroundColor: theme.palette.grey[100],
      color: theme.palette.grey[900],
      [theme.breakpoints.down('sm')]: {
        flex: 1,
      },
      '& p:first-child': {
        flex: '1 1 100%',
      },
      '& p:last-child': {
        flex: '0 1 24px',
        display: 'block',
      },
      '& svg': {
        flex: '0 1 24px',
        color: theme.palette.grey[700],
      },
    },
    squareButton: {
      padding: 0,
      borderRadius: 8,
      minWidth: 42,
    },
    responseButtons: {
      right: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        position: 'absolute',
        top: theme.spacing(1),
        right: theme.spacing(2),
      },
    },
  };
});

export default useStyles;
