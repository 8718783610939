import React, { useCallback } from 'react';
import { useSnackbar, SnackbarContent } from 'notistack';
import { Typography, Card, CardActions, Box, IconButton } from '@mui/material';
import { CloseOutlined as CloseIcon } from '@mui/icons-material';
import useStyles from './Toast.style';

const ToastRefComponent = (props: any, ref: React.ForwardedRef<any>) => {
  const classes = useStyles();
  const { closeSnackbar } = useSnackbar();
  const handleDismiss = useCallback(() => {
    closeSnackbar(props.id);
  }, [props.id, closeSnackbar]);

  return (
    <SnackbarContent ref={ref} className={classes.root}>
      <Card className={classes.toastContainer}>
        <CardActions classes={{ root: classes.actionRoot }}>
          <Typography variant="subtitle2">{props.message}</Typography>
          <Box className={classes.icons}>
            <IconButton onClick={handleDismiss}>
              <CloseIcon />
            </IconButton>
          </Box>
        </CardActions>
      </Card>
    </SnackbarContent>
  );
};

const Toast = React.forwardRef(ToastRefComponent);

export default Toast;
