/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Dispatch } from 'redux';
import { QueryState } from 'common/enums';
import apiFetch, { getEmergencyRequests } from 'services/apiFetch';
import { pageActions } from 'store/actions';
import PageState from 'store/state/PageState';
import EmergencyPageState, {
  EmergencyPageEmptyState,
} from 'store/state/pages/EmergencyPageState';

const identifyPage =
  (payload: PageState<EmergencyPageState>) => async (dispatch: Dispatch) => {
    const identity = {
      ...EmergencyPageEmptyState,
      ...payload,
    };
    dispatch(pageActions.identify(identity));
  };

export default identifyPage;
