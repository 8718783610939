import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  FireStationDetailProps,
  FireStationDetailPublicProps,
} from './FireStationDetail.props';
import {
  FireStationForm,
  FireStationFormProps,
  payloadContact,
  payloadFireStationContact,
} from 'helpers/forms/firestationForm';
import { loadFireStationById } from 'thunks/firestation/getFireStation';
import {
  createContacts,
  createFireStation,
  deleteFireStation,
  updateFireStation,
} from 'thunks/firestation/createFireStation';
import { map, uniq } from 'lodash';
import FireStationDetailView from './FireStationDetail.view';
import { ISelectProp } from 'interfaces/FireStations';
import { QueryState } from 'common/enums';
import { AppFireState } from 'store/state/FireStationsState';

const FireStationDetailContainer: React.FC<FireStationDetailPublicProps> = (
  ownProps: FireStationDetailPublicProps
) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const {
    isLoading,
    fireStations,
    singleFireStation,
    counties,
    contacts,
    errorMessage,
  } = useSelector((state: AppFireState) => state.firestation);
  const awaitRequest: boolean = isLoading === QueryState.AWAIT;
  const [cityOption, setCityList] = useState<ISelectProp[]>([]);
  const [countyOption, setCountyOption] = useState<ISelectProp[]>([]);
  const [isContact1New, setIsContact1New] = useState<boolean>(true);
  const [isContact2New, setIsContact2New] = useState<boolean>(true);
  const [origGenInfo, setOrigGenInfo] = useState<any>(null);
  const [alertError, setAlertError] = useState<boolean>(false);
  const [confirmModal, setConfirmModal] = useState<boolean>(false);
  const [processingDelete, setProcessingDelete] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const form = useForm(FireStationFormProps({}));
  const { watch, getValues } = form;
  const selectedStationId = params?.id || 0;
  const isNewForm = location.pathname === '/dashboard/fire-stations/new';
  const countyName = watch('generalInformation.county', '');

  const checkObjectProperties = (obj: any) => {
    for (const key in obj) {
      if (obj[key] !== null && obj[key] != '') return false;
    }
    return true;
  };

  useEffect(() => {
    const countyList = JSON.parse(localStorage.getItem('countyList') || '');
    setCountyOption(countyList.counties);
    const resetData: FireStationForm = {
      generalInformation: {
        name: '',
        county: '',
        city: '',
        address1: '',
        address2: '',
      },
      contact1: {
        contactId: 0,
        fullName: '',
        workPhone: '',
        cellPhone: '',
        emailAddress: '',
      },
      contact2: {
        contactId: 0,
        fullName: '',
        workPhone: '',
        cellPhone: '',
        emailAddress: '',
      },
    };
    setOrigGenInfo(resetData.generalInformation);
    form.reset(resetData);
    if (selectedStationId !== 0 && !isNewForm) {
      dispatch(loadFireStationById({ stationId: selectedStationId }));
    }
  }, []);

  useEffect(() => {
    if (!isNewForm) {
      if (!checkObjectProperties(singleFireStation)) {
        const newData = {
          generalInformation: singleFireStation,
          contact1: {
            contactId: 0,
            fullName: '',
            workPhone: '',
            cellPhone: '',
            emailAddress: '',
          },
          contact2: {
            contactId: 0,
            fullName: '',
            workPhone: '',
            cellPhone: '',
            emailAddress: '',
          },
        };
        setOrigGenInfo(newData.generalInformation);
        form.reset(newData);

        if (Array.isArray(contacts) && contacts.length > 0) {
          const contactTemp: any = [];
          contacts.slice(0, 2).map((v: any) => {
            contactTemp.push(v);
          });
          const tempData = {
            ...newData,
            contact1: contactTemp[0],
            contact2:
              contactTemp[1] !== undefined ? contactTemp[1] : newData.contact2,
          };

          form.reset(tempData);
          if (contacts.length >= 2) {
            setIsContact1New(false);
            setIsContact2New(false);
          }
          if (contacts.length == 1) {
            setIsContact1New(false);
          }
        }
      }
    }
  }, [singleFireStation, contacts]);

  useEffect(() => {
    const loadCityOption = () => {
      const countyStations = counties.reduce((arr: any, c: any) => {
        if (c.county === countyName) {
          return arr.concat(c.stations ? c.stations : []);
        }
        return arr;
      }, []);
      const cities = countyStations && uniq(map(countyStations, 'city')).sort();
      const cityOption = cities.map((c: string) => ({
        value: c,
        label: c,
      }));

      setCityList(cityOption);
    };
    if (!awaitRequest) {
      loadCityOption();
    }
  }, [countyName]);

  useEffect(() => {
    if (!awaitRequest) {
      const cityList = uniq(map(fireStations, 'city')).sort();
      const cityOption = cityList.map((c: string) => ({
        value: c,
        label: c,
      }));

      setCityList(cityOption);
    }
  }, [fireStations]);

  useEffect(() => {
    if (
      !processingDelete &&
      (isLoading === QueryState.UPDATED || isLoading === QueryState.CREATED)
    ) {
      setConfirmModal(true);
    }

    if (processingDelete && isLoading === QueryState.UPDATED) {
      setProcessingDelete(false);
      setDeleteModal(false);
      navigate('/dashboard/fire-stations');
    }
    if (isLoading === QueryState.FAIL) {
      setProcessingDelete(false);
      setDeleteModal(false);
      setAlertError(true);
    }
  }, [isLoading]);

  const handleSubmitForm = async (payload: FireStationForm) => {
    const isEmpty = checkObjectProperties(payload?.contact2);
    const contact1Payload: payloadContact = {
      details: payload.contact1,
    };
    const contact2Payload: payloadContact = {
      details: payload.contact2,
    };

    if (isNewForm) {
      // Todo: New Record
      if (!isEmpty) {
        //Include Contact 2 if Not Empty
        const body: payloadFireStationContact = {
          details: payload.generalInformation,
          contacts: [
            {
              details: {
                fullName: payload.contact1.fullName,
                workPhone: payload.contact1.workPhone,
                cellPhone: payload.contact1.cellPhone,
                emailAddress: payload.contact1.emailAddress,
              },
            },
            {
              details: {
                fullName: payload.contact2.fullName,
                workPhone: payload.contact2.workPhone,
                cellPhone: payload.contact2.cellPhone,
                emailAddress: payload.contact2.emailAddress,
              },
            },
          ],
        };
        dispatch(createFireStation(body));
      } else {
        const body: payloadFireStationContact = {
          details: payload.generalInformation,
          contacts: [
            {
              details: {
                fullName: payload.contact1.fullName,
                workPhone: payload.contact1.workPhone,
                cellPhone: payload.contact1.cellPhone,
                emailAddress: payload.contact1.emailAddress,
              },
            },
          ],
        };
        dispatch(createFireStation(body));
      }
    } else {
      // Todo: Update Record
      if (isContact2New) {
        const bodyContact2 = {
          details: {
            fullName: payload.contact2.fullName,
            workPhone: payload.contact2.workPhone,
            cellPhone: payload.contact2.cellPhone,
            emailAddress: payload.contact2.emailAddress,
          },
          stationId: selectedStationId,
        };
        dispatch(createContacts(bodyContact2)); //Has Contact 2 Info create
        const body: payloadFireStationContact = {
          details: payload.generalInformation,
          contacts: [contact1Payload],
        };
        dispatch(updateFireStation(body, { stationId: selectedStationId }));
      } else {
        if (!isEmpty) {
          //Include Contact 2 if Not Empty
          const body: payloadFireStationContact = {
            details: payload.generalInformation,
            contacts: [contact1Payload, contact2Payload],
          };
          dispatch(updateFireStation(body, { stationId: selectedStationId }));
        } else {
          const body: payloadFireStationContact = {
            details: payload.generalInformation,
            contacts: [contact1Payload],
          };
          dispatch(updateFireStation(body, { stationId: selectedStationId }));
        }
      }
    }
  };

  const handleConfirmation = () => {
    setConfirmModal(false);
    navigate('/dashboard/fire-stations');
  };

  const handleConfirmDelete = async (confirm: boolean) => {
    if (confirm) {
      if (selectedStationId) {
        setProcessingDelete(true);
        const formValue: Partial<FireStationForm> = getValues();
        if (formValue && formValue.generalInformation) {
          if (formValue.contact1 && formValue.contact2) {
            const contact1Payload: payloadContact = {
              details: formValue.contact1,
            };
            const contact2Payload: payloadContact = {
              details: formValue.contact2,
            };
            const body: payloadFireStationContact = {
              details: formValue.generalInformation,
              contacts: [contact1Payload, contact2Payload],
            };
            dispatch(deleteFireStation(body, { stationId: selectedStationId }));
          }

          if (formValue.contact1) {
            const contact1Payload: payloadContact = {
              details: formValue.contact1,
            };
            const body: payloadFireStationContact = {
              details: formValue.generalInformation,
              contacts: [contact1Payload],
            };
            dispatch(deleteFireStation(body, { stationId: selectedStationId }));
          }
        }
      }
    } else {
      setDeleteModal(false);
    }
  };

  const combinedProps: FireStationDetailProps = {
    ...ownProps,
    awaitRequest,
    form,
    counties,
    countyOption,
    cityOption,
    isNewForm,
    errorMessage,
    alertError,
    confirmModal,
    deleteModal,
    handleSubmitForm,
    hideAlertError: () => setAlertError(false),
    handleConfirmation,
    showDeleteModal: () => setDeleteModal(true),
    handleConfirmDelete,
    processingDelete,
  };
  return <FireStationDetailView {...combinedProps} />;
};

export default FireStationDetailContainer;
