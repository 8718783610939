import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IEmergencyRequest } from 'common/interfaces';
import useSelectorSafe from 'store/selectors/useSelectorSafe';
import { AppState } from 'store/state/AppState';
import { SocketAppState } from 'store/state/SocketState';
import storeconfig from 'store';
import {
  DashboardEmptyState,
  DashboardState,
} from 'store/state/DashboardState';
import { dashboardThunk } from 'thunks';
import {
  DashboardMainModal,
  DashboardModalFocus,
  DashboardMainProps,
  DashboardMainPublicProps,
} from './DashboardMain.props';
import DashboardMainView from './DashboardMain.view';
import { QueryState, RoomState } from 'common/enums';
import { tokenActions } from 'store/actions';
import { verifyIfTokenIsExpired } from 'common/utils/getTokenExpiration';

const DashboardMainContainer: React.FC<DashboardMainPublicProps> = (
  ownProps: DashboardMainPublicProps
) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { store } = storeconfig;
  const { token } = store.getState();
  const {
    emergencies = [],
    responses = [],
    page,
    pageLimit,
    pageTotal,
    initialPageTotal,
    queryState,
    ...dashboardState
  }: DashboardState = useSelectorSafe((state: AppState) => state.dashboard) ||
  DashboardEmptyState;
  const awaitRequest = queryState === QueryState.AWAIT;
  const [modalFocus, setModalFocus] = useState<Partial<DashboardModalFocus>>({
    modal: DashboardMainModal.UNDEFINED,
  });
  const [pageCount, setPageCount] = useState<number>(1);
  const { roomRequest, roomResponse, from } = useSelector(
    (state: SocketAppState) => state.socket
  );

  const handlePageChange = (num: number) => {
    if (num !== page) {
      setPageCount(num);
      dispatch(dashboardThunk.getEmergencies(num));
    }
  };
  const handleModalFocus = (modal: Partial<DashboardModalFocus>) => {
    setModalFocus(modal);
  };
  const navigateToEmergency = (e: IEmergencyRequest) => {
    navigate(`emergencies/${e.requestId}`, { state: { emergencyData: e } });
  };

  const getCountyData = async () => {
    const data = await fetch('countylist.json').then((res: any) => res.json());
    return {
      data,
    };
  };

  useEffect(() => {
    if (roomResponse !== null && from === RoomState.FFMOB_RESPONSE) {
      dispatch(dashboardThunk.getResponses);
    }

    if (roomRequest !== null && from === RoomState.FFMOB_REQUEST) {
      dispatch(dashboardThunk.getEmergencies(pageCount));
    }
  }, [roomRequest, roomResponse, from]);

  useEffect(() => {
    dispatch(dashboardThunk.getEmergencies(1));
    dispatch(dashboardThunk.getResponses);

    // Save to local storage the county list json file in public folder
    getCountyData().then(({ data }) => {
      localStorage.setItem('countyList', JSON.stringify(data));
    });

    const expiredIn = verifyIfTokenIsExpired(token);
    if (expiredIn < 1) {
      localStorage.clear();
      dispatch(tokenActions.clear());
      navigate('/login');
    }
  }, []);

  const refreshResponseData = () => {
    dispatch(dashboardThunk.getResponses);
  };

  const combinedProps: DashboardMainProps = {
    ...ownProps,
    awaitRequest,
    emergencies,
    page,
    pageLimit,
    pageTotal,
    initialPageTotal,
    modalFocus,
    responses,
    handlePageChange,
    handleModalFocus,
    navigateToNewEmergency: () => navigate('emergencies/new'),
    navigateToEmergency,
    refreshResponseData,
  };
  return <DashboardMainView {...combinedProps} />;
};

export default DashboardMainContainer;
