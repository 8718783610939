import { Nullable } from 'common/utils/Nullable';

export enum UserStatus {
  APPROVED = 'Approved',
  SUSPENDED = 'Suspended',
  PENDING = 'Pending Approval',
  NOT_APPROVED = 'Not Approved',
}

export interface IAccountPositions {
  i: number;
  n: string;
  a: number;
  positions: {
    i: number;
    n: string;
  }[];
}

export interface ICurrentGroups {
  i: number;
  n: string;
  a: number;
}

interface ICurrentGroupAppPermissions {
  s: number;
  n: string;
  a: string[];
}

interface ICurrentPositions {
  i: number;
  n: string;
}

export interface IUserPortal {
  id: number;
  pvMultipleUser: number;
  pvAdministrator: number;
  pvAccountLocked: number;
  pvPersonGivenName: string;
  pvPersonMiddleName: string;
  pvPersonSurName: string;
  pvOfficePhone: Nullable<string>;
  pvMobilePhone: Nullable<string>;
  pvOrganizationUnitName: string;
  username: string;
  email: string;
  status: UserStatus;
  lastUpdated: string;
  pvDomainID: number;
  pvVoid: number;
  pvCanSendBlastEmail: null | string;
  subscribeToEmail: boolean;
  pvActiveGroupID: number;
  currentGroups: ICurrentGroups[];
  currentPositions: ICurrentPositions[];
  pvTrainingAccount?: number;
  currentGroupSettings?: unknown;
  currentGroupsWithAdmin?: unknown;
  currentInScemdGroup?: boolean;
  currentGroupAppPermissions?: ICurrentGroupAppPermissions[];
  pvActiveGroupAdmin?: boolean;
  accessToken: string;
  account2position?: IAccountPositions[];
}
