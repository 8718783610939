import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { find, findIndex, includes, isEmpty, pick } from 'lodash';

import { IRegion } from 'common/interfaces';
import { QueryState, RoomState } from 'common/enums';
import { Nullable } from 'common/utils/Nullable';
import useSelectorSafe from 'store/selectors/useSelectorSafe';
import RequestPageState, {
  RequestPageEmptyState,
} from 'store/state/pages/RequestPageState';
import { requestThunk } from 'thunks';
import { StackableStep } from 'components/fragments/StackableSteps';
import {
  RequestForm,
  RequestFormProps,
  RequestFormStep,
} from 'helpers/forms/requestForm';
import {
  EmergencyRequestProps,
  EmergencyRequestPublicProps,
} from './EmergencyRequest.props';
import EmergencyRequestView from './EmergencyRequest.view';
import { AppState } from 'store/state/AppState';

const EmergencyRequestContainer: React.FC<EmergencyRequestPublicProps> = (
  ownProps: EmergencyRequestPublicProps
) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const location: any = useLocation();
  const pageState: RequestPageState = useSelectorSafe((state: AppState) => {
    const page = state?.page;
    if (page && page.key === location?.key) return page;
    return RequestPageEmptyState;
  });
  const socket = useSelectorSafe((state: AppState) => state?.socket);
  const { regions = [] } = pageState;
  let awaitRequest = pageState.state === QueryState.AWAIT;
  const emergencyData = location?.state?.emergencyData || null;
  const [alertError, setAlertError] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<Nullable<string>>(null);
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
  const [step, setStep] = useState<RequestFormStep>(
    RequestFormStep.REQUEST_DETAILS
  );
  const [stepHistory, setStepHistory] = useState<StackableStep[]>(
    pageState?.steps || []
  );
  const [disableStep, setDisableStep] = useState<boolean>(false);
  const form = useForm(RequestFormProps({ step }));

  const handleChangeStep = (next: RequestFormStep) => {
    const nextIndex = findIndex(stepHistory, { step: next });
    const prevIndex = findIndex(stepHistory, { step });
    const prevStep = stepHistory[prevIndex];
    const steps = [...stepHistory];
    if (nextIndex > prevIndex && !prevStep.complete) {
      steps[prevIndex] = { ...prevStep, complete: true };
    }
    steps[nextIndex] = { ...steps[nextIndex], active: true };
    setAlertError(false);
    setStepHistory(steps);
    setStep(next);
  };

  const handleConfirmation = async (confirm: boolean) => {
    if (confirm) {
      const formValues = form.getValues() as RequestForm;
      const { equipment, personnel } = formValues.resources;
      // Filter items with entry only
      const filteredEquipmentItem = equipment.reduce((arr: any, item: any) => {
        if (item.number && item.description) {
          return arr.concat({ ...item });
        }
        return arr;
      }, []);
      const filteredPersonnelItem = personnel.reduce((arr: any, item: any) => {
        if (item.number && item.credentialsNeeded) {
          return arr.concat({ ...item });
        }
        return arr;
      }, []);
      // Generate Request Payload
      const requestPayload = {
        ...formValues,
        resources: {
          personnel: filteredPersonnelItem,
          equipment: filteredEquipmentItem,
        },
      };

      if (emergencyData) {
        dispatch(requestThunk.update(requestPayload));
      } else {
        dispatch(requestThunk.create(requestPayload));
      }
    }
    setOpenConfirmation(false);
  };

  const handleSubmit = () => {
    if (!awaitRequest) setOpenConfirmation(true);
  };

  useEffect(() => {
    const locationState: any = location?.state;
    if (socket?.from === RoomState.FFMOB_RESPONSE) {
      const { requestId } = locationState?.emergencyData || '';

      const wsResponse = socket?.roomResponse?.data?.requestId || 0;
      const rId = requestId;

      const fsName =
        socket?.roomResponse?.data?.details?.fireStationInfo?.details?.name ||
        '';
      if (wsResponse === rId?.toString()) {
        const msgAlert =
          `The FFMOB Coordinator has responded to ${fsName}'s initial response, ` +
          'please contact the FFMOB Coordinator if those assets are no longer available.';
        setAlertMessage(msgAlert);
        setAlertError(true);
        setDisableStep(true);
      }
    }
  }, [socket]);

  useEffect(() => {
    // If ever we got a response from getRegions
    if (regions?.length > 0) {
      const emergencyData = location?.state?.emergencyData;
      const regionsState = emergencyData?.details?.regions || [];
      const data = {
        ...form.getValues(),
        regions: regions.map((r: IRegion) => {
          const countiesState =
            find(regionsState, { region: r.region })?.counties || [];
          return {
            region: r.region,
            counties: r.counties.map((c: any) => ({
              checked: includes(countiesState, c.name),
              county: c.name,
              stationsNumber: c.stationsNumber,
            })),
          };
        }),
      };
      form.reset(data);
    }
  }, [regions]);

  useEffect(() => {
    if (
      pageState.state === QueryState.CREATED ||
      pageState.state === QueryState.UPDATED
    ) {
      const requestNumber = pageState.newRequestNumber;
      // TODO: Navigate directly in thunk without waiting for dispatch
      if (requestNumber && requestNumber > 0) {
        navigate(`/dashboard/emergencies/${requestNumber}`);
      }

      if (params.requestId && parseInt(params.requestId) > 0) {
        navigate(`/dashboard/emergencies/${params.requestId}`);
      }
    }

    if (pageState.state === QueryState.FAIL) {
      setAlertMessage(
        'We just experienced a technical issue, please wait a few seconds and try again.'
      );
      setAlertError(true);
      awaitRequest = false;
    }
  }, [pageState.state]);

  useEffect(() => {
    const { errors } = form.formState;
    if (!isEmpty(errors)) {
      // Alert error message for the following fields
      // const err = pick(errors, ['resources', 'regions']);
      const err = pick(errors, ['resources', 'regions']);
      if (!isEmpty(err)) {
        if (err.resources?.equipment || err.resources?.personnel) {
          return setAlertError(false);
        } else {
          setAlertMessage(Object.values(err)[0]?.message);
          setAlertError(true);
        }
      }
    }
  }, [form.formState.errors]);

  useEffect(() => {
    const locationState: any = location?.state;
    if (locationState?.emergencyData) {
      const { requestId, details } = locationState.emergencyData;
      const { regions, equipment, personnel, emailBody, ...requestDetails } =
        details;
      // console.log('InitalUseEffect:', regions);
      const formData: Partial<RequestForm> = {
        requestDetails: {
          ...requestDetails,
          requestNumber: requestId,
        },
        resources: {
          equipment,
          personnel,
        },
        emailBody,
      };
      // Reset form using emergency request details
      form.reset(formData);

      // Check all steps if request for edit
      const completeStepHistory = stepHistory.map((s: any) => ({
        ...s,
        active: true,
        complete: true,
      }));

      setStepHistory(completeStepHistory);
    }
    // Create a new page identity
    dispatch(requestThunk.identify(location.key, pageState));
  }, []);

  const combinedProps: EmergencyRequestProps = {
    ...ownProps,
    awaitRequest,
    alertError,
    alertMessage,
    emergencyData,
    form,
    openConfirmation,
    step,
    stepHistory,
    disableStep,
    handleChangeStep,
    handleConfirmation,
    handleSubmit,
    hideAlertError: () => setAlertError(false),
  };
  return <EmergencyRequestView {...combinedProps} />;
};

export default EmergencyRequestContainer;
