import { QueryState } from 'common/enums';
import { IEmergencyRequest, IEmergencyResponse } from 'common/interfaces';
import { Nullable } from 'common/utils/Nullable';

interface EmergencyPageState {
  archiveState: QueryState;
  queryState: QueryState;
  emergencyData: Nullable<IEmergencyRequest>;
  responses: IEmergencyResponse[];
}

export const EmergencyPageEmptyState: EmergencyPageState = {
  archiveState: QueryState.UNDEFINED,
  queryState: QueryState.UNDEFINED,
  emergencyData: null,
  responses: [],
};

export default EmergencyPageState;
