import { UseFormProps } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

interface IItemsPersonnel {
  accepted: boolean;
  firstName: string;
  lastName: string;
  idNumber: string;
  rateOfPay: string;
  stationId: number;
}

interface IItemsEquip {
  accepted: boolean;
  code: string;
  description: string;
  stationId: number;
}

interface IFormItems {
  items: IItemsPersonnel[] | IItemsEquip[] | [];
  name: string;
  requested: number;
}

export interface ResourceEditFormData {
  personnel: IFormItems[] | [];
  equipment: IFormItems[] | [];
}

export const DefaultResEditForm: ResourceEditFormData = {
  personnel: [],
  equipment: [],
};

const schema = yup.object().shape({
  personnel: yup.array().of(
    yup.object().shape({
      name: yup.string(),
      requested: yup.number(),
      items: yup.array().of(
        yup.object().shape({
          accepted: yup.boolean(),
          firstName: yup.string().required('This is required!'),
          lastName: yup.string().required('This is required!'),
          idNumber: yup.string().required('This is required!'),
          rateOfPay: yup.string().required('This is required!'),
          stationId: yup.number().required(),
        })
      ),
    })
  ),
  equipment: yup.array().of(
    yup.object().shape({
      name: yup.string(),
      requested: yup.number(),
      items: yup.array().of(
        yup.object().shape({
          accepted: yup.boolean(),
          code: yup.string().required('This is required!'),
          description: yup.string().required('This is required!'),
          stationId: yup.number().required(),
        })
      ),
    })
  ),
});

export const ResourceEditFormProps = ({
  formData = DefaultResEditForm,
}: {
  formData: ResourceEditFormData;
}): Partial<UseFormProps> => {
  return {
    defaultValues: formData,
    resolver: yupResolver(schema),
  };
};
