import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      [theme.breakpoints.up('md')]: {
        padding: `0 ${theme.spacing(2)}`,
      },
    },
    equipmentWrapper: {
      flex: 1,
      overflowY: 'auto',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    equipmentItem: {
      marginBottom: theme.spacing(1),
      padding: theme.spacing(3),
      borderRadius: theme.spacing(1),
      backgroundColor: '#fafafa',
      [theme.breakpoints.down('md')]: {
        padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
      },
    },
    button: {
      marginTop: theme.spacing(1),
      flex: '0 1 auto',
    },
    removeContainer: {
      display: 'flex',
      width: '100%',
      alignItems: 'flex-end',
    },
    iconClose: {
      cursor: 'pointer',
      marginLeft: 'auto',
    },
  };
});

export default useStyles;
