import { UseFormProps } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { IEmergencyEquipment, IEmergencyPersonnel } from 'common/interfaces';
import { Nullable } from 'common/utils/Nullable';
import moment from 'moment';

export enum RequestFormStep {
  REQUEST_DETAILS = 'requestDetails',
  PERSONNEL = 'personnel',
  EQUIPMENT = 'equipment',
  REGIONS = 'regions',
  SEND_REQUEST = 'sendRequest',
}

export interface RequestFormResources {
  personnel: IEmergencyPersonnel[];
  equipment: IEmergencyEquipment[];
}

export interface RequestFormRegion {
  counties: { county: string; checked: boolean }[];
  region: number;
}

export interface RequestForm {
  requestDetails: {
    requestNumber?: number | string;
    date: Nullable<string>;
    departmentName: string;
    eventName: string;
    contactPerson: string;
    contactPhone: string;
    stagingLocation: string;
    notes: string;
  };
  regions: RequestFormRegion[];
  resources: RequestFormResources;
  emailBody: string;
}

export const schema = yup.object().shape({
  requestDetails: yup.mixed().when('$step', {
    is: (step: string) => step === RequestFormStep.REQUEST_DETAILS,
    then: yup.object().shape({
      date: yup.date().nullable(true).required('This field is required!'),
      departmentName: yup.string().required('This field is required!').max(255),
      eventName: yup.string().required('This field is required!').max(255),
      contactPerson: yup.string().required('This field is required!').max(255),
      contactPhone: yup
        .string()
        .min(12, 'Required a 10 digit number')
        .required('This field is required!'),
      stagingLocation: yup
        .string()
        .required('This field is required!')
        .max(255),
      notes: yup.string().max(65535, 'Exceeded the maximum allowed character'),
    }),
  }),
  resources: yup
    .mixed()
    .when('$step', {
      is: (step: string) => step === RequestFormStep.PERSONNEL,
      then: yup.object().shape({
        personnel: yup.array().of(
          yup.object().shape({
            number: yup.string().test({
              name: 'overLimit',
              message: 'Maximum limit to 100',
              test: (number: any) => {
                const transform: any = (val: any) =>
                  val !== '' ? Number(val) : null;
                return transform(number) <= 100;
              },
            }),
            credentialsNeeded: yup.string().when('number', {
              is: (number: any) => {
                return number > 0;
              },
              then: yup.string().required('This is required!').max(255),
            }),
            notes: yup
              .string()
              .max(65535, 'Exceeded the maximum allowed character'),
          })
        ),
      }),
    })
    .when('$step', {
      is: (step: string) => step === RequestFormStep.EQUIPMENT,
      then: yup.object().shape({
        equipment: yup.array().of(
          yup.object().shape({
            number: yup.string().test({
              name: 'overLimit',
              message: 'Maximum limit to 100',
              test: (number: any) => {
                const transform: any = (val: any) =>
                  val !== '' ? Number(val) : null;
                return transform(number) <= 100;
              },
            }),
            code: yup.string().max(255),
            description: yup.string().when('number', {
              is: (number: any) => {
                return number > 0;
              },
              then: yup.string().required('This is required!').max(255),
            }),
          })
        ),
      }),
    })
    .when('$step', {
      is: (step: string) => step === RequestFormStep.EQUIPMENT,
      then: yup
        .object()
        .test(
          'atLeastOneResource',
          'Please enter at least one Personnel or Equipment!',
          (value: any) => {
            const personnel = value?.personnel;
            const equipment = value?.equipment;
            if (personnel.length > 0 || equipment.length > 0) {
              const pObj = personnel[0];
              const eObj = equipment[0];
              return (
                (!pObj?.number && eObj.number) ||
                (pObj.number && !eObj?.number) ||
                (pObj.number && eObj.number)
              );
            }
            return false;
          }
        ),
    }),
  regions: yup.mixed().when('$step', {
    is: (step: string) => step === RequestFormStep.REGIONS,
    then: yup.array().test({
      name: 'atLeastOneRegion',
      message: 'Please select at least one region or county!',
      test: (regions: any) => {
        return regions.some((r: RequestFormRegion) =>
          r.counties.some((c) => c.checked)
        );
      },
    }),
  }),
});

export const RequestFormProps = ({
  formData = {
    resources: {
      personnel: [{ number: '', credentialsNeeded: '', notes: '' }],
      equipment: [{ number: '', code: '', description: '' }],
    },
    requestDetails: {
      // requestNumber: '',
      date: moment(new Date()).format('YYYY-MM-DD'),
      departmentName: '',
      eventName: '',
      contactPerson: '',
      contactPhone: '',
      stagingLocation: '',
      notes: '',
    },
    emailBody:
      'Attention Chief and/or designee, Firefighter Mobilization is requesting the following to support a need identified by a local resource.',
  },
  step,
}: {
  formData?: Partial<RequestForm>;
  step: string;
}): Partial<UseFormProps> => {
  return {
    defaultValues: formData,
    resolver: yupResolver(schema),
    context: { step },
  };
};
