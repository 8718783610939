import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {},
    pagination: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: theme.spacing(2),
    },
    exportIcon: {
      fill: theme.palette.primary.dark,
      width: '1.5rem',
      height: '1rem',
    },
  };
});

export default useStyles;
