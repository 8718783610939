import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { Nullable } from './Nullable';
import { IidTokenSession } from 'interfaces/session.interface';
import { refreshSession } from 'thunks/cognito/cognitoThunks';
import TokenState from 'store/state/TokenState';

export function getTokenExpiration(
  tokenSession: Nullable<Partial<IidTokenSession>>
) {
  const expValue = tokenSession && tokenSession.payload?.exp;
  if (expValue) return expValue;
}

export function verifyIfTokenIsExpired(token: TokenState) {
  const currentTimestamp = Math.floor(Date.now() / 1000); // ?:Current timestamp in seconds
  const cognitoUser = token?.cognitoUser;
  const tokenSession = cognitoUser && JSON.parse(cognitoUser);
  const tokenExpiration = getTokenExpiration(tokenSession) || 0;
  return tokenExpiration - currentTimestamp;
}

export async function refreshAccessToken(): Promise<CognitoUserSession> {
  return await refreshSession();
}
