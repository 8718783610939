import React from 'react';
import MuiModal, { ModalProps } from '@mui/material/Modal';
import { Backdrop, Fade, Paper } from '@mui/material';
import { withStyles } from '@mui/styles';

import useStyles, { ModalStyles } from './Modal.styles';

const Modal = withStyles(ModalStyles)(MuiModal);

const ModalView: React.FC<ModalProps> = (props: ModalProps) => {
  const classes = useStyles();
  return (
    <Modal
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      {...props}
    >
      <Fade in={props.open}>
        <Paper className={classes.modalContent} elevation={1}>
          {props.children}
        </Paper>
      </Fade>
    </Modal>
  );
};

export default ModalView;
