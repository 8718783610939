import React from 'react';
import clsx from 'clsx';
import { Box, ListItemIcon, MenuList, MenuItem } from '@mui/material';
import MuiDrawer, { DrawerProps } from '@mui/material/Drawer';
import { withStyles } from '@mui/styles';
import { Nullable } from 'common/utils/Nullable';
import { Typo } from 'components/primitives';
import useStyles, { DrawerStyles } from './DrawerMenu.styles';

const Drawer = withStyles(DrawerStyles)(MuiDrawer);

export interface DrawerMenuItem {
  icon: React.ReactNode;
  name: string;
  path: Nullable<string>;
  title: string;
}

const DrawerMenuView = ({
  activePathname = null,
  items = [],
  onMenuClick,
  ...props
}: {
  activePathname?: Nullable<string>;
  items?: DrawerMenuItem[];
  onMenuClick: (menu: DrawerMenuItem) => void;
} & DrawerProps) => {
  const classes = useStyles();
  // TODO: Fix pre-selected menu programmatically via route information
  // TODO: Enable menu route navigation
  return (
    <Drawer elevation={0} {...props}>
      <Box className={classes.menuWrapper} role="presentation">
        <MenuList className={classes.menuList}>
          {items.map((m: DrawerMenuItem, i: number) => (
            <MenuItem
              key={m.name}
              className={clsx(classes.menuItem)}
              selected={(m.path && m.path === activePathname) || false}
              onClick={() => onMenuClick(m)}
            >
              <ListItemIcon>{m.icon}</ListItemIcon>
              <Typo variant="h6" color="inherit">
                {m.title}
              </Typo>
            </MenuItem>
          ))}
        </MenuList>
      </Box>
    </Drawer>
  );
};

export default DrawerMenuView;
