/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { MouseEvent } from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import { Button, FormGroup, Modal, Typo } from 'components/primitives';
import useStyles from './DismissOfferResources.styles';

const DismissOfferResources = ({
  className,
  title = 'Please confirm',
  onOk,
  awaitSave,
  emailMsg,
  changeTextEmailBody,
  ...props
}: {
  title?: string;
  onOk?: (e: MouseEvent, confirm: boolean, payload: any) => void;
  awaitSave?: boolean;
  emailMsg?: string;
  changeTextEmailBody?: (e: any) => void;
} & BoxProps) => {
  const classes = useStyles();
  const emailTemplate =
    'Attention Chief and/or designee, thank you for your support and offer for the requested Firefighter Mobilization response. ' +
    'At this time, the response is full. ' +
    'Please continue to monitor for further requests as this incident continues to expand. ' +
    'Thank you for your continued support.';
  const onTouchButton = (e: MouseEvent, ok: boolean) => {
    onOk && onOk(e, ok, 'payload');
  };
  return (
    <Box className={classes.content}>
      <Typo mb={2} variant="h4">
        Dismiss offered resources
      </Typo>
      <Typo mb={1} variant="body1" color="common.black">
        Please provide a reason for dismissal.
      </Typo>
      <Typo variant="body1" color="common.black">
        You can send it or edit if needed
      </Typo>
      <Box mt={3}>
        <FormGroup label="Email body">
          <textarea
            className={classes.textarea}
            // defaultValue={emailMsg}
            value={emailMsg}
            onChange={changeTextEmailBody}
            spellCheck="false"
            rows={10}
          />
        </FormGroup>
      </Box>
      <Box className={classes.footer}>
        <Button onClick={(e) => onTouchButton(e, false)} neutral fullWidth>
          Cancel
        </Button>
        <Button
          progress={awaitSave}
          onClick={(e) => onTouchButton(e, true)}
          fullWidth
        >
          Send dismissal
        </Button>
      </Box>
    </Box>
  );
};

export default DismissOfferResources;
