import { makeCreateActions, presetActions } from 'store/utils/Redux';
import { ExtendedPageState } from 'store/state/PageState';

const ns = 'PAGE';
const createPageActions = makeCreateActions(ns);

const pageActions = createPageActions({
  identify: (identity: ExtendedPageState) => identity,
  update: presetActions.makeIdentity(),
  clear: presetActions.noPayload,
});

export default pageActions;
