import { ISelectProp } from 'interfaces/FireStations';
import { CredentialOptions } from './CredentialOptions';
import { EquipmentOptions } from './EquipmentOptions';

const credentialOptions: ISelectProp[] = CredentialOptions.map(
  (value: string) => ({
    label: value,
    value,
  })
);

const equipmentOptions: ISelectProp[] = EquipmentOptions.map(
  (value: string) => ({
    label: value,
    value,
  })
);

export { credentialOptions, equipmentOptions };
