/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Dispatch } from 'redux';
import { pageActions } from 'store/actions';
import ArchivePageState, {
  ArchivePageEmptyState,
} from 'store/state/pages/ArchivePageState';
import PageState from 'store/state/PageState';

const identifyPage =
  (payload: PageState<ArchivePageState>) => async (dispatch: Dispatch) => {
    const identity = {
      ...ArchivePageEmptyState,
      ...payload,
    };
    dispatch(pageActions.identify(identity));
  };

export default identifyPage;
