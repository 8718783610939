import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateAdapter from '@mui/lab/AdapterMoment';
import { SnackbarProvider } from 'notistack';
import AppTheme from 'theming/theme';
import StoreConfig from 'store';

import {
  CoordinatorProfile,
  DashboardMain,
  EmergencyRequest,
  EmergencyArchive,
  EmergencyDetails,
  FireStations,
  FireStationDetail,
} from 'routes/Dashboard';
import FireChiefResponse from 'routes/FireChiefResponse';
import ProtectedPage from 'routes/ProtectedPage';
import LoginPage from 'routes/Login';
import NotFound from 'routes/NotFound';
import 'typeface-roboto';

const App: React.FC = () => {
  // TODO: convert to declarative routing
  // TODO: Create a magic page for fire chief response
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={AppTheme}>
        <LocalizationProvider dateAdapter={DateAdapter}>
          <Provider store={StoreConfig.store}>
            <PersistGate persistor={StoreConfig.persistor}>
              <CssBaseline />
              <SnackbarProvider maxSnack={5}>
                <BrowserRouter>
                  <Routes>
                    <Route path="login" element={<LoginPage />} />
                    <Route
                      path="emergency/response/:requestId"
                      element={<FireChiefResponse />}
                    />
                    <Route path="/" element={<ProtectedPage />}>
                      <Route path="dashboard" element={<DashboardMain />} />
                      <Route
                        path="dashboard/archive"
                        element={<EmergencyArchive />}
                      />
                      <Route
                        path="dashboard/profile"
                        element={<CoordinatorProfile />}
                      />
                      <Route
                        path="dashboard/emergencies/new"
                        element={<EmergencyRequest />}
                      />
                      <Route
                        path="dashboard/emergencies/:requestId/edit"
                        element={<EmergencyRequest />}
                      />
                      <Route
                        path="dashboard/emergencies/:requestId"
                        element={<EmergencyDetails />}
                      />
                      <Route
                        path="dashboard/fire-stations"
                        element={<FireStations />}
                      />
                      <Route
                        path="dashboard/fire-stations/new"
                        element={<FireStationDetail />}
                      />
                      <Route
                        path="dashboard/fire-stations/:id"
                        element={<FireStationDetail />}
                      />
                    </Route>
                    <Route path="*" element={<NotFound />} />
                  </Routes>
                </BrowserRouter>
              </SnackbarProvider>
            </PersistGate>
          </Provider>
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
