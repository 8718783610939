import React from 'react';
import { Stack, Grid } from '@mui/material';
import { Typo } from 'components/primitives';

const HeaderTitleEquipment: React.FC = () => {
  return (
    <Stack>
      <Grid container spacing={1}>
        {/* Header title */}
        <Grid item xs={4}>
          <Typo variant="body2" color="textSecondary" fontWeight={600}>
            Vehicle Description
          </Typo>
        </Grid>
        <Grid item xs={4}>
          <Typo variant="body2" color="textSecondary" fontWeight={600}>
            Fire Station
          </Typo>
        </Grid>
        <Grid item xs={3}>
          <Typo variant="body2" color="textSecondary" fontWeight={600}>
            FEMA Cost Code
          </Typo>
        </Grid>
        <Grid item xs={1}></Grid>
      </Grid>
    </Stack>
  );
};

export default HeaderTitleEquipment;
