import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Grid } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';
import DatePicker from '@mui/lab/DatePicker';
import { FormGroup, TextField, NumberFormatPhone } from 'components/primitives';

import useStyles from './EmergencyDetails.styles';

const EmergencyDetailsView: React.FC<{ name: string } & BoxProps> = ({
  name,
  ...props
}) => {
  const classes = useStyles();
  const { control } = useFormContext();
  // const validateKeyPressNumber = (event: React.KeyboardEvent) => {
  //   if (!/[0-9]/.test(event.key)) {
  //     event.preventDefault();
  //   }
  // };
  const handlePhoneChange =
    (onChange: any) => (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(NumberFormatPhone(event));
    };

  return (
    <Box {...props} height="100%">
      <Box className={classes.root}>
        <Box className={classes.requestDetailsWrapper}>
          <Grid container columnSpacing={1} rowSpacing={1}>
            <Grid item xs={12} lg={6}>
              <Controller
                name={`${name}.date`}
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <FormGroup label="Date" error={error}>
                    <DatePicker
                      value={value}
                      onChange={(m) => onChange(m.toDate())}
                      renderInput={(params) => (
                        <TextField {...params} error={!!error} />
                      )}
                    />
                  </FormGroup>
                )}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <Controller
                name={`${name}.departmentName`}
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <FormGroup label="Requesting Department Name" error={error}>
                    <TextField
                      error={!!error}
                      value={value}
                      onChange={onChange}
                    />
                  </FormGroup>
                )}
              />
            </Grid>
            <Grid item xs={12} lg={12}>
              <Controller
                name={`${name}.eventName`}
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <FormGroup label="Event Name" error={error}>
                    <TextField
                      value={value}
                      error={!!error}
                      onChange={onChange}
                    />
                  </FormGroup>
                )}
              />
            </Grid>
            <Grid item xs={12} lg={12}>
              <Controller
                name={`${name}.contactPerson`}
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <FormGroup label="Contact Person" error={error}>
                    <TextField
                      value={value}
                      error={!!error}
                      onChange={onChange}
                    />
                  </FormGroup>
                )}
              />
            </Grid>
            <Grid item xs={12} lg={12}>
              <Controller
                name={`${name}.contactPhone`}
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <FormGroup label="Contact Phone" error={error}>
                    <TextField
                      value={value}
                      error={!!error}
                      onChange={handlePhoneChange(onChange)}
                    />
                  </FormGroup>
                )}
              />
            </Grid>
            <Grid item xs={12} lg={12}>
              <Controller
                name={`${name}.stagingLocation`}
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <FormGroup label="Report to staging at" error={error}>
                    <TextField
                      value={value}
                      error={!!error}
                      onChange={onChange}
                    />
                  </FormGroup>
                )}
              />
            </Grid>
            <Grid item xs={12} lg={12}>
              <Controller
                name={`${name}.notes`}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <FormGroup label="Additional notes" error={error}>
                    <TextField value={value} onChange={onChange} multiline />
                  </FormGroup>
                )}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default EmergencyDetailsView;
