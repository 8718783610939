import { UseFormProps } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ResourceState } from 'common/enums';
import { IEmergencyResponseResource } from 'common/interfaces';

export interface ResourceForm {
  personnel: IEmergencyResponseResource[];
  equipment: IEmergencyResponseResource[];
  status: ResourceState;
}

export const DefaultResourceForm = {
  personnel: [],
  equipment: [],
  status: ResourceState.UNDEFINED,
};

export const ResourceFormProps = ({
  formData = DefaultResourceForm,
}: {
  formData?: ResourceForm;
}): Partial<UseFormProps> => {
  return {
    defaultValues: formData,
  };
};
