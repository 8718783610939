import React from 'react';
import {
  Control,
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import clsx from 'clsx';
import { Checkbox, Stack, useTheme, useMediaQuery } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';
import { ResourceType } from 'common/types';
// import { IEmergencyResponseResource } from 'common/interfaces';

import useStyles from './ResouceTabPanel.styles';

const HiddenBox: React.FC<{
  children: React.ReactNode;
  visible?: boolean;
}> = ({ children, visible = false }) => (
  <React.Fragment>{visible && children}</React.Fragment>
);

const ItemCheckbox: React.FC<{
  control: Control;
  name: string;
  handleClick: () => void;
}> = ({ control, name, handleClick }) => (
  <Controller
    {...{ control, name }}
    render={({ field: { onChange, value } }) => (
      <Checkbox checked={value} onChange={onChange} onClick={handleClick} />
    )}
  />
);

const ResourceItems: React.FC<
  { index: number; name: string; handleClick: () => void } & BoxProps
> = ({ index, name, handleClick, ...props }) => {
  const { control } = useFormContext();
  const itemsField = useFieldArray({ name: `${name}.${index}.items`, control });

  return (
    <React.Fragment>
      {itemsField.fields.map((item: any, i: number) => (
        <Stack key={item.id} direction="row" className={props.className}>
          <HiddenBox visible={name === 'personnel'}>
            <Box>
              <ItemCheckbox
                name={`${name}.${index}.items.${i}.accepted`}
                control={control}
                handleClick={handleClick}
              />
              {`${item.firstName} ${item.lastName}`}
            </Box>
            <Box>{item.idNumber}</Box>
            <Box>{item.rateOfPay}</Box>
          </HiddenBox>
          <HiddenBox visible={name === 'equipment'}>
            <Box>
              <ItemCheckbox
                name={`${name}.${index}.items.${i}.accepted`}
                control={control}
                handleClick={handleClick}
              />
              {item.description}
            </Box>
            <Box>{item.code}</Box>
          </HiddenBox>
        </Stack>
      ))}
    </React.Fragment>
  );
};

const ResourceTabPanel: React.FC<
  { active?: boolean; name: ResourceType; handleClick: () => void } & BoxProps
> = ({ active = false, name, handleClick, ...props }) => {
  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { control, resetField } = useFormContext();
  const resourceField = useFieldArray({ name, control });
  const resourceWatch = useWatch({ name, control });

  const onSelectAll =
    (name: string, unchecked: boolean, resource: any) => () => {
      resetField(name, {
        defaultValue: {
          ...resource,
          items: resource.items.map((item: any) => ({
            ...item,
            accepted: !unchecked,
          })),
        },
      });
    };

  return (
    <Box
      id={`resource-tabpanel-${name}`}
      aria-labelledby={`resource-tab-${name}`}
      hidden={!active}
      role="tabpanel"
      {...props}
    >
      {active && (
        <Stack className={classes.stackView} pb={1} rowGap={1}>
          {resourceField.fields.map((r: any, index: number) => {
            const checked = resourceWatch[index].items.filter(
              (item: any) => item.accepted
            ).length;

            return (
              <React.Fragment key={r.id}>
                <Stack
                  className={clsx(classes.personnelItem, classes.sectionItem)}
                  direction="row"
                >
                  <Box>
                    <Checkbox
                      name={`${name}.${index}`}
                      checked={checked > 0}
                      sx={{
                        opacity:
                          checked > 0 && checked < r.items.length ? 0.5 : 1,
                      }}
                      onChange={onSelectAll(
                        `${name}.${index}`,
                        r.items.length === checked,
                        r
                      )}
                    />
                    {name === 'personnel' ? r.credentialsNeeded : r.description}
                  </Box>
                  <HiddenBox visible={name === 'personnel'}>
                    <Box>{isMobile ? 'ID' : 'ID Number'}</Box>
                    <Box>{isMobile ? 'Rate' : 'Rate of pay'}</Box>
                  </HiddenBox>
                  <HiddenBox visible={name === 'equipment'}>
                    <Box>FEMA Cost Code</Box>
                  </HiddenBox>
                </Stack>
                <ResourceItems
                  className={classes.personnelItem}
                  {...{ index, name, handleClick }}
                />
              </React.Fragment>
            );
          })}
        </Stack>
      )}
    </Box>
  );
};

export default ResourceTabPanel;
