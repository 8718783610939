import { Dispatch } from 'redux';
import apiFetch, {
  getEmergencyFCRequests,
  getEmergencyFCResponses,
  getNotificationDecode,
  getFireStationInfo,
} from 'services/apiFetch';
import apiFetchMagicUrl from 'services/apiFetchMagicUrl';
import actions from 'store/actions/response';
import {
  IEmergencyResponse,
  IEmergencyResponseDetailEquipment,
  IEmergencyResponseDetailPersonnel,
} from 'common/interfaces';
import { range, isEmpty } from 'lodash';
import { FCState } from 'store/state/FirechiefResponseState';
import ResourceState from 'common/enums/ResourceState';

export const loadEmergencyRequestAsync =
  (params: any) => (dispatch: Dispatch, getState: () => FCState) => {
    const { fireStationData } = getState().response;
    const stationId: number = fireStationData?.stationId || 0;
    dispatch(actions.responseLoadStart());
    // Fetch Initial Emergency Request
    apiFetchMagicUrl(getEmergencyFCRequests(params))
      .then(({ data, status }) => {
        if (status === 200) {
          if (data.count > 0) {
            const lastRecord = data?.dataset.length || 0;
            const dataVariable: any =
              data?.dataset[lastRecord === 1 ? 0 : lastRecord - 1];
            const responsePayload: IEmergencyResponse = {
              responseId: 0,
              requestId: dataVariable.requestId,
              createDate: dataVariable.createDate,
              updateDate: dataVariable.updateDate,
              deleted: 0,
              groupId: null,
              globalId: '',
              archived: dataVariable.archived,
              userId: dataVariable.userId,
              stationId: stationId,
              details: {
                status: ResourceState.UNDEFINED,
                createdAt: null,
                updatedAt: dataVariable.details.updatedAt,
                fireStationInfo: null,
                equipment: dataVariable.details.equipment.map(
                  (e: IEmergencyResponseDetailEquipment) => ({
                    code: e.code,
                    number: e.number,
                    description: e.description,
                    totalEquipment: dataVariable.details.equipment.length,
                    totalAcceptedEquipment: 0,
                    items: range(e.number > 10 ? 10 : e.number).map(() => ({
                      description: '',
                      code: '',
                    })),
                  })
                ),
                personnel: dataVariable.details.personnel.map(
                  (p: IEmergencyResponseDetailPersonnel) => ({
                    notes: p.notes,
                    number: p.number,
                    totalPersonnel: dataVariable.details.personnel.length,
                    credentialsNeeded: p.credentialsNeeded,
                    items: range(p.number > 10 ? 10 : p.number).map(() => ({
                      idNumber: '',
                      firstName: '',
                      lastName: '',
                      rateOfPay: 0,
                    })),
                  })
                ),
              },
            };
            // Check if new request
            if (params?.initialRequest) {
              dispatch(actions.responseLoadRequestData(dataVariable));
              return dispatch(actions.responseLoadSuccess(responsePayload));
            } else {
              return dispatch(actions.responseLoadRequestData(dataVariable));
            }
          } else {
            return dispatch(actions.responseLoadError('Expired Token'));
          }
        }
      })
      .catch((error) => {
        console.log('loadEmergencyRequestAsync catch:', error?.message);
        dispatch(actions.responseLoadError(error?.message));
      });
  };

export const loadResponseAsync =
  (params: any) => (dispatch: Dispatch, getState: () => FCState) => {
    const { fireStationData } = getState().response;
    const stationId: number = fireStationData?.stationId || 0;
    // console.log('loadResponseAsync params:', params);
    // console.log('loadResponseAsync stationId:', stationId);
    dispatch(actions.responseFetching());
    apiFetch(getEmergencyFCResponses(params))
      .then(async ({ data, status }) => {
        if (status === 200) {
          if (data.dataset.length > 0) {
            // console.log('loadResponseAsync:', data.dataset);
            const foundRes: IEmergencyResponse[] = data?.dataset?.filter(
              (el: any) => {
                return el.stationId === stationId;
              }
            );

            if (!isEmpty(foundRes)) {
              console.log('found Response for this station');
              // console.log('foundRes:', foundRes);
              const dataVariable = foundRes[0];
              const dataPayload: IEmergencyResponse = {
                responseId: dataVariable.responseId,
                requestId: dataVariable.requestId,
                createDate: dataVariable.createDate,
                updateDate: dataVariable.updateDate,
                deleted: 0,
                groupId: null,
                globalId: dataVariable.globalId,
                archived: dataVariable.archived,
                userId: dataVariable.userId,
                stationId: stationId,
                details: {
                  status: dataVariable.details.status,
                  createdAt: dataVariable.details.createdAt,
                  updatedAt: dataVariable.details.updatedAt,
                  fireStationInfo: dataVariable.details.fireStationInfo,
                  equipment: dataVariable.details.equipment,
                  personnel: dataVariable.details.personnel,
                },
              };
              dispatch(actions.responseLoadSuccess(dataPayload));
              const res = await apiFetch(getEmergencyFCRequests(params));
              const dataRequest = res.data.dataset[0];
              dispatch(actions.responseLoadRequestData(dataRequest));
            } else {
              console.log('no response for this station');
              dispatch(actions.responseLoadStart());
            }
          } else {
            //? There is no response created yet
            dispatch(actions.responseLoadStart());
          }
        }
      })
      .catch((error) => {
        console.log('loadResponseAsync catch:', error?.message);
        dispatch(actions.responseLoadError(error?.message));
      });
  };

export const loadFireStationData =
  (params: any) => async (dispatch: Dispatch) => {
    dispatch(actions.responseFetching());
    apiFetchMagicUrl(getNotificationDecode(params))
      .then(async ({ data, status }) => {
        if (status === 200) {
          await apiFetchMagicUrl(
            getFireStationInfo({
              stationId: data.stationId,
              access_token: params.access_token,
            })
          )
            .then(({ data, status }) => {
              if (status === 200)
                dispatch(actions.responseLoadStationData(data));
            })
            .catch((error) =>
              dispatch(actions.responseLoadError(error?.message))
            );
        }
      })
      .catch((error) => {
        console.log('loadFireStationData catch:', error?.message);
        dispatch(actions.responseLoadError(error?.message));
      });
  };
