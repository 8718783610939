import AES from 'crypto-js/aes';
import { AuthProtect } from 'common/enums';
import {
  IEmergencyRequest,
  IEmergencyResponse,
  IEndpoint,
} from 'common/interfaces';
import {
  EmergencyRequestParams,
  EmergencyResponseParams,
  FireStationParams,
  NotificationParams,
} from './apiParams';
import {
  payloadContact,
  payloadFireStationInfo,
} from 'helpers/forms/firestationForm';

export const authLogin: (username: string, password: string) => IEndpoint = (
  username: string,
  password: string
) => {
  const passwordKey = process.env.REACT_APP_PALMETTO_PASSWORD_KEY;
  if (!passwordKey) {
    throw { status: 400, message: 'Missing password encryption key' };
  }
  return {
    authorization: AuthProtect.NOT_REQUIRED,
    url: 'accounts/login',
    method: 'get',
    params: {
      password: AES.encrypt(password, passwordKey).toString(),
      username,
    },
  };
};

export const getAccount: (id: number) => IEndpoint = (id) => ({
  authorization: AuthProtect.REQUIRED,
  url: `accounts/${id}`,
  method: 'get',
});

export const getRegions: IEndpoint = {
  authorization: AuthProtect.REQUIRED,
  url: 'stations/regions',
  method: 'get',
  prod: true,
};

// Emergency Request
export const getEmergencyRequests: (
  params?: Partial<EmergencyRequestParams>
) => IEndpoint = (params) => ({
  authorization: AuthProtect.REQUIRED,
  url: `requests?${params?.requestId || ''}`,
  method: 'get',
  prod: true,
  params,
});

export const getEmergencyFCRequests: (
  params?: Partial<EmergencyRequestParams>
) => IEndpoint = (params) => ({
  authorization: AuthProtect.NOT_REQUIRED,
  url: `requests?${params?.requestId || ''}`,
  method: 'get',
  prod: true,
  params,
});

export const getEmergencyRequestsById: (
  params?: Partial<EmergencyRequestParams>
) => IEndpoint = (params) => ({
  authorization: AuthProtect.REQUIRED,
  url: `requests/${params?.requestId}?`,
  method: 'get',
  prod: true,
});

export const createEmergencyRequest: (
  payload: Partial<IEmergencyRequest>
) => IEndpoint = (payload) => {
  return {
    authorization: AuthProtect.REQUIRED,
    url: 'requests',
    method: 'post',
    data: payload,
    prod: true,
  };
};

export const updateEmergencyRequest: (
  payload: Partial<IEmergencyRequest>
) => IEndpoint = (payload) => {
  return {
    authorization: AuthProtect.REQUIRED,
    url: `requests/${payload.requestId}`,
    method: 'put',
    data: payload,
    prod: true,
  };
};

export const getNotificationRequestId: (
  params?: Partial<EmergencyRequestParams>
) => IEndpoint = (params) => ({
  authorization: AuthProtect.REQUIRED,
  url: `requests/${params?.requestId || ''}/notifications`,
  method: 'get',
  prod: true,
});

export const sendNotificationReqeustByStation: (
  params?: Partial<NotificationParams>
) => IEndpoint = (params) => ({
  authorization: AuthProtect.REQUIRED,
  url: `requests/${params?.requestId || ''}/notify/${params?.stationId || ''}`,
  method: 'get',
  prod: true,
});

export const sendNotificationRequestAll: (
  params?: Partial<NotificationParams>
) => IEndpoint = (params) => ({
  authorization: AuthProtect.REQUIRED,
  url: `requests/${params?.requestId || ''}/notify`,
  method: 'get',
  prod: true,
});

// Emergency Response
export const getEmergencResponses: (
  params?: Partial<EmergencyResponseParams>
) => IEndpoint = (params) => ({
  authorization: AuthProtect.REQUIRED,
  url: `responses?${params?.requestId || ''}`,
  method: 'get',
  prod: true,
  params,
});

export const getEmergencyResponseByRequestId: (
  params?: Partial<EmergencyResponseParams>
) => IEndpoint = (params) => ({
  authorization: AuthProtect.REQUIRED,
  url: `requests/${params?.requestId || ''}/responses`,
  method: 'get',
  prod: true,
  params,
});

export const getEmergencyFCResponses: (
  params?: Partial<EmergencyResponseParams>
) => IEndpoint = (params) => ({
  authorization: AuthProtect.NOT_REQUIRED,
  url: `responses?${params?.requestId || ''}`,
  method: 'get',
  prod: true,
  params,
});

export const createEmergencyResponse: (
  payload: Partial<IEmergencyResponse>,
  params?: Partial<EmergencyResponseParams>
) => IEndpoint = (payload, params) => {
  return {
    authorization: params?.authorize
      ? AuthProtect.REQUIRED
      : AuthProtect.NOT_REQUIRED,
    url: `responses?access_token=${params?.access_token}`,
    method: 'post',
    data: payload,
    prod: true,
  };
};

export const updateEmergencyResponse: (
  payload: Partial<IEmergencyResponse>,
  params?: Partial<EmergencyResponseParams>
) => IEndpoint = (payload, params) => {
  return {
    authorization: params?.authorize
      ? AuthProtect.REQUIRED
      : AuthProtect.NOT_REQUIRED,
    url: `responses/${payload.responseId}?access_token=${params?.access_token}`,
    method: 'put',
    data: payload,
    prod: true,
  };
};

export const getNotificationDecode: (
  params?: Partial<EmergencyResponseParams>
) => IEndpoint = (params) => ({
  authorization: AuthProtect.NOT_REQUIRED,
  url: `notifications/decode?${params?.requestId || ''}`,
  method: 'get',
  prod: true,
  params,
});

// Fire Stations
export const getFireStationInfo: (
  params?: Partial<EmergencyResponseParams>
) => IEndpoint = (params) => ({
  authorization: AuthProtect.NOT_REQUIRED,
  url: `stations/${params?.stationId || ''}?access_token=${
    params?.access_token
  }`,
  method: 'get',
  prod: true,
  // params,
});

export const getFireStationList: (
  params?: Partial<FireStationParams>
) => IEndpoint = (params) => ({
  authorization: AuthProtect.REQUIRED,
  url: 'stations',
  method: 'get',
  prod: true,
  params,
});

export const getFireStationCounty: (
  params?: Partial<FireStationParams>
) => IEndpoint = (params) => ({
  authorization: AuthProtect.REQUIRED,
  url: `stations/counties/${params?.county || ''}`,
  method: 'get',
  prod: true,
  params,
});

export const getFireStationCity: (
  params?: Partial<FireStationParams>
) => IEndpoint = (params) => ({
  authorization: AuthProtect.REQUIRED,
  url: `stations/cities/${params?.city || ''}`,
  method: 'get',
  prod: true,
  params,
});

export const postFireStation: (
  payload?: Partial<payloadFireStationInfo>
) => IEndpoint = (payload) => ({
  authorization: AuthProtect.REQUIRED,
  url: 'stations',
  method: 'post',
  data: payload,
  prod: true,
});

export const putFireStation: (
  payload?: Partial<payloadFireStationInfo>,
  params?: Partial<FireStationParams>
) => IEndpoint = (payload, params) => ({
  authorization: AuthProtect.REQUIRED,
  url: `stations/${params?.stationId || ''}`,
  method: 'put',
  data: payload,
  prod: true,
});

export const gerFireStationById: (
  params?: Partial<FireStationParams>
) => IEndpoint = (params) => ({
  authorization: AuthProtect.REQUIRED,
  url: `stations/${params?.stationId || ''}`,
  method: 'get',
  prod: true,
});
export const gerFireStationById2: (
  params?: Partial<FireStationParams>
) => IEndpoint = (params) => ({
  authorization: AuthProtect.REQUIRED,
  url: `stations/${params?.stationId || ''}/contacts`,
  method: 'get',
  prod: true,
});

// Contacts
export const postContacts: (payload?: Partial<payloadContact>) => IEndpoint = (
  payload
) => ({
  authorization: AuthProtect.REQUIRED,
  url: 'contacts',
  method: 'post',
  data: payload,
  prod: true,
});

export const putContacts: (
  payload?: Partial<payloadContact>,
  params?: Partial<FireStationParams>
) => IEndpoint = (payload, params) => ({
  authorization: AuthProtect.REQUIRED,
  url: `contacts/${params?.contactId || ''}`,
  method: 'put',
  data: payload,
  prod: true,
});

export const deleteStation: (
  payload?: Partial<payloadFireStationInfo>,
  params?: Partial<FireStationParams>
) => IEndpoint = (payload, params) => ({
  authorization: AuthProtect.REQUIRED,
  url: `stations/${params?.stationId}`,
  method: 'delete',
  data: payload,
  prod: true,
});
