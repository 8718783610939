export enum ResponseActypeTypes {
  RESPONSE_LOAD_START = 'RESPONSE_LOAD_START',
  RESPONSE_LOAD_FETCH = 'RESPONSE_LOAD_FETCH',
  RESPONSE_LOAD_SUCCESS = 'RESPONSE_LOAD_SUCCESS',
  RESPONSE_LOAD_ERROR = 'RESPONSE_LOAD_ERROR',
  RESPONSE_LOAD_FSTATION = 'RESPONSE_LOAD_FSTATION',
  RESPONSE_LOAD_REQUEST = 'RESPONSE_LOAD_REQUEST',
  RESPONSE_CREATE_RESPONSE = 'RESPONSE_CREATE_RESPONSE',
  RESPONSE_UPDATE_RESPONSE = 'RESPONSE_UPDATE_RESPONSE',
}
