/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { MouseEvent } from 'react';
import { Box } from '@mui/material';
import { ModalProps } from '@mui/material/Modal';
import { Button, Modal, Typo } from 'components/primitives';
import useStyles from './AlertMsgModal.styles';

const ConfirmationModalView = ({
  className,
  loading = false,
  title = 'Please confirm',
  onPress,
  ...props
}: {
  loading?: boolean;
  title?: string;
  onPress?: (e: MouseEvent, confirm: boolean) => void;
} & ModalProps) => {
  const classes = useStyles();
  const onTouchButton = (e: MouseEvent, confirm: boolean) => {
    if (!loading) {
      onPress && onPress(e, confirm);
    }
  };
  return (
    <Modal {...props} className={classes.root}>
      <React.Fragment>
        <Box className={classes.content}>
          <Typo variant="h3" mb={2}>
            {title}
          </Typo>
          {props.children}
        </Box>
        <Box className={classes.footer}>
          {!loading && (
            <Button
              onClick={(e) => onTouchButton(e, false)}
              neutral={false}
              fullWidth
            >
              Ok
            </Button>
          )}
        </Box>
      </React.Fragment>
    </Modal>
  );
};

export default ConfirmationModalView;
