import {
  IEmergencyResponse,
  IEmergencyResponseFireStationData,
} from 'interfaces/EmergencyResponse';
import { IEmergencyRequest } from 'interfaces/EmergencyRequest';
import { Nullable } from 'common/utils/Nullable';

export interface ResponseStateType {
  isLoading: boolean;
  initialRequest: boolean;
  errorMessage: Nullable<string>;
  emergencyResponse: Nullable<IEmergencyResponse>;
  fireStationData: Nullable<IEmergencyResponseFireStationData>;
  emergencyRequest: Nullable<IEmergencyRequest>;
}

export const ResponseState: ResponseStateType = {
  isLoading: false,
  initialRequest: false,
  errorMessage: null,
  emergencyResponse: null,
  fireStationData: null,
  emergencyRequest: null,
};

export interface FCState {
  response: ResponseStateType;
}
