import { UseFormProps } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Nullable } from 'common/utils/Nullable';
import { IFireStation } from 'interfaces/FireStations';

export interface contactInfoForm {
  contactId?: number;
  fullName: string;
  workPhone: string;
  cellPhone: string;
  emailAddress: string;
}

export interface generalInfoForm {
  name: Nullable<string>;
  county: Nullable<string>;
  city: Nullable<string>;
  address1: Nullable<string>;
  address2: Nullable<string>;
  zip?: Nullable<string>;
}

export interface FireStationForm {
  generalInformation: Nullable<generalInfoForm | IFireStation>;
  contact1: contactInfoForm;
  contact2: contactInfoForm;
}

export interface payloadFireStationInfo {
  details: Nullable<generalInfoForm | IFireStation>;
}

export interface payloadContact {
  details: contactInfoForm;
  stationId?: string | number;
}
export interface payloadFireStationContact {
  details: Nullable<generalInfoForm | IFireStation>;
  contacts: payloadContact[];
}

export const schema = yup.object().shape({
  name: yup.string().required('This field is required'),
  county: yup.string().required('This field is required'),
  city: yup.string().required('This field is required'),
  address1: yup.string().required('This field is required'),
});

export const schemaDetail = yup.object().shape({
  generalInformation: yup.object({
    name: yup.string().required('This field is required'),
    county: yup.string().required('This field is required'),
    city: yup.string().required('This field is required'),
    address1: yup.string().required('This field is required'),
  }),
  contact1: yup.object({
    fullName: yup.string().required('This field is required'),
    workPhone: yup
      .string()
      .min(12, 'Required a 10 digit number')
      .required('This field is required'),
    cellPhone: yup
      .string()
      .min(12, 'Required a 10 digit number')
      .required('This field is required'),
    emailAddress: yup
      .string()
      .email('Invalid email format')
      .required('This field is required'),
  }),
  contact2: yup.object().shape(
    {
      fullName: yup.string().notRequired(),
      workPhone: yup
        .string()
        .notRequired()
        .nullable()
        .when('workPhone', {
          is: (value: string) => value?.length,
          then: (rule) => rule.min(12, 'Required a 10 digit number'),
        }),
      cellPhone: yup
        .string()
        .notRequired()
        .nullable()
        .when('workPhone', {
          is: (value: string) => value?.length,
          then: (rule) => rule.min(12, 'Required a 10 digit number'),
        }),
      emailAddress: yup.string().email('Invalid email format'),
    },
    [['workPhone', 'workPhone']]
  ),
});

export const FireStationFormProps = ({
  formData = {
    generalInformation: {
      name: '',
      county: '',
      city: '',
      address1: '',
      address2: '',
    },
    contact1: {
      contactId: 0,
      fullName: '',
      workPhone: '',
      cellPhone: '',
      emailAddress: '',
    },
    contact2: {
      contactId: 0,
      fullName: '',
      workPhone: '',
      cellPhone: '',
      emailAddress: '',
    },
  },
}: {
  formData?: Partial<FireStationForm>;
}): Partial<UseFormProps> => {
  return {
    defaultValues: formData,
    resolver: yupResolver(schemaDetail),
  };
};

export const FireStationModalFormProps = ({
  formData = {
    name: '',
    county: '',
    city: '',
    address1: '',
    address2: '',
  },
}: {
  formData?: Partial<generalInfoForm>;
}): Partial<UseFormProps> => {
  return {
    defaultValues: formData,
    resolver: yupResolver(schema),
  };
};
