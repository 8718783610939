import { UseFormProps } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import IAccount from 'common/interfaces/Account';

const schema = yup.object().shape({
  pvFirstName: yup.string().required('This field is required'),
  pvLastName: yup.string().required('This field is required'),
  pvAddress: yup.string().required('This field is required'),
});

export const ProfileFormProps = ({
  formData = {
    pvAccountID: 0,
    pvAccountLocked: 0,
    pvAdministrator: 0,
    pvComment: '',
    pvCreateDate: '',
    pvEmail: '',
    pvEmailVerified: false,
    pvFirstName: '',
    pvGlobalID: '',
    pvLastName: '',
    pvLastUpdate: null,
    pvLocation: null,
    pvMiddleName: '',
    pvMobilePhone: '',
    pvOfficePhone: '',
    pvOrganizationUnitName: null,
    pvStatus: '',
    pvSupervisor: null,
    pvUsername: '',
    pvAddress: '',
  },
}: {
  formData?: Partial<IAccount>;
}): Partial<UseFormProps> => {
  return {
    defaultValues: formData,
    resolver: yupResolver(schema),
  };
};
