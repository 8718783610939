import React from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { ExportIcon } from 'common/icons';
import { Container, Fade, IconButton, Pagination, Stack } from '@mui/material';
import { IEmergencyRequest } from 'interfaces/EmergencyRequest';
import { TitleText } from 'components/primitives';
import { RequestItemBox } from 'components/fragments';
import { PreLoaderSnippet } from 'components/snippets';

import { EmergencyArchiveProps } from './EmergencyArchive.props';
import useStyles from './EmergencyArchive.styles';

const EmergencyArchiveView: React.FC<EmergencyArchiveProps> = ({
  awaitRequest,
  emergencies,
  pageTotal,
  ...props
}: EmergencyArchiveProps) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const isEmpty = !awaitRequest && emergencies?.length > 0;

  const onNavigateToEmergency = (e: IEmergencyRequest) => () => {
    navigate(`/dashboard/emergencies/${e.requestId}`, {
      state: {
        emergencyData: e,
      },
    });
  };

  return (
    <Container>
      <TitleText title="Archived Incidents" />
      <PreLoaderSnippet
        visible={awaitRequest}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '300px',
        }}
      />
      <Fade in={!awaitRequest && isEmpty}>
        <Stack spacing={1}>
          {emergencies?.map((e: any, i) => {
            const createdAt = moment(e.createDate).format('L');
            const regions = e?.details.regions?.length || 0;
            const counties = e?.details.regions.reduce(
              (sum: number, r: any) => sum + r.counties?.length,
              0
            );
            return (
              <RequestItemBox
                key={e.requestId}
                name={`Request #${e.requestId}`}
                emergencyData={{}}
                meta={[
                  {
                    label: 'Created on',
                    value: moment(createdAt).format('L'),
                  },
                  {
                    label: 'Sent to',
                    value: `${regions} Regions / ${counties} Counties`,
                  },
                  { label: 'Responses', value: '0' },
                ]}
              >
                <IconButton onClick={onNavigateToEmergency(e)}>
                  <ExportIcon className={classes.exportIcon} />
                </IconButton>
              </RequestItemBox>
            );
          })}
        </Stack>
      </Fade>
      {pageTotal > 1 && (
        <Pagination
          className={classes.pagination}
          count={pageTotal}
          page={props.page}
          shape="rounded"
          onChange={(e, num) => props.handlePageChange(num)}
        />
      )}
    </Container>
  );
};

export default EmergencyArchiveView;
