import { IRegion } from 'interfaces/Region';
import { Dispatch } from 'redux';
import apiFetch, { getRegions } from 'services/apiFetch';
import { pageActions } from 'store/actions';
import RequestPageState, {
  RequestPageEmptyState,
} from 'store/state/pages/RequestPageState';

const identityPage =
  (key: string, state: Partial<RequestPageState>) =>
  async (dispatch: Dispatch): Promise<any> => {
    const identity: RequestPageState = {
      ...RequestPageEmptyState,
      ...state,
    };
    if (!identity?.regions || identity.regions?.length === 0) {
      const res = await apiFetch(getRegions).then((r) => r.data || []);
      identity.regions =
        res.regions.sort((a: IRegion, b: IRegion) => a.region - b.region) || [];
    }
    dispatch(pageActions.identify({ ...identity, key }));
  };

export default identityPage;
