import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {},
    titleMeta: {
      flex: 1,
      display: 'flex',
      paddingLeft: '8px',
      justifyContent: 'flex-end',
      columnGap: '8px',
      '& strong': {
        color: theme.palette.grey[900],
      },
    },
    flexRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    archive: {
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      padding: `0 ${theme.spacing(1)}`,
      color: theme.palette.grey[900],
      '&:hover': {
        color: theme.palette.primary.main,
      },
      [theme.breakpoints.down('sm')]: {
        padding: 0,
      },
    },
    more: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: 0,
      width: '60px',
      height: 56,
      borderRadius: 5,
      backgroundColor: theme.palette.grey[100],
      '& svg': {
        margin: '8px 0 3px',
      },
      [theme.breakpoints.down('md')]: {
        height: 42,
      },
    },
    resourcesWrapper: {
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    resourceItem: {
      [theme.breakpoints.up('md')]: {
        minWidth: '170px',
        height: 56,
      },
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(1),
      },
    },
    responseBadge: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '32px',
      height: '32px',
      borderRadius: '5px',
      backgroundColor: theme.palette.grey[400],
      color: theme.palette.grey[50],
    },
  };
});

export default useStyles;
