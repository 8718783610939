import { Dispatch } from 'redux';
import actions from 'store/actions/firestations';
import {
  payloadContact,
  payloadFireStationContact,
} from 'helpers/forms/firestationForm';
import { QueryState } from 'common/enums';
import apiFetch, {
  deleteStation,
  FireStationParams,
  postContacts,
  postFireStation,
  putContacts,
  putFireStation,
} from 'services/apiFetch';
import { AppFireState } from 'store/state/FireStationsState';

export const createContacts =
  (payload: {
    details: {
      emailAddress: string;
      fullName: string;
      workPhone: string;
      cellPhone: string;
    };
    stationId: string | number;
  }) =>
  async (dispatch: Dispatch, getState: () => AppFireState) => {
    const { isLoading } = getState().firestation;

    dispatch(actions.updateQueryStatus({ queryStatus: QueryState.AWAIT }));
    const endpoint = postContacts(payload);
    if (isLoading === QueryState.READY) {
      await apiFetch(endpoint)
        .then(() => {
          console.log('Done manual create contact');
        })
        .catch((e) => console.log(e));
    }
  };

export const updateContacts =
  (payload: payloadContact) =>
  (dispatch: Dispatch, getState: () => AppFireState) => {
    const { isLoading } = getState().firestation;
    if (isLoading === QueryState.READY) {
      dispatch(actions.updateQueryStatus({ queryStatus: QueryState.AWAIT }));
      const contactPayload: payloadContact = {
        details: {
          fullName: payload.details.fullName,
          workPhone: payload.details.workPhone,
          cellPhone: payload.details.cellPhone,
          emailAddress: payload.details.emailAddress,
        },
      };
      const endpoint = putContacts(contactPayload, {
        contactId: payload.details.contactId,
      });

      apiFetch(endpoint)
        .then(() => {
          dispatch(
            actions.updateQueryStatus({ queryStatus: QueryState.UPDATED })
          );
        })
        .catch((e) => console.log(e));
    }
  };

export const updateFireStation =
  (payload: payloadFireStationContact, params: FireStationParams) =>
  async (dispatch: Dispatch) => {
    dispatch(actions.updateQueryStatus({ queryStatus: QueryState.AWAIT }));

    const endpoint = putFireStation(payload, params);
    await apiFetch(endpoint)
      .then(() => {
        dispatch(
          actions.updateQueryStatus({ queryStatus: QueryState.UPDATED })
        );
      })
      .catch((e) => {
        console.log(e);
        dispatch(actions.responseError(e?.message));
      });
  };

export const createFireStation =
  (payload: payloadFireStationContact) => async (dispatch: Dispatch) => {
    dispatch(actions.updateQueryStatus({ queryStatus: QueryState.AWAIT }));

    const endpointFS = postFireStation(payload);
    await apiFetch(endpointFS)
      .then(() => {
        dispatch(
          actions.updateQueryStatus({ queryStatus: QueryState.CREATED })
        );
      })
      .catch((e) => {
        dispatch(actions.responseError(e?.message));
      });
  };

export const deleteFireStation =
  (payload: payloadFireStationContact, params: FireStationParams) =>
  async (dispatch: Dispatch) => {
    dispatch(actions.updateQueryStatus({ queryStatus: QueryState.AWAIT }));
    const endpointFS = deleteStation(payload, params);

    return await apiFetch(endpointFS)
      .then((res) => {
        dispatch(
          actions.updateQueryStatus({ queryStatus: QueryState.UPDATED })
        );
        return res;
      })
      .catch((e) => {
        dispatch(actions.responseError(e?.message));
      });
  };
