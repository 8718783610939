import React, { MouseEvent } from 'react';
import { FormProvider } from 'react-hook-form';
import { find, findIndex, flatMap } from 'lodash';
import {
  ArrowForward as ArrowForwardIcon,
  Send as SendIcon,
} from '@mui/icons-material';
import { Alert, Box, Container, Paper, Snackbar, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { PaperLayout } from 'components/layouts';
import { StackableSteps } from 'components/fragments';
import { Typo } from 'components/primitives';
import Button from 'components/primitives/Button';
import { ConfirmationModal } from 'components/modals';
import {
  EmergencyDetails,
  EmergencyEquipment,
  EmergencyPersonnel,
  EmergencyRegions,
  EmergencySendRequest,
} from 'components/fragments/EmergencyForms';
import { RequestFormStep } from 'helpers/forms/requestForm';
import { EmergencyRequestProps } from './EmergencyRequest.props';
import useStyles from './EmergencyRequest.styles';
import _ from 'lodash';

const StepButton = ({
  lastStep,
  progress = false,
  isDisable = false,
  onClick,
}: {
  lastStep: boolean;
  progress: boolean;
  isDisable: boolean;
  onClick: () => void;
}) => {
  return (
    <Button
      endIcon={
        lastStep ? (
          <SendIcon fontSize="small" />
        ) : (
          <ArrowForwardIcon fontSize="small" />
        )
      }
      progress={progress}
      onClick={onClick}
      fullWidth
      disabled={isDisable}
    >
      {lastStep ? 'Send' : 'Next'}
    </Button>
  );
};

const EmergencyRequestView: React.FC<EmergencyRequestProps> = ({
  alertError,
  alertMessage,
  awaitRequest,
  emergencyData,
  form,
  step,
  stepHistory,
  ...props
}: EmergencyRequestProps) => {
  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const stepIndex = findIndex(stepHistory, { step });
  const stepInfo = stepHistory[stepIndex];
  const resourcesStep =
    step === RequestFormStep.PERSONNEL || step === RequestFormStep.EQUIPMENT;
  const lastStep = step === RequestFormStep.SEND_REQUEST;
  const stepTitle = stepInfo?.title || stepInfo?.step;
  const formValue = form.getValues();
  const regions = formValue?.regions?.flatMap((i: any) => i.counties) || [];
  const countSelectedRegions = regions?.filter((c: any) => c.checked) || [];
  const sumOfRegionsCounty = _.sumBy(
    countSelectedRegions,
    (value: any) => value.stationsNumber
  );

  const onNextStep = () => {
    if (lastStep) props.handleSubmit();
    else {
      const index = findIndex(stepHistory, { step: stepInfo?.step });
      const nextStep = stepHistory[index + 1];
      if (nextStep) {
        props.handleChangeStep(nextStep.step as RequestFormStep);
      }
    }
  };
  const onConfirm = (e: MouseEvent, confirm: boolean) => {
    props.handleConfirmation(confirm);
  };
  const onJumpTo = (next: number | string) => {
    if (!awaitRequest && step !== next) {
      props.handleChangeStep(next as RequestFormStep);
    }
  };

  return (
    <React.Fragment>
      <Container component="main">
        {isMobile && (
          <PaperLayout sx={{ mt: 2, mb: -1, py: 1, px: 1 }} elevation={0}>
            <Stack direction="row" columnGap={1.5} alignItems="center">
              <Box className={classes.stepBox}>
                <Typo variant="h3">{stepIndex + 1}</Typo>
              </Box>
              <Typo variant="h4">{stepTitle}</Typo>
            </Stack>
          </PaperLayout>
        )}

        {/* Alert error message */}
        <Snackbar
          open={alertError}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          sx={{ mt: 5 }}
        >
          <Alert
            variant="outlined"
            severity="error"
            sx={{ width: '100%', backgroundColor: 'common.white' }}
            onClose={props.hideAlertError}
          >
            {alertMessage || ''}
          </Alert>
        </Snackbar>

        {/* Main content */}
        <PaperLayout
          className={isMobile ? classes.disableWrap : ''}
          elevation={isMobile ? 0 : 3}
          titleView={
            !isMobile && (
              <Stack
                flexDirection={isMobile ? 'column' : 'row'}
                alignItems={isMobile ? 'flex-start' : 'flex-end'}
                columnGap={1}
                mb={1}
              >
                <Typo variant="h4">{stepTitle}</Typo>
                {emergencyData && (
                  <Typo variant="body1" color="textSecondary">
                    Resource request #{emergencyData?.requestId}
                  </Typo>
                )}
              </Stack>
            )
          }
        >
          <Stack direction="row">
            {/* Form content */}
            <Box
              className={classes.formView}
              sx={{ px: { xs: 0, lg: 2 }, mr: { lg: 2 } }}
            >
              <FormProvider {...form}>
                <EmergencyDetails
                  hidden={step !== RequestFormStep.REQUEST_DETAILS}
                  name={RequestFormStep.REQUEST_DETAILS}
                />
                <EmergencyPersonnel
                  hidden={step !== RequestFormStep.PERSONNEL}
                  name={`resources.${RequestFormStep.PERSONNEL}`}
                />
                <EmergencyEquipment
                  hidden={step !== RequestFormStep.EQUIPMENT}
                  name={`resources.${RequestFormStep.EQUIPMENT}`}
                />
                <EmergencyRegions
                  hidden={step !== RequestFormStep.REGIONS}
                  name={RequestFormStep.REGIONS}
                />
                <EmergencySendRequest
                  hidden={step !== RequestFormStep.SEND_REQUEST}
                />
              </FormProvider>
            </Box>

            {/* Right pane */}
            {!isMobile && (
              <Paper
                className={classes.sidebar}
                elevation={0}
                variant="outlined"
                sx={{ p: { lg: 2 } }}
              >
                <StackableSteps
                  sx={{ flex: '0 1 100%' }}
                  active={step}
                  steps={stepHistory}
                  onStep={onJumpTo}
                />
                <Stack rowGap={1}>
                  {/* <Box className={classes.notes}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Enim ut fames sagittis fusce. Nec phasellus id mauris, eget
                    rutrum diam imperdiet laoreet.
                  </Box> */}
                  {resourcesStep && (
                    <Button
                      onClick={form.handleSubmit(onNextStep)}
                      sx={{ mb: 1 }}
                      alternate
                      fullWidth
                    >
                      Skip
                    </Button>
                  )}
                  <StepButton
                    lastStep={lastStep}
                    progress={awaitRequest}
                    onClick={form.handleSubmit(onNextStep)}
                    isDisable={props.disableStep}
                  />
                </Stack>
              </Paper>
            )}
          </Stack>
        </PaperLayout>
      </Container>

      {isMobile && (
        <Box className={classes.footerView}>
          {resourcesStep && (
            <Paper elevation={8} className={classes.footerSkip}>
              <Button onClick={form.handleSubmit(onNextStep)} alternate>
                Skip
              </Button>
            </Paper>
          )}
          <Paper elevation={8} className={classes.footerNext}>
            <StepButton
              lastStep={lastStep}
              progress={awaitRequest}
              onClick={form.handleSubmit(onNextStep)}
              isDisable={props.disableStep}
            />
          </Paper>
        </Box>
      )}

      <ConfirmationModal
        open={props.openConfirmation}
        title="Send confirmation"
        onConfirm={onConfirm}
      >
        <React.Fragment>
          <Typo variant="body1" mb={2}>
            This Resource Request will be sent to{' '}
            <strong>{sumOfRegionsCounty}</strong> Fire Stations.
          </Typo>
          <Typo variant="body2">You can send it or edit if needed</Typo>
        </React.Fragment>
      </ConfirmationModal>
    </React.Fragment>
  );
};

export default EmergencyRequestView;
