import React, { MouseEvent, useState, useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import {
  ArrowForward as ArrowForwardIcon,
  Send as SendIcon,
} from '@mui/icons-material';
import {
  Box,
  Container,
  Fade,
  Grid,
  LinearProgress,
  Paper,
  Stack,
  Snackbar,
  Alert,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { MainLayout, PaperLayout } from 'components/layouts';
import { StackableSteps } from 'components/fragments';
import Button from 'components/primitives/Button';
import { Typo } from 'components/primitives';
import { ConfirmationModal, AlertMsgModal } from 'components/modals';
import { ResponseFormStep } from 'helpers/forms/responseForm';
import { FireChiefResponseProps } from './FireChiefResponse.props';
import useStyles from './FireChiefResponse.styles';
import {
  RequestEquipmentContext,
  RequestPersonnelContext,
} from 'components/fragments/ResponseForms';
import { isUndefined, findIndex } from 'lodash';

const FireChiefResponseView: React.FC<FireChiefResponseProps> = ({
  awaitSave,
  awaitState,
  emergencyData,
  totalRegions,
  step,
  form,
  stepHistory,
  alertError,
  alertMessage,
  ...props
}: FireChiefResponseProps) => {
  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const stepInfo = stepHistory?.find((s) => s.name === step) || stepHistory[0];
  const lastStep = step === ResponseFormStep.EQUIPMENT;
  const isDisabled = emergencyData?.details?.status === 1;
  const [errorFormStatus, setErrorFormStatus] = useState<boolean>(false);
  const formErrorMessage: string = form.formState.errors.equipment?.message;

  useEffect(() => {
    if (!isUndefined(formErrorMessage)) {
      setErrorFormStatus(true);
    }
    return () => setErrorFormStatus(false);
  }, [formErrorMessage]);

  const onSubmit = () => {
    // return;
    if (awaitSave) return;
    if (lastStep) props.handleSubmit();
    else {
      const index = findIndex(stepHistory, { step: stepInfo?.step });
      const nextStep = stepHistory[index + 1];

      if (nextStep) {
        props.handleChangeStep(nextStep.name as ResponseFormStep);
      }
    }
  };
  const onConfirm = (_e: MouseEvent, confirm: boolean) =>
    props.handleConfirmation(confirm);

  const onJumpTo = (next: number | string) => {
    let stepName = '';
    if (typeof next === 'string') {
      stepName = next;
    }
    if (typeof next === 'number') {
      switch (next) {
        case 1:
          stepName = ResponseFormStep.PERSONNEL;
          break;
        case 2:
          stepName = ResponseFormStep.EQUIPMENT;
          break;
      }
    }
    if (!awaitSave && step !== stepName) {
      props.handleChangeStep(stepName as ResponseFormStep);
    }
  };

  const renderNextButton = () => {
    return (
      <Button
        endIcon={
          lastStep ? (
            <SendIcon fontSize="small" />
          ) : (
            <ArrowForwardIcon fontSize="small" />
          )
        }
        fullWidth
        progress={awaitSave}
        disableRipple={awaitSave}
        onClick={form.handleSubmit(onSubmit)}
        disabled={lastStep && isDisabled}
      >
        {lastStep ? 'Send' : 'Next'}
      </Button>
    );
  };

  const SuccessMessage: React.FC = () => (
    <Container component="main" sx={{ mb: { xs: 7 } }}>
      <PaperLayout
        elevation={3}
        titleView={
          <Stack flexDirection="row" justifyContent="space-between" pb={1}>
            <Stack
              flexDirection={isMobile ? 'column' : 'row'}
              alignItems={isMobile ? 'flex-start' : 'flex-end'}
              columnGap={1}
            >
              <Typo variant="h4">Request response</Typo>
            </Stack>
          </Stack>
        }
      >
        <Typo variant="body1" mb={1}>
          The response has been created and sent to the Fire Station
          Coordinator.
        </Typo>
      </PaperLayout>
    </Container>
  );

  const UpdateMessage: React.FC = () => (
    <Container component="main" sx={{ mb: { xs: 7 } }}>
      <PaperLayout
        elevation={3}
        titleView={
          <Stack flexDirection="row" justifyContent="space-between" pb={1}>
            <Stack
              flexDirection={isMobile ? 'column' : 'row'}
              alignItems={isMobile ? 'flex-start' : 'flex-end'}
              columnGap={1}
            >
              <Typo variant="h4">Request response</Typo>
            </Stack>
          </Stack>
        }
      >
        <Typo variant="body1" mb={1}>
          The response has been updated and sent to the Fire Station
          Coordinator.
        </Typo>
      </PaperLayout>
    </Container>
  );

  const ExpiredTokenMessage: React.FC = () => (
    <Container component="main" sx={{ mb: { xs: 7 } }}>
      <PaperLayout
        elevation={3}
        titleView={
          <Stack flexDirection="row" justifyContent="space-between" pb={1}>
            <Stack
              flexDirection={isMobile ? 'column' : 'row'}
              alignItems={isMobile ? 'flex-start' : 'flex-end'}
              columnGap={1}
            >
              <Typo variant="h4">Request response</Typo>
            </Stack>
          </Stack>
        }
      >
        <Typo variant="body1" mb={1}>
          The email link has expired, please contact the fire fighter
          mobilization coordinator for more information.
        </Typo>
      </PaperLayout>
    </Container>
  );

  if (!awaitState && props.responseSuccess) return <SuccessMessage />;
  if (!awaitState && props.updateSuccess) return <UpdateMessage />;
  if (!awaitState && props.tokenExpired) return <ExpiredTokenMessage />;

  return (
    <MainLayout toolbar={(awaitSave || awaitState) && <LinearProgress />}>
      <Container component="main" sx={{ mb: { xs: 7 } }}>
        {/* Alert error message */}
        <Snackbar
          open={alertError}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          sx={{ mt: 5 }}
        >
          <Alert
            variant="outlined"
            severity="error"
            sx={{ width: '100%', backgroundColor: 'common.white' }}
            onClose={props.hideAlertError}
          >
            {alertMessage}
          </Alert>
        </Snackbar>
        <Fade in={!awaitState}>
          <Box>
            {isMobile && (
              <PaperLayout sx={{ mt: 2, mb: -1, py: 1, px: 1 }} elevation={0}>
                <Stack direction="row" columnGap={1.5} alignItems="center">
                  <Box className={classes.stepBox}>
                    <Typo variant="h3">{step === 'personnel' ? 1 : 2}</Typo>
                  </Box>
                  <Typo variant="h4">Add {step}</Typo>
                </Stack>
              </PaperLayout>
            )}

            {/* Main content */}
            <PaperLayout
              className={isMobile ? classes.disableWrap : ''}
              elevation={isMobile ? 0 : 3}
              titleView={
                !isMobile && (
                  <Stack
                    flexDirection="row"
                    justifyContent="space-between"
                    pb={1}
                  >
                    <Stack
                      flexDirection={isMobile ? 'column' : 'row'}
                      alignItems={isMobile ? 'flex-start' : 'flex-end'}
                      columnGap={1}
                    >
                      <Typo variant="h4">Request response</Typo>
                      {emergencyData && (
                        <Typo variant="body1" color="textSecondary">
                          Resource request #{emergencyData?.requestId}
                        </Typo>
                      )}
                    </Stack>
                    <Typo variant="h4" color="textSecondary">
                      Requested
                    </Typo>
                  </Stack>
                )
              }
            >
              <Grid columnSpacing={3} container>
                <Grid item xs={12} lg={8} className={classes.formView}>
                  <FormProvider {...form}>
                    <RequestPersonnelContext
                      hidden={step !== ResponseFormStep.PERSONNEL}
                      name={ResponseFormStep.PERSONNEL}
                      emergencyDetails={emergencyData?.details}
                      isDisabled={isDisabled}
                    />
                    <RequestEquipmentContext
                      hidden={step !== ResponseFormStep.EQUIPMENT}
                      name={ResponseFormStep.EQUIPMENT}
                      emergencyDetails={emergencyData?.details}
                      isDisabled={isDisabled}
                    />
                  </FormProvider>
                </Grid>
                {!isMobile && (
                  <Grid item lg={4}>
                    <Paper
                      className={classes.sidebar}
                      elevation={0}
                      variant="outlined"
                      sx={{ p: { lg: 2 } }}
                    >
                      <StackableSteps
                        sx={{ flex: '0 1 100%' }}
                        active={step}
                        steps={stepHistory}
                        onStep={onJumpTo}
                      />
                      {renderNextButton()}
                    </Paper>
                  </Grid>
                )}
              </Grid>
            </PaperLayout>
          </Box>
        </Fade>

        {isMobile && (
          <Box className={classes.footerView}>{renderNextButton()}</Box>
        )}

        <ConfirmationModal
          open={props.openConfirmation}
          title="Resource confirmation"
          onConfirm={onConfirm}
        >
          <React.Fragment>
            <Typo variant="body1" mb={1}>
              This response will be sent to the Fire Fighter Mobilization
              Coordinator.
            </Typo>
            <Typo variant="body2" mb={1}>
              You can edit it before it is confirmed.
            </Typo>
          </React.Fragment>
        </ConfirmationModal>
        <AlertMsgModal
          open={errorFormStatus}
          title="Response is not Complete."
          onPress={() => setErrorFormStatus(false)}
        >
          <React.Fragment>
            <Typo variant="body1" mb={1}>
              {formErrorMessage}
            </Typo>
          </React.Fragment>
        </AlertMsgModal>
      </Container>
    </MainLayout>
  );
};

export default FireChiefResponseView;
