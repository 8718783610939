import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { Divider, Grid, Box } from '@mui/material';
import CreatableSelect from 'react-select/creatable';
import { OnChangeValue } from 'react-select';
import { FormGroup, TextField, TitleText, Typo } from 'components/primitives';
import { Button } from 'components/primitives';
import useStyles from './FireStationInfoForm.styles';
import { FireStationInfoPublicProps } from './FireStationInfoForm.props';
import { uniq, map } from 'lodash';
import { Nullable } from 'common/utils/Nullable';
import { generalInfoForm } from 'helpers/forms/firestationForm';
import { ISelectProp } from 'interfaces/FireStations';

const FireStationInfoFormView: React.FC<FireStationInfoPublicProps> = ({
  form,
  onAccept,
  awaitSave,
  ...props
}: FireStationInfoPublicProps) => {
  const classes = useStyles();
  const [cityList, setCityList] = useState<ISelectProp[]>([]);
  const [formPayload, setFormPayload] = useState<Nullable<generalInfoForm>>({
    name: '',
    county: '',
    city: '',
    address1: '',
    address2: '',
  });
  const [disableCity, setDisableCity] = useState<boolean>(true);
  const [countyOption, setCountyOption] = useState<ISelectProp[]>([]);
  const { watch, control, trigger } = form || {};
  // const { errors } = form?.formState || {};
  // console.log('form errors', errors);

  useEffect(() => {
    const subscription =
      watch &&
      watch((value, { name }) => {
        // console.log(value);
        // console.log(name);
        if (value && value?.county?.length > 0 && name === 'county') {
          const countyStations =
            props.counties &&
            props.counties.reduce((arr: any, c: any) => {
              if (c.county === value.county) {
                return arr.concat(c.stations ? c.stations : []);
              }
              return arr;
            }, []);
          const cities =
            countyStations && uniq(map(countyStations, 'city')).sort();
          const cityOption = cities.map((c: string) => ({
            value: c,
            label: c,
          }));
          setCityList(cityOption);
          setDisableCity(false);
        }
        const payload = {
          name: value.name,
          county: value.county,
          city: value.city,
          address1: value.address1,
          address2: value.address2,
        };

        setFormPayload(payload);
      });
    return () => subscription?.unsubscribe();
  }, [watch]);

  useEffect(() => {
    const loadCountyOption = () => {
      const definedSelect =
        (props.counties &&
          props.counties.map((c: any) => ({
            value: c.county,
            label: c.county,
          }))) ||
        [];
      // console.log('new county select', definedSelect);
      setCountyOption(definedSelect);
    };
    loadCountyOption();
  }, []);

  const handleButtonAction = async (accept: boolean) => {
    const res =
      trigger && (await trigger(['name', 'county', 'city', 'address1']));

    if (accept) {
      if (res) {
        if (onAccept) onAccept(accept, formPayload);
      }
    } else {
      if (onAccept) onAccept(accept, null);
    }
  };

  const handleCountyChange =
    (onChange: any) => (newValue: OnChangeValue<ISelectProp, false>) => {
      onChange(newValue?.value);
    };

  const handleCityChange =
    (onChange: any) => (newValue: OnChangeValue<ISelectProp, false>) => {
      onChange(newValue?.value);
    };

  return (
    <React.Fragment>
      <Typo variant="body1" fontWeight={600}>
        General information
      </Typo>
      <Divider sx={{ mt: 1, mb: 2 }} />
      <Grid container spacing={2}>
        <Grid item xs={12} lg={12}>
          <Controller
            name={'name'}
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FormGroup label="Name" error={error}>
                <TextField value={value} onChange={onChange} />
              </FormGroup>
            )}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Controller
            name={'county'}
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange }, fieldState: { error } }) => (
              <FormGroup label="County" error={error}>
                <CreatableSelect
                  onChange={handleCountyChange(onChange)}
                  options={countyOption}
                />
              </FormGroup>
            )}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Controller
            name={'city'}
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange }, fieldState: { error } }) => (
              <FormGroup label="City" error={error}>
                <CreatableSelect
                  onChange={handleCityChange(onChange)}
                  options={cityList}
                  isDisabled={disableCity}
                />
              </FormGroup>
            )}
          />
        </Grid>
        <Grid item xs={12} lg={12}>
          <Controller
            name={'address1'}
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FormGroup label="Address - Line 1" error={error}>
                <TextField onChange={onChange} value={value} />
              </FormGroup>
            )}
          />
        </Grid>
        <Grid item xs={12} lg={12}>
          <Controller
            name="address2"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FormGroup label="Address - Line 2" error={error}>
                <TextField onChange={onChange} value={value} />
              </FormGroup>
            )}
          />
        </Grid>
      </Grid>
      <Box className={classes.footer}>
        <Button
          fullWidth
          alternate
          disabled={awaitSave}
          onClick={() => handleButtonAction(false)}
        >
          Cancel
        </Button>
        <Button
          fullWidth
          onClick={() => handleButtonAction(true)}
          progress={awaitSave}
        >
          Save
        </Button>
      </Box>
    </React.Fragment>
  );
};

export default FireStationInfoFormView;
