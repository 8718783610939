import { createStyles, makeStyles } from '@mui/styles';
import { lighten, Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.text.primary,
      fontWeight: 600,
    },
    toolbar: {
      display: 'flex',
      columnGap: theme.spacing(2),
      minHeight: 'unset',
      height: '100%',
    },
    createButton: {
      backgroundColor: theme.palette.common.white,
      borderWidth: 1.5,
      borderColor: lighten(theme.palette.primary.dark, 0.3),
      '&:hover': {
        backgroundColor: lighten(theme.palette.primary.main, 0.7),
      },
    },
    iconButton: {
      borderRadius: 8,
      minWidth: 'unset',
      padding: '0 8px',
    },
  })
);

export default useStyles;
