import { QueryState } from 'common/enums';
import { IEmergencyRequest, IEmergencyResponse } from 'common/interfaces';

export type DashboardState = {
  queryState: QueryState;
  emergencies: IEmergencyRequest[];
  responses: IEmergencyResponse[];
  page: number;
  pageLimit: number;
  pageTotal: number;
  initialPageTotal: number;
};

export const DashboardEmptyState: DashboardState = {
  queryState: QueryState.AWAIT,
  emergencies: [],
  responses: [],
  page: 0,
  pageLimit: 5,
  pageTotal: 0,
  initialPageTotal: 0,
};
