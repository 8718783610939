import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {},
    titleView: {
      '& .MuiTypography-h6': {
        flex: '0 1 auto',
      },
      '& .MuiTypography-h5': {
        flex: 1,
        color: theme.palette.grey[400],
        textAlign: 'right',
      },
      '& .MuiTypography-body1': {
        flex: '0 1 auto',
        marginLeft: theme.spacing(1),
        marginBottom: '3px',
        color: theme.palette.grey[700],
      },
    },
    formView: {
      maxHeight: '504px',
      [theme.breakpoints.down('md')]: {
        maxHeight: 'unset',
      },
    },
    stepBox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '32px',
      height: '32px',
      borderRadius: '5px',
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.common.white,
    },
    disableWrap: {
      padding: 0,
      backgroundColor: 'transparent',
    },
    sidebar: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      rowGap: theme.spacing(2),
      minHeight: '504px',
      height: '100%',
      border: 'solid 1px #EBEBEB',
      borderRadius: 8,
      backgroundColor: '#FAFAFA',
    },
    footerView: {
      position: 'fixed',
      display: 'flex',
      justifyContent: 'center',
      bottom: 0,
      padding: `0 ${theme.spacing(3)} ${theme.spacing(2)}`,
      width: '100%',
      '& button': {
        width: '240px',
        height: '52px',
      },
    },
  };
});

export default useStyles;
