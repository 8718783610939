import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { ResourceFormProps } from 'helpers/forms/resourceForm';
import {
  AcceptResourcesProps,
  AcceptResourcesPublicProps,
} from './AcceptResources.props';
import AcceptResourcesView from './AcceptResources.view';

const AcceptResourcesContainer: React.FC<AcceptResourcesPublicProps> = (
  publicProps: AcceptResourcesPublicProps
) => {
  const { emergencyResponse } = publicProps;
  const [tabIndex, setTabIndex] = useState<number>(0);
  const form = useForm(
    ResourceFormProps({
      formData: emergencyResponse?.details,
    })
  );

  const handleAccept = (accept: boolean, payload: any) => () => {
    const acceptPayload = {
      ...emergencyResponse,
      details: payload,
    };
    if (publicProps.onAccept) publicProps.onAccept(accept, acceptPayload);
  };

  const combinedProps: AcceptResourcesProps = {
    ...publicProps,
    form,
    tabIndex,
    handleAccept,
    handleTabChange: (e: React.SyntheticEvent, index: number) =>
      setTabIndex(index),
  };
  return <AcceptResourcesView {...combinedProps} />;
};

export default AcceptResourcesContainer;
