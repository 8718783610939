import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Box, { BoxProps } from '@mui/material/Box';
import { FormGroup, Typo } from 'components/primitives';
import useStyles from './EmergencySendRequest.styles';

const EmergencySendRequestView: React.FC<BoxProps> = (props: BoxProps) => {
  const classes = useStyles();
  const { control } = useFormContext();
  const emailTemplate =
    'Attention Chief and/or designee, Firefighter Mobilization is requesting the following to support a need identified by a local resource. asfdadsf';
  return (
    <Box {...props} className={classes.root}>
      <Controller
        name="emailBody"
        control={control}
        rules={{ required: true }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <FormGroup label="Email body" error={error}>
            <textarea
              className={classes.textarea}
              value={value}
              // defaultValue={emailTemplate}
              onChange={onChange}
              spellCheck="false"
              rows={15}
            />
          </FormGroup>
        )}
      />
      <Typo variant="body2" sx={{ mt: 1 }} color="common.black">
        This email will be sent to all the selected regions. Please verify it
        thoroughly.
      </Typo>
    </Box>
  );
};

export default EmergencySendRequestView;
