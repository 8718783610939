import { Dispatch } from 'redux';
import { AppState } from 'store/state/AppState';
import apiFetch, { getEmergencResponses } from 'services/apiFetch';
import { dashboardActions } from 'store/actions';
import { QueryState } from 'common/enums';

const getResponses = async (
  dispatch: Dispatch,
  getState: () => AppState
): Promise<any> => {
  const res = await apiFetch(getEmergencResponses()).then(
    (res) => res.data || null
  );
  const responses = res.dataset || [];
  dispatch(dashboardActions.update({ responses }));
};

export default getResponses;
