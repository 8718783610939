import { createTheme } from '@mui/material/styles';
import MuiAppBarStyles from './overrides/MuiAppBar';
import MuiButtonStyles from './overrides/MuiButton';
import MuiLinkStyles from './overrides/MuiLink';
import MuiOutlinedInputStyles from './overrides/MuiOutlinedInput';
import MuiPaperStyles from './overrides/MuiPaper';

import lightPalette from './palettes/lightPallete';
import typography from './typography';

const theme = createTheme({
  palette: lightPalette,
  components: {
    MuiAppBar: MuiAppBarStyles(lightPalette),
    MuiButton: MuiButtonStyles(lightPalette),
    MuiLink: MuiLinkStyles(lightPalette),
    MuiOutlinedInput: MuiOutlinedInputStyles(lightPalette),
    MuiPaper: MuiPaperStyles(lightPalette),
  },
  typography,
});

export default theme;
