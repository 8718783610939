import { Dispatch } from 'redux';
import { IEmergencyRequest } from 'interfaces/EmergencyRequest';
import { QueryState } from 'common/enums';
import { AppState } from 'store/state/AppState';
import { pageActions } from 'store/actions';
import { apiFetch2, updateEmergencyRequest } from 'services/apiFetch';
import { RequestForm } from 'helpers/forms/requestForm';
import { requestPayload } from './create';

const update =
  (formData: RequestForm) =>
  async (dispatch: Dispatch, getState: () => AppState): Promise<any> => {
    dispatch(pageActions.update({ state: QueryState.AWAIT }));

    const { token } = getState();
    const payload = requestPayload(token?.id as number, formData);

    await apiFetch2(updateEmergencyRequest(payload))
      .then(() => dispatch(pageActions.update({ state: QueryState.UPDATED })))
      .catch((error) => {
        console.error('thunk update error:', error);
        dispatch(pageActions.update({ state: QueryState.FAIL }));
      });
    // dispatch(pageActions.update({ state: QueryState.UPDATED }));
  };

export default update;
