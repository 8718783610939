import { QueryState } from 'common/enums';
import { Nullable } from 'common/utils/Nullable';

export type NotificationDataProps = {
  contactId: number;
  createDate: string;
  details: Nullable<string>;
  deleted: number;
  globalId: string;
  groupId: Nullable<string>;
  guestToken: string;
  notificationId: number;
  requestId: number;
  responseId: Nullable<number>;
  stationId: number;
  updateDate: string;
  userId: Nullable<string | number>;
  requestDetails: Nullable<any>;
  stationDetails: Nullable<any>;
  contactDetails: Nullable<any>;
};

export type NotificationStateType = {
  isLoading: QueryState;
  initialPageCount: number;
  notificationData: Nullable<NotificationDataProps[]>;
};

export const NotificationState: NotificationStateType = {
  isLoading: QueryState.READY,
  initialPageCount: 0,
  notificationData: null,
};

export interface AppNotification {
  notification: NotificationStateType;
}
