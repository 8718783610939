import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { ISize } from 'interfaces/Size';

const useStyles = makeStyles((theme: Theme) => {
  // const marginX = innerWidth - theme.breakpoints.values.lg + 48;
  return {
    root: {},
    responseList: {
      marginBottom: theme.spacing(1),
      // padding: `0 20px 0 ${marginX / 2}px`,
      display: 'flex',
      flexDirection: 'row',
      columnGap: '15px',
      width: '100%',
      height: 'auto',
      // [theme.breakpoints.down('md')]: {
      //   padding: `0 ${theme.spacing(2)}`,
      // },
    },
    responseContainer: {
      overflowY: 'visible',
      paddingLeft: (props: ISize) =>
        `${(props?.width - theme.breakpoints.values.lg + 48) / 2}px`,
      [theme.breakpoints.down('md')]: {
        padding: `0 ${theme.spacing(2)}`,
      },
    },
    responseItem: {
      minWidth: '266px',
    },
    responseBadge: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '32px',
      height: '32px',
      borderRadius: '5px',
      backgroundColor: theme.palette.grey[400],
      color: theme.palette.grey[50],
    },
    dismissOfferModal: {
      width: '550px',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    dismissEmailTextArea: {
      resize: 'none',
      padding: theme.spacing(3),
      width: '100%',
      lineHeight: theme.spacing(3),
      borderRadius: theme.spacing(1),
      borderColor: '#AEAEAE',
      fontFamily: 'inherit',
      color: theme.palette.text.secondary,
    },
    pagination: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: theme.spacing(2),
    },
  };
});

export default useStyles;
