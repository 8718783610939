import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => {
  return {
    modalContent: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: theme.spacing(1),
      padding: theme.spacing(3),
      minWidth: '320px',
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2),
        minWidth: '95%',
      },
    },
  };
});

const ModalStyles = (theme: Theme): any => ({
  root: {},
});

export { ModalStyles };
export default useStyles;
