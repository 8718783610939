import { RoomState } from 'common/enums';
import { IEmergencyRequest } from 'interfaces/EmergencyRequest';
import { IEmergencyResponse } from 'interfaces/EmergencyResponse';
import { Nullable } from 'common/utils/Nullable';

export type SocketStateType = {
  room: RoomState;
  data: Nullable<IEmergencyRequest | IEmergencyResponse>;
};

export interface ISocketState {
  roomRequest: Nullable<SocketStateType>;
  roomResponse: Nullable<SocketStateType>;
  roomNotification: Nullable<SocketStateType>;
  from: RoomState;
}

export const SocketState: ISocketState = {
  roomRequest: null,
  roomResponse: null,
  roomNotification: null,
  from: RoomState.FFMOB_EMPTY,
};

export interface SocketAppState {
  socket: ISocketState;
}
