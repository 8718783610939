import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      [theme.breakpoints.down('sm')]: {
        minWidth: '250px !important',
      },
    },
    actionRoot: {
      padding: '8px 8px 8px 16px',
      justifyContent: 'space-between',
    },
    icons: {
      marginLeft: 'auto',
    },
    toastContainer: {
      width: '100%',
    },
  };
});

export default useStyles;
