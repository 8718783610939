import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OnChangeValue } from 'react-select';
import useSelectorSafe from 'store/selectors/useSelectorSafe';
import { AppState } from 'store/state/AppState';
import { AppFireState } from 'store/state/FireStationsState';
import {
  FireStationsProps,
  FireStationsPublicProps,
} from './FireStations.props';
import {
  loadFireStations,
  loadCounties,
  loadCities,
  resetContactsField,
  resetFireStationState,
} from 'thunks/firestation/getFireStation';
import FireStationsView from './FireStations.view';
import _ from 'lodash';
import { QueryState } from 'common/enums';
import { ISelectProp } from 'interfaces/FireStations';

const FireStationsContainer: React.FC<FireStationsPublicProps> = (
  ownProps: FireStationsPublicProps
) => {
  const dispatch = useDispatch();
  // const dashboard = useSelectorSafe((state: AppState) => state.dashboard);
  const {
    isLoading,
    page,
    initialPageTotal,
    pageLimit,
    pageTotal,
    fireStations,
    counties,
    contacts,
    cities,
    errorMessage,
  } = useSelector((state: AppFireState) => state.firestation);
  const awaitRequest = isLoading === QueryState.AWAIT;
  const [selectCounty, setSelectCounty] = useState<string>('');
  const [selectCity, setSelectCity] = useState<string>('');
  const [cityArr, setCityList] = useState<ISelectProp[]>([]);
  const [modalStatus, setModalStatus] = useState<boolean>(false);
  const [keyword, setKeyword] = useState<string>('');
  const [countyOption, setCountyOption] = useState<ISelectProp[]>([]);

  useEffect(() => {
    if (selectCounty && !awaitRequest) {
      const cityList = _.uniq(_.map(fireStations, 'city')).sort();
      const cityOption = cityList.map((c: string) => ({
        value: c,
        label: c,
      }));
      setCityList(cityOption);
    }
  }, [fireStations]);

  useEffect(() => {
    const delayKeyPressFn = setTimeout(() => {
      if (
        (!awaitRequest && keyword === '') ||
        selectCounty === null ||
        selectCounty === ''
      ) {
        dispatch(
          loadFireStations({
            page: 1,
            county: selectCounty,
            city: selectCity,
            keyword: '',
          })
        );
      }

      if (!awaitRequest && keyword !== '') {
        dispatch(
          loadFireStations({
            page: 1,
            county: selectCounty || '',
            city: selectCity || '',
            keyword,
          })
        );
      }
    }, 1000);

    return () => clearTimeout(delayKeyPressFn);
  }, [keyword, selectCounty]);

  useEffect(() => {
    if (!awaitRequest && selectCity && selectCity.length > 0) {
      dispatch(
        loadFireStations({
          page: 1,
          county: selectCounty || '',
          city: selectCity,
          keyword,
        })
      );
    }
  }, [selectCity]);

  useEffect(() => {
    const countyList = JSON.parse(localStorage.getItem('countyList') || '');
    setCountyOption(countyList.counties);
    return () => {
      dispatch(resetFireStationState);
    };
  }, []);

  const handleChangeCounty = (e: string) => {
    setSelectCounty(e);
  };

  const handleChangeCity = (value: string) => {
    setSelectCity(value);
  };

  const handleModal = (confirm: boolean) => {
    setModalStatus(confirm);
  };

  const handlePageChange = (num: number) => {
    if (num !== page) {
      dispatch(
        loadFireStations({
          page: num,
          county: selectCounty || '',
          city: selectCity || '',
          keyword,
        })
      );
    }
  };

  const handleChangeKeyword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(e.target.value);
  };

  const handleRefreshList = () => {
    dispatch(loadFireStations({ page: 1 }));
  };

  const combinedProps: FireStationsProps = {
    ...ownProps,
    awaitRequest,
    fireStations,
    counties,
    cityArr,
    selectCounty: selectCounty || '',
    selectCity,
    modalStatus,
    page,
    pageTotal,
    pageLimit,
    keyword,
    countyOption,
    handleChangeKeyword,
    handlePageChange,
    handleModal,
    handleChangeCounty,
    handleChangeCity,
    handleRefreshList,
  };

  return <FireStationsView {...combinedProps} />;
};

export default FireStationsContainer;
