import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
    },
    requestDetailsWrapper: {
      flex: 1,
      width: '100%',
      overflowY: 'auto',
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(2),
        borderRadius: '8px',
        backgroundColor: theme.palette.common.white,
      },
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
  };
});

export default useStyles;
