import { Dispatch } from 'redux';
import { socketActions } from 'store/actions';

export const postWSRequest = (payload: any) => (dispatch: Dispatch) => {
  console.log('postWSRequest:', payload);
  dispatch(socketActions.requestReceived(payload));
};

export const postWSResponse = (payload: any) => (dispatch: Dispatch) => {
  console.log('postWSResponse:', payload);
  dispatch(socketActions.responseReceived(payload));
};
