import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      columnGap: '10px',
      height: '40px',
      '& hr': {
        flex: 1,
      },
    },
  };
});

export default useStyles;
