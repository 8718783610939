import { IEmergencyRequest, IEmergencyResponse } from 'common/interfaces';
import { Nullable } from 'common/utils/Nullable';

export enum EmergencyDetailsModal {
  UNDEFINED = 0,
  ARCHIVE = 1,
  REMIND = 2,
  ACCEPT_RESOURCES = 3,
  DISMISS_RESOURCES = 4,
  PROVIDED_RESOUCES = 5,
}

export interface EmergencyDetailsModalFocus {
  modal: EmergencyDetailsModal;
  payload: IEmergencyResponse;
}

export interface EmergencyDetailsPublicProps {
  /** public props */
}

export interface EmergencyDetailsCalcedProps {
  awaitArchive: boolean;
  emergencyData: Nullable<IEmergencyRequest>;
  emergencyResponses: IEmergencyResponse[];
  modalFocus: Partial<EmergencyDetailsModalFocus>;
  reqStationData: [];
  handleConfirmArchive: () => void;
  refreshEmergencyData: () => void;
  handleModalFocus: (modalFocus: Partial<EmergencyDetailsModalFocus>) => void;
  resetModalFocus: () => void;
}

export type EmergencyDetailsProps = EmergencyDetailsPublicProps &
  EmergencyDetailsCalcedProps;
