import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      padding: `0 ${theme.spacing(2)}`,
      width: '100%',
      height: '100%',
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(0),
      },
    },
    personnelWrapper: {
      flex: 1,
      overflowY: 'auto',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    personnelItem: {
      [theme.breakpoints.down('md')]: {
        padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
        borderRadius: theme.spacing(1),
        backgroundColor: theme.palette.grey[100],
      },
    },
    button: {
      marginTop: theme.spacing(1),
      flex: '0 1 auto',
    },
    titleBox: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: theme.spacing(1),
      padding: `${theme.spacing(1)}`,
      backgroundColor: theme.palette.grey[50],
    },
    titleHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: `${theme.spacing(1)}`,
      backgroundColor: theme.palette.grey[50],
      '& span': {
        fontWeight: 600,
        fontSize: '12px',
        color: theme.palette.grey[700],
      },
    },
    titleHeaderInfo: {
      display: 'flex',
      flexDirection: 'column',
      padding: `${theme.spacing(1)}`,
      color: theme.palette.grey[700],
      '& span': {
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '18px',
        paddingTop: `${theme.spacing(1)}`,
      },
    },
    titleColumn: {
      fontSize: '12px',
      fontWeight: 600,
      color: theme.palette.grey[700],
    },
    nameColumn: {
      display: 'flex',
      alignItems: 'center',
      '& span': {
        flex: '0 1 24px',
        fontSize: '14px',
        fontWeight: 600,
        color: theme.palette.grey[400],
      },
      '& MuiInputBase-input': {
        flex: 1,
      },
    },
    entryButton: {
      minHeight: 'unset',
      padding: '0 25px 0 7px',
      height: '24px',
      backgroundColor: '#D7E2FF',
      fontSize: '11px',
      color: '#2961FD',
      '&:hover': {
        backgroundColor: '#D7E2FF',
      },
      '& .MuiSvgIcon-root': {
        fontSize: '16px',
        color: '#2961FD',
      },
    },
  };
});

export default useStyles;
