import { Dispatch } from 'redux';
import actions from 'store/actions/firestations';
import apiFetch, {
  getFireStationList,
  getFireStationCounty,
  FireStationParams,
  getFireStationCity,
  gerFireStationById,
  gerFireStationById2,
} from 'services/apiFetch';
import { AppFireState } from 'store/state/FireStationsState';
import { map, uniq } from 'lodash';
import { QueryState } from 'common/enums';

const getPageNumber = (count: number, limit: number): number => {
  return Math.ceil(count / limit);
};

export const loadFireStations =
  (params: FireStationParams) =>
  async (dispatch: Dispatch, getState: () => AppFireState): Promise<any> => {
    const currentPage = params.page || 1;
    const { pageLimit, pageTotal, initialPageTotal } = getState().firestation;

    // Empty firestations and show await
    dispatch(actions.fetchFireStationPage(params.page));

    if (params.page === 1) {
      const endpoint = getFireStationList({
        limit: pageLimit,
        offset: 0,
        county: params?.county,
        city: params?.city,
        keyword: params?.keyword,
      });

      const res = await apiFetch(endpoint).then((res) => res.data || null);
      if (res?.count > 0) {
        const pageCount =
          res?.count > pageLimit ? getPageNumber(res?.count, pageLimit) : 1; //res?.count;

        const payload = {
          isLoading: QueryState.READY,
          pageTotal: pageCount,
          initialPageTotal: res?.count,
          stations: res?.dataset || [],
        };
        dispatch(actions.responseStation(payload));
      } else {
        const newPageTotal = res?.dataset.length === 0 ? 0 : pageTotal;
        const payload = {
          isLoading: QueryState.READY,
          stations: res?.dataset || [],
          pageTotal: newPageTotal,
        };
        dispatch(actions.responseStation(payload));
      }
    } else {
      const offsetCount = (currentPage - 1) * pageLimit;
      const endpoint = getFireStationList({
        limit: pageLimit,
        offset: offsetCount < initialPageTotal ? offsetCount : currentPage - 1,
        county: params?.county,
        city: params?.city,
        keyword: params?.keyword,
      });
      const res = await apiFetch(endpoint).then((res) => res.data || null);
      if (res?.count > 0) {
        const pageCount =
          res?.count > pageLimit ? getPageNumber(res?.count, pageLimit) : 1;
        const payload = {
          isLoading: QueryState.READY,
          pageTotal: pageCount,
          stations: res?.dataset || [],
        };
        dispatch(actions.responseStation(payload));
      } else {
        const newPageTotal = res?.dataset.length === 0 ? 0 : pageTotal;
        const payload = {
          isLoading: QueryState.READY,
          stations: res?.dataset || [],
          pageTotal: newPageTotal,
        };
        dispatch(actions.responseStation(payload));
      }
    }
  };

export const loadCounties = (dispatch: Dispatch) => {
  dispatch(actions.fetchData());
  apiFetch(getFireStationCounty())
    .then(({ data, status }) => {
      if (status === 200) {
        dispatch(actions.responseCounties(data));
      }
    })
    .catch((error) => dispatch(actions.responseError(error?.message)));
};

export const loadCities = async (dispatch: Dispatch) => {
  const res = await apiFetch(getFireStationCity()).then(
    (res) => res.data || null
  );
  if (res) {
    const cityList = uniq(map(res, 'city')).sort();
    dispatch(actions.responseCities(cityList));
  }
};

export const loadFireStationsCounty =
  (params: FireStationParams) => (dispatch: Dispatch) => {
    dispatch(actions.fetchData());
    const endpoint = getFireStationCounty({
      county: params.county,
    });
    apiFetch(endpoint)
      .then(({ data, status }) => {
        if (status === 200) {
          const payload = {
            isLoading: QueryState.READY,
            stations: data[0]?.stations || [],
          };
          dispatch(actions.responseStation(payload));
        }
      })
      .catch((error) => dispatch(actions.responseError(error?.message)));
  };

export const loadFireStationsCity =
  (params: FireStationParams) => (dispatch: Dispatch) => {
    const endpoint = getFireStationCity({
      city: params.city,
    });
    apiFetch(endpoint)
      .then(({ data, status }) => {
        if (status === 200) {
          const payload = {
            isLoading: QueryState.READY,
            stations: data[0]?.stations || [],
          };
          dispatch(actions.responseStation(payload));
        }
      })
      .catch((error) => dispatch(actions.responseError(error?.message)));
  };

export const loadFireStationById =
  (params: FireStationParams) => async (dispatch: Dispatch) => {
    dispatch(actions.updateQueryStatus({ queryStatus: QueryState.AWAIT }));

    const endpoint2 = gerFireStationById2({
      stationId: params.stationId,
    });
    const res2 = await apiFetch(endpoint2).then(
      (res) => res.data?.dataset || []
    );
    if (res2 && Array.isArray(res2) && res2.length) {
      const payloadInfo = res2[0].stationDetails || {};
      dispatch(actions.responseFireStationById(payloadInfo));
      const payloadContact = res2.map((value: any) => ({
        ...value.details,
        contactId: value.contactId,
      }));
      dispatch(actions.responseContacts(payloadContact));
    } else {
      const endpoint = gerFireStationById({
        stationId: params.stationId,
      });

      const res = await apiFetch(endpoint)
        .then((res) => res.data || null)
        .catch((e) => console.log('catch loadFireStationById:', e));
      if (res?.details) {
        const payload = res.details;
        dispatch(actions.responseFireStationById(payload));
      }
    }
  };

export const resetContactsField = (dispatch: Dispatch) => {
  dispatch(actions.responseContacts([])); //empty contacts
};

export const resetFireStationState = (dispatch: Dispatch) => {
  console.log('execute dispatch resetFireStationState');
  dispatch(actions.resetFireStationState());
};
