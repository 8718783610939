import React, { useRef, useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { socketThunk } from 'thunks';
import { Toast } from 'components/primitives';
import storeconfig from 'store';
import { RoomState } from 'common/enums';

declare const window: any;

const WebSocketContainer: React.FC = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { store } = storeconfig;
  const { token } = store.getState();
  const clientRef = useRef<any>(null);
  const [waitingToReconnect, setWaitingToReconnect] = useState<any>(null);
  const [isOpen, setIsOpen] = useState(false);
  const UId = token.id;
  const authorization = token.authorization;
  const socketURL: string = process.env.REACT_APP_WEBSOCKET_URL || '';
  const url = socketURL + authorization + '&groupid=49&userid=' + UId;
  // const socketResponse = new WebSocket(url);
  // const socketRequest = new WebSocket(url);

  const toastMsg = (message: string) => {
    enqueueSnackbar(message, {
      anchorOrigin: { vertical: 'top', horizontal: 'right' },
      content: (key, message) => <Toast id={key} message={message || ''} />,
    });
  };

  const rooms = {
    action: 'join',
    data: {
      room: ['ffmob_response', 'ffmob_request'],
    },
  };
  // const msgResponse = {
  //   action: 'join',
  //   data: {
  //     room: ['ffmob_response'],
  //   },
  // };
  // const msgRequest = {
  //   action: 'join',
  //   data: {
  //     room: ['ffmob_request'],
  //   },
  // };

  // const msgNotification = {
  //   action: 'join',
  //   data: {
  //     room: ['ffmob_notification'],
  //   },
  // };

  useEffect(() => {
    if (waitingToReconnect) {
      return;
    }
    // Only set up the websocket once
    if (!clientRef.current) {
      const client = new WebSocket(url);
      clientRef.current = client;
      window.client = client;

      client.onerror = (e) => console.error(e);

      client.onopen = () => {
        setIsOpen(true);
        console.log('ws opened');
        client.send(JSON.stringify(rooms));
      };

      client.onclose = () => {
        if (clientRef.current) {
          // Connection failed
          console.log('ws closed by server');
        } else {
          // Cleanup initiated from app side, can return here, to not attempt a reconnect
          console.log('ws closed by app component unmount');
          return;
        }

        if (waitingToReconnect) {
          return;
        }

        // Parse event code and log
        setIsOpen(false);
        console.log('ws closed');

        // Setting this will trigger a re-run of the effect,
        // cleaning up the current websocket, but not setting
        // up a new one right away
        setWaitingToReconnect(true);

        // This will trigger another re-run, and because it is false,
        // the socket will be set up again
        setTimeout(() => setWaitingToReconnect(null), 3000);
      };

      client.onmessage = (event: any) => {
        console.log('received message');
        if (event.data) {
          // console.log('onmessage data:', JSON.parse(event.data));
          const resData = JSON.parse(event.data);
          const hasDetails = resData.data.details !== undefined;

          if (resData.room === RoomState.FFMOB_REQUEST) {
            if (resData.data.archived === 1) {
              const payload = {
                room: resData.room,
                data: {
                  ...resData?.data,
                },
              };
              return dispatch(socketThunk.postWSRequest(payload));
            }

            if (resData.data.requestId && resData.data.requestId > 0) {
              const payload = {
                room: resData.room,
                data: {
                  ...resData.data,
                },
              };
              dispatch(socketThunk.postWSRequest(payload));
              return toastMsg(
                `New Request ${payload?.data?.requestId || ''} created`
              );
            } else {
              if (hasDetails) {
                const detailsParse = JSON.parse(resData?.data?.details);
                const payload = {
                  room: resData.room,
                  data: {
                    ...resData.data,
                    details: detailsParse,
                  },
                };
                // console.log('Parse details', payload);
                dispatch(socketThunk.postWSRequest(payload));
                return toastMsg('A request has been updated');
              }
            }
          }

          if (resData.room === RoomState.FFMOB_RESPONSE) {
            if (resData.data.responseId && resData.data.responseId > 0) {
              const payload = {
                room: resData.room,
                data: {
                  ...resData.data,
                },
              };
              dispatch(socketThunk.postWSResponse(payload));
              return toastMsg(
                `New Response ${payload.data.responseId || ''} received.`
              );
            } else {
              if (hasDetails) {
                const detailsParse = JSON.parse(resData?.data?.details);
                const payload = {
                  room: resData.room,
                  data: {
                    ...resData.data,
                    details: detailsParse,
                  },
                };
                dispatch(socketThunk.postWSResponse(payload));
                return toastMsg('A Response is updated.');
              }
            }
          }
        }
      };

      return () => {
        console.log('Cleanup');
        // Dereference, so it will set up next time
        clientRef.current = null;
        client.close();
      };
    }
  }, [waitingToReconnect]);

  return null;
};

export default WebSocketContainer;
