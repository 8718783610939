import { NotificationActionTypes as actionTypes } from '../types/Notification.actionTypes';
import { NotificationState as initialStates } from '../state/NotificationState';
import { QueryState } from 'common/enums';

const NotificationReducer = (state = initialStates, action: any) => {
  switch (action.type) {
    case actionTypes.NOTIFICATION_QUERYSTATUS:
      return {
        ...state,
        isLoading: action.payload.queryStatus,
      };
    case actionTypes.NOTIFICATION_FETCH:
      return {
        ...state,
        isLoading: QueryState.AWAIT,
        notificationData: null,
      };
    case actionTypes.NOTIFICATION_RESPONSE:
      return {
        ...state,
        isLoading: QueryState.READY,
        notificationData: action.payload.data,
        initialPageCount: action.payload.initialPageCount,
      };
    default:
      return state;
  }
};

export default NotificationReducer;
