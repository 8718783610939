import React from 'react';
import { useFormContext } from 'react-hook-form';
import Box from '@mui/material/Box';
import RequestPersonnelChildView from './RequestPersonnelChild.view';
import useStyles from './RequestPersonnel.styles';

const RequestPersonnelContext = ({
  name,
  emergencyDetails,
  isDisabled,
  ...props
}: any) => {
  const classes = useStyles();
  const { getValues } = useFormContext();
  const value = getValues();

  return (
    <Box {...props} height="100%">
      <Box className={classes.root}>
        <Box className={classes.personnelWrapper}>
          {value.personnel?.map((field: any, index: number) => {
            return (
              <React.Fragment key={index}>
                <RequestPersonnelChildView
                  rootName={`personnel.${index}.items`}
                  field={field}
                  isDisabled={isDisabled}
                />
              </React.Fragment>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default RequestPersonnelContext;
