import { SocketActionTypes as actionTypes } from '../types/Socket.actionTypes';
import { SocketState as initialStates } from '../state/SocketState';
import { RoomState } from 'common/enums';

const SocketReducer = (state = initialStates, action: any) => {
  switch (action.type) {
    case actionTypes.SOCKET_REQUEST:
      return {
        ...state,
        roomRequest: action.payload,
        from: RoomState.FFMOB_REQUEST,
      };
    case actionTypes.SOCKET_RESPONSE:
      return {
        ...state,
        roomResponse: action.payload,
        from: RoomState.FFMOB_RESPONSE,
      };
    case actionTypes.SOCKET_NOTIFICATION:
      return {
        ...state,
        roomNotification: action.payload,
        from: RoomState.FFMOB_NOTIFICATION,
      };
    default:
      return state;
  }
};

export default SocketReducer;
