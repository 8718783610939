import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { tokenActions } from 'store/actions';
import TokenState from 'store/state/TokenState';
import { cognitoLogin } from 'thunks/cognito/cognitoThunks';
import { Nullable } from 'common/utils/Nullable';
import { UserLogin, LoginProps, LoginPublicProps } from './Login.props';
import LoginView from './Login.view';
import { UserStatus } from 'common/interfaces/userportal.interface';

const LoginErrMessage = {
  MISSING: 'Please enter the username and password',
  INVALID: 'You have entered an invalid username and/or password',
  SERVER: 'Sorry, something went wrong there. Please try again.',
};

const LoginContainer: React.FC<LoginPublicProps> = (
  ownProps: LoginPublicProps
) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [errMessage, setErrMessage] = useState<Nullable<string>>(null);
  const [login, setLogin] = useState<UserLogin>({
    username: null,
    password: null,
  });
  const authenticateCognito = async (username: string, password: string) => {
    setInProgress(true);
    const loginData = await cognitoLogin({ username, password }).catch(
      (err) => {
        console.log('🚀cognitoLogin err: ', err);
        setInProgress(false);
        setErrMessage(LoginErrMessage.SERVER);
      }
    );
    if (loginData) {
      setInProgress(false);
      const userInfo = loginData?.accountInfo;
      if (userInfo?.status === UserStatus.PENDING) {
        return setErrMessage(
          'Your account is pending approval. Please contact your administrator.'
        );
      }
      if (userInfo?.status === UserStatus.NOT_APPROVED) {
        return setErrMessage(
          'Your account is not approved. Please contact your administrator.'
        );
      }
      if (userInfo?.status === UserStatus.SUSPENDED) {
        return setErrMessage(
          'Your account is suspended. Please contact your administrator.'
        );
      }
      if (userInfo?.status === UserStatus.APPROVED) {
        const token: TokenState = {
          authorization: userInfo.accessToken || '',
          id: userInfo.id || 0,
          expiresIn: loginData.cognitoUser?.payload?.exp || 0,
          cognitoUser: JSON.stringify(loginData.cognitoUser),
        };

        dispatch(tokenActions.authorize(token));
        navigate('/dashboard');
      }
    }
  };

  const handleChange = (field: keyof UserLogin, value: string) => {
    setLogin({ ...login, [field]: value });
    setErrMessage(null);
  };

  const handleLogin = async () => {
    const { username, password } = login;

    if (username && password) {
      await authenticateCognito(username, password);
    } else {
      setErrMessage(LoginErrMessage.MISSING);
    }
  };

  const combinedProps: LoginProps = {
    ...ownProps,
    inProgress,
    errMessage,
    login,
    handleChange,
    handleLogin,
  };
  return <LoginView {...combinedProps} />;
};

export default LoginContainer;
