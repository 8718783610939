import React from 'react';
import { Box, FormHelperText } from '@mui/material';
import Typo from '../Typo';
import { FormGroupProps } from './FormGroup.props';

const FormGroup: React.FC<FormGroupProps> = ({
  label,
  color = 'textSecondary',
  error,
  fontWeight = 600,
  // TODO: Override theme typography sizes
  variant = 'body2',
  ...props
}: FormGroupProps) => {
  return (
    <Box {...props}>
      <Typo mb="5px" color={color} variant={variant} fontWeight={fontWeight}>
        {label}
      </Typo>
      {props.children}
      <FormHelperText error={!!error} sx={{ mt: 0, fontSize: '11px' }}>
        {error?.message}
      </FormHelperText>
    </Box>
  );
};

export default FormGroup;
