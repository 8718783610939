import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { isUndefined, findIndex, isEmpty } from 'lodash';
import apiFetch, {
  createEmergencyResponse,
  updateEmergencyResponse,
  updateEmergencyRequest,
} from 'services/apiFetch';
import { IEmergencyResponse } from 'common/interfaces';
import {
  ResponseFormProps,
  ResponseFormStep,
} from 'helpers/forms/responseForm';
import {
  FireChiefResponseProps,
  FireChiefResponsePublicProps,
} from './FireChiefResponse.props';
import FireChiefResponseView from './FireChiefResponse.view';
import { StackableStep } from 'components/fragments/StackableSteps';
import { useSelector, useDispatch } from 'react-redux';
import {
  loadResponseAsync,
  loadEmergencyRequestAsync,
  loadFireStationData,
} from 'thunks/response/getResponse';

const ResponseFormSteps: StackableStep[] = [
  {
    active: true,
    complete: false,
    step: 1,
    name: ResponseFormStep.PERSONNEL,
    title: 'Add personnel',
  },
  {
    active: false,
    complete: false,
    step: 2,
    name: ResponseFormStep.EQUIPMENT,
    title: 'Add equipment',
  },
];

const FireChiefResponseContainer: React.FC<FireChiefResponsePublicProps> = (
  ownProps: FireChiefResponsePublicProps
) => {
  const dispatch = useDispatch();
  const {
    isLoading,
    initialRequest,
    errorMessage,
    emergencyResponse,
    fireStationData,
    emergencyRequest,
  } = useSelector((state: any) => state.response);
  const params = useParams();
  const emergencyData: IEmergencyResponse = emergencyResponse;
  const totalRegions: number =
    emergencyRequest?.details?.regions.flatMap((i: any) => i.counties).length ||
    0;

  const [awaitState, setAwaitState] = useState<boolean>(true);
  const [awaitSave, setAwaitSave] = useState<boolean>(false);
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
  const [step, setStep] = useState<ResponseFormStep>(
    ResponseFormStep.PERSONNEL
  );
  const [stepHistory, setStepHistory] = useState<StackableStep[]>(
    ResponseFormSteps || []
  );
  const [alertError, setAlertError] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [responseSuccess, setResponseSuccess] = useState<boolean>(false);
  const [updateSuccess, setUpdateSuccess] = useState<boolean>(false);
  const [tokenExpired, setTokenExpired] = useState<boolean>(false);
  const form = useForm(
    ResponseFormProps({
      formData: undefined,
      step,
    })
  );

  const handleChangeStep = (next: ResponseFormStep) => {
    const nextIndex = findIndex(stepHistory, { name: next });
    const prevIndex = findIndex(stepHistory, { name: step });
    const prevStep = stepHistory[prevIndex];
    const steps = [...stepHistory];

    if (nextIndex > prevIndex && !prevStep.complete) {
      steps[prevIndex] = { ...prevStep, complete: true };
    }
    steps[nextIndex] = { ...steps[nextIndex], active: true };
    setStepHistory(steps);
    setStep(next);
  };

  const handleCreateResponse = async (payload: any, reqPayload: any) => {
    await apiFetch(
      createEmergencyResponse(payload, {
        access_token: params.requestId,
        authorize: false,
      })
    )
      .then(({ data, status }) => {
        console.log('CreateResponse Status:', status);
        // console.log('return data from createEmergencyResponse:', data);
        // Update Emergency Request
        apiFetch(updateEmergencyRequest(reqPayload));
        // TODO: do something here after create happen
        setResponseSuccess(true);
      })
      .catch((e) => {
        setAlertMessage(e.message);
        setAlertError(true);
      })
      .finally(() => {
        setAwaitSave(false);
        setStep(ResponseFormStep.PERSONNEL);
        form.reset();
      });
    setAwaitSave(false);
    setStep(ResponseFormStep.PERSONNEL);
  };

  const handleUpdateResponse = async (payload: any, reqPayload: any) => {
    await apiFetch(
      updateEmergencyResponse(payload, {
        access_token: params.requestId,
        authorize: false,
      })
    )
      .then(({ data, status }) => {
        console.log('UpdateResponse Status:', status);
        // Update Emergency Request
        apiFetch(updateEmergencyRequest(reqPayload));
        // TODO: do something here after create happen
        setUpdateSuccess(true);
      })
      .finally(() => {
        setAwaitSave(false);
        setStep(ResponseFormStep.PERSONNEL);
        form.reset();
      });
    setAwaitSave(false);
    setStep(ResponseFormStep.PERSONNEL);
  };

  const handleConfirmation = (confirm: boolean) => {
    if (confirm && emergencyData) {
      const { equipment, personnel } = form.getValues();
      const variableDetails = emergencyData.details;
      const requestDetails = emergencyRequest?.details;
      // Filter items with entry only
      const filteredEquipmentItem = equipment.map((e: any) =>
        e.items.reduce((arr: any, item: any) => {
          if (item.code && item.description) {
            return arr.concat({ ...item, accepted: false });
          }
          return arr;
        }, [])
      );
      const filteredPersonnelItem = personnel.map((p: any) =>
        p.items.reduce((arr: any, item: any) => {
          if (item.firstName && item.lastName && item.idNumber) {
            return arr.concat({ ...item, accepted: false });
          }
          return arr;
        }, [])
      );

      // Generate Request Payload
      const requestPayload = {
        ...emergencyRequest,
        details: {
          ...requestDetails,
          status: emergencyData.details.status,
          fireStationInfo: fireStationData,
          equipment: variableDetails.equipment.map((e, i: number) => ({
            code: e.code,
            number: e.number,
            description: e.description,
            totalEquipment: variableDetails.equipment.length,
            totalAcceptedEquipment: 0, // IS THIS BY DEFINED OR ALWAYS 0 ???
            items: filteredEquipmentItem[i],
          })),
          personnel: variableDetails.personnel.map((p, i: number) => ({
            notes: p.notes,
            number: p.number,
            credentialsNeeded: p.credentialsNeeded,
            totalPersonnel: variableDetails.personnel.length,
            items: filteredPersonnelItem[i],
          })),
        },
      };
      const stationID: number = fireStationData?.stationId || 0;
      // Generate Response Payload
      const payload: IEmergencyResponse = {
        deleted: 0,
        responseId: emergencyData.responseId,
        requestId: emergencyData.requestId,
        createDate: emergencyData.createDate,
        updateDate: emergencyData.updateDate,
        groupId: emergencyData.groupId,
        globalId: emergencyData.globalId,
        userId: emergencyData.userId,
        archived: emergencyData.archived,
        stationId: stationID,
        details: {
          createdAt: emergencyData.createDate,
          updatedAt: emergencyData.updateDate,
          fireStationInfo: fireStationData,
          status: emergencyData.details.status, // 0 default (when it's new), 1 if is accepted, 2 if is rejected
          equipment: variableDetails.equipment.map((e, i: number) => ({
            code: e.code,
            number: e.number,
            description: e.description,
            totalEquipment: variableDetails.equipment.length,
            totalAcceptedEquipment: 0, // IS THIS BY DEFINED OR ALWAYS 0 ???
            items: filteredEquipmentItem[i],
          })),
          personnel: variableDetails.personnel.map((p, i: number) => ({
            notes: p.notes,
            number: p.number,
            credentialsNeeded: p.credentialsNeeded,
            totalPersonnel: variableDetails.personnel.length,
            items: filteredPersonnelItem[i],
          })),
        },
      };
      // console.log('payload confirm:', payload);
      // console.log('requestPayload confirm:', requestPayload);
      setAwaitSave(true);
      if (emergencyData.responseId > 0) {
        // Perform updated response
        handleUpdateResponse(payload, requestPayload);
      }
      if (emergencyData.responseId === 0) {
        // Perform create new response
        handleCreateResponse(payload, requestPayload);
      }
    }
    setOpenConfirmation(false);
  };
  const handleSubmit = () => {
    setOpenConfirmation(true);
  };

  useEffect(() => {
    // Todo: Verify if there is a response and find the response with equal stationId
    if (!isLoading && !isEmpty(fireStationData)) {
      dispatch(loadResponseAsync({ access_token: params.requestId }));
    }
  }, [fireStationData]);

  useEffect(() => {
    if (!isLoading && !isUndefined(errorMessage) && errorMessage?.length > 0) {
      if (errorMessage === 'Expired Token') return setTokenExpired(true);
      setAlertMessage(errorMessage);
      setAlertError(true);
    }
  }, [errorMessage, isLoading]);

  useEffect(() => {
    if (emergencyData) {
      const { equipment, personnel } = emergencyData.details;
      const formData = {
        personnel: personnel.map((p) => ({
          name: p.credentialsNeeded,
          notes: p.notes,
          items: p.items,
        })),
        equipment: equipment.map((e) => ({
          name: e.description,
          items: e.items,
        })),
      };
      // Display request form details
      form.reset(formData);
    }
  }, [emergencyData]);

  useEffect(() => {
    if (!isLoading && initialRequest) {
      dispatch(
        loadEmergencyRequestAsync({
          access_token: params.requestId,
          initialRequest,
        })
      );
    }
    // dispatch(loadFireStationData({ access_token: params.requestId }));
  }, [initialRequest]);

  useEffect(() => {
    setAwaitSave(isLoading);
    setAwaitState(isLoading);
  }, [isLoading]);

  useEffect(() => {
    //? Fetch response information
    if (params.requestId) {
      // Todo: Identify the station id responding
      dispatch(loadFireStationData({ access_token: params.requestId }));
    }
  }, []);

  const combinedProps: FireChiefResponseProps = {
    ...ownProps,
    awaitSave,
    awaitState,
    emergencyData,
    totalRegions,
    form,
    openConfirmation,
    step,
    stepHistory,
    alertError,
    alertMessage,
    responseSuccess,
    updateSuccess,
    tokenExpired,
    handleChangeStep,
    handleConfirmation,
    handleSubmit,
    hideAlertError: () => setAlertError(false),
  };

  return <FireChiefResponseView {...combinedProps} />;
};

export default FireChiefResponseContainer;
