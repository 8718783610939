import { QueryState } from 'common/enums';
import {
  IFireStation,
  IFireStationContacts,
} from 'common/interfaces/FireStations';
import { Nullable } from 'common/utils/Nullable';
import { generalInfoForm } from 'helpers/forms/firestationForm';

export type FireStationStateType = {
  isLoading: QueryState;
  errorMessage: Nullable<string>;
  page: number;
  pageLimit: number;
  initialPageTotal: number;
  pageTotal: number;
  fireStations: IFireStation[];
  counties: string[];
  cities: string[];
  contacts: IFireStationContacts[];
  singleFireStation: Nullable<generalInfoForm | IFireStation>;
};

export const FireStateState: FireStationStateType = {
  isLoading: QueryState.READY,
  errorMessage: null,
  page: 0,
  pageLimit: 10,
  initialPageTotal: 0,
  pageTotal: 0,
  fireStations: [],
  counties: [],
  cities: [],
  contacts: [],
  singleFireStation: null,
};

export interface AppFireState {
  firestation: FireStationStateType;
}
