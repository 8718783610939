import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {},
    content: {
      width: '600px',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    tabContent: {
      minHeight: '400px',
      [theme.breakpoints.down('sm')]: {
        minHeight: 0,
      },
    },
    footer: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: theme.spacing(3),
      columnGap: '10px',
    },
    titleView: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '& p:last-child': {
        color: theme.palette.grey[800],
      },
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'start',
      },
    },
    grayBox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: theme.spacing(1),
      backgroundColor: theme.palette.grey[50],
      '& .MuiBox-root': {
        display: 'flex',
        justifyContent: 'flex-end',
        color: theme.palette.grey[800],
      },
    },
    stackView: {
      backgroundColor: theme.palette.grey[50],
    },
    sectionItem: {
      '& .MuiBox-root': {
        backgroundColor: theme.palette.grey[100],
        fontSize: '11px',
        fontWeight: 600,
        color: theme.palette.grey[700],
      },
    },
    personnelItem: {
      fontSize: '13px',
      color: theme.palette.grey[900],
      '& .MuiCheckbox-root': {
        marginRight: theme.spacing(1),
        padding: 0,
        paddingLeft: theme.spacing(1),
      },
      '& .MuiBox-root': {
        display: 'flex',
        alignItems: 'center',
        height: '28px',
      },
      '& .MuiBox-root:not(:first-child)': {
        flex: '0 1 20%',
        justifyContent: 'center',
        fontWeight: 600,
      },
      '& .MuiBox-root:first-child': {
        flex: '0 1 100%',
      },
    },
    dropDownContainer: {
      display: 'flex',
      height: '0px',
      overflow: 'hidden',
      // transition: 'all ease 0.3s',
    },
  };
});

const TabsStyles = (theme: Theme): any => ({
  root: {},
  indicator: {
    backgroundColor: 'transparent',
  },
});

const TabItemStyles = (theme: Theme): any => ({
  root: {
    opacity: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 0,
    paddingRight: theme.spacing(2),
    backgroundColor: 'transparent',
    textTransform: 'none',
  },
  selected: {
    backgroundColor: theme.palette.grey[100],
  },
});

export { TabsStyles, TabItemStyles };

export default useStyles;
