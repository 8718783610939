import { handleActions } from 'redux-actions';
import { presetReducers } from 'store/utils/Redux';
import { dashboardActions } from '../actions';
import {
  DashboardState,
  DashboardEmptyState,
} from 'store/state/DashboardState';

const reducer = handleActions<DashboardState, any>(
  {
    [String(dashboardActions.update)]: presetReducers.makeMerge(),
    [String(dashboardActions.clear)]:
      presetReducers.makeReset(DashboardEmptyState),
  },
  DashboardEmptyState
);

export default reducer;
