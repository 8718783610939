import React, { MouseEvent } from 'react';
import clsx from 'clsx';
import { Skeleton, Stack } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';
import { PaperProps } from '@mui/material/Paper';
import { Typo } from 'components/primitives';

import useStyles from './RequestItemBox.styles';
import { PaperLayout } from 'components/layouts';

const RequestItemBoxView = ({
  emergencyData,
  paperProps,
  meta = [],
  name,
  onSelect,
  ...props
}: {
  emergencyData: any;
  elevation?: number;
  meta?: { label: string | number; value: string | number }[];
  name: string;
  paperProps?: PaperProps;
  onSelect?: (e: MouseEvent) => void;
} & BoxProps) => {
  const classes = useStyles();
  return (
    <a aria-hidden="true" onClick={onSelect}>
      <Box {...props}>
        <PaperLayout
          sx={{ px: 2, py: 1, minHeight: '50px' }}
          {...paperProps}
          className={clsx(classes.root)}
        >
          <Box className={classes.leftColumn}>
            <Typo variant="h5">
              <strong>{name}</strong>
            </Typo>
            {meta?.length > 0 && (
              <Stack direction="row" columnGap={1} className={classes.meta}>
                {meta.map((m) => (
                  <Typo key={m.label} variant="body2" color="textSecondary">
                    {m.label}
                    <strong>{m.value || ''}</strong>
                  </Typo>
                ))}
              </Stack>
            )}
          </Box>
          <Box className={classes.rightColumn}>{props.children}</Box>
        </PaperLayout>
      </Box>
    </a>
  );
};

export default RequestItemBoxView;
