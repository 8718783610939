import React from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
  FireStationInfoFormProps,
  FireStationInfoPublicProps,
} from './FireStationInfoForm.props';
import FireStationInfoFormView from './FireStationInfoForm.view';
import { FireStationModalFormProps } from 'helpers/forms/firestationForm';
import { AppFireState } from 'store/state/FireStationsState';

const FireStationInfoFormContainer: React.FC<FireStationInfoFormProps> = ({
  onAccept,
  awaitSave,
  ...props
}: FireStationInfoFormProps) => {
  const { counties } = useSelector((state: AppFireState) => state.firestation);

  const form = useForm(FireStationModalFormProps({ formData: undefined }));

  const combineProps: FireStationInfoPublicProps = {
    form,
    counties,
    onAccept,
    awaitSave,
    ...props,
  };
  return <FireStationInfoFormView {...combineProps} />;
};

export default FireStationInfoFormContainer;
