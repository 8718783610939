import { ResponseActypeTypes as actionTypes } from '../types/Response.actionTypes';
import { ResponseState as initialStates } from '../state/FirechiefResponseState';

const fcResponseReducer = (state = initialStates, action: any) => {
  switch (action.type) {
    case actionTypes.RESPONSE_LOAD_START:
      return {
        ...state,
        isLoading: false,
        initialRequest: true,
        errorMessage: null,
        emergencyResponse: null,
      };
    case actionTypes.RESPONSE_LOAD_FETCH:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
      };
    case actionTypes.RESPONSE_LOAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        initialRequest: false,
        errorMessage: null,
        emergencyResponse: action.payload,
      };
    case actionTypes.RESPONSE_LOAD_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload,
      };
    case actionTypes.RESPONSE_LOAD_FSTATION:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        fireStationData: action.payload,
      };
    case actionTypes.RESPONSE_LOAD_REQUEST:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        emergencyRequest: action.payload,
      };
    case actionTypes.RESPONSE_CREATE_RESPONSE:
      return {
        ...state,
        ...initialStates,
      };
    case actionTypes.RESPONSE_UPDATE_RESPONSE:
      return {
        ...state,
        ...initialStates,
      };
    default:
      return state;
  }
};

export default fcResponseReducer;
