import React from 'react';
import { useFormContext } from 'react-hook-form';
import RequestEquipmentChildView from './RequestEquipmentChild.view';
import Box from '@mui/material/Box';
import useStyles from './RequestEquipment.styles';

const RequestEquipmentContext = ({
  name,
  emergencyDetails,
  hidden,
  isDisabled,
  ...props
}: any) => {
  const classes = useStyles();
  const { getValues } = useFormContext();
  const value = getValues();

  return (
    <Box hidden={hidden} height="100%">
      <Box className={classes.root}>
        <Box className={classes.equipmentWrapper}>
          {value.equipment?.map((field: any, index: number) => {
            return (
              <React.Fragment key={index}>
                <RequestEquipmentChildView
                  rootName={`equipment.${index}.items`}
                  field={field}
                  isDisabled={isDisabled}
                />
              </React.Fragment>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default RequestEquipmentContext;
