import { makeCreateActions, presetActions } from 'store/utils/Redux';
import { DashboardState } from 'store/state/DashboardState';

const ns = 'DASHBOARD';
const createDashboardActions = makeCreateActions(ns);

const dashboardActions = createDashboardActions({
  update: presetActions.makeIdentity<Partial<DashboardState>>(),
  clear: presetActions.noPayload,
});

export default dashboardActions;
