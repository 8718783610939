import { FireStationActionTypes as actionTypes } from '../types/FireStation.actionTypes';
import { FireStateState as initialStates } from '../state/FireStationsState';
import { QueryState } from 'common/enums';

const FireStationReducer = (state = initialStates, action: any) => {
  switch (action.type) {
    case actionTypes.FIRESTATION_RESET:
      return {
        ...state,
        isLoading: QueryState.READY,
        errorMessage: null,
        fireStations: [],
        pageTotal: 0,
      };
    case actionTypes.FIRESTATION_LOAD_STATION:
      return {
        ...state,
        isLoading: QueryState.READY,
        singleFireStation: action.payload,
      };
    case actionTypes.FIRESTATION_FETCHPAGE:
      return {
        ...state,
        isLoading: QueryState.AWAIT,
        fireStations: [],
        page: action.payload,
        pageTotal: 0,
      };
    case actionTypes.FIRESTATION_QUERYSTATUS:
      return {
        ...state,
        isLoading: action.payload.queryStatus,
      };
    case actionTypes.FIRESTATION_FETCH:
      return {
        ...state,
        isLoading: QueryState.AWAIT,
        errorMessage: null,
        fireStations: [],
        pageTotal: 0,
      };
    case actionTypes.FIRESTATION_LOAD_STATIONS:
      return {
        ...state,
        isLoading: QueryState.READY,
        initialPageTotal: action.payload.initialPageTotal
          ? action.payload.initialPageTotal
          : state.initialPageTotal,
        pageTotal: action.payload.pageTotal,
        fireStations: action.payload.stations,
      };
    case actionTypes.FIRESTATION_LOAD_CITIES:
      return {
        ...state,
        isLoading: QueryState.READY,
        cities: action.payload,
      };
    case actionTypes.FIRESTATION_LOAD_COUNTIES:
      return {
        ...state,
        isLoading: QueryState.READY,
        counties: action.payload,
      };
    case actionTypes.FIRESTATION_LOAD_CONTACTS:
      return {
        ...state,
        isLoading: QueryState.READY,
        contacts: action.payload,
      };
    case actionTypes.FIRESTATION_ERROR:
      return {
        ...state,
        isLoading: QueryState.FAIL,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
};

export default FireStationReducer;
