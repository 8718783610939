import * as React from 'react';

const ExportIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="12"
    viewBox="0 0 16 12"
    fill="black"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M15.3334 5.99996L12.6667 3.33329V5.33329H6.66675V6.66663H12.6667V8.66663L15.3334 5.99996ZM0.666748 9.99996V1.99996C0.666748 1.64634 0.807224 1.3072 1.05727 1.05715C1.30732 0.807102 1.64646 0.666626 2.00008 0.666626H10.0001C10.3537 0.666626 10.6928 0.807102 10.9429 1.05715C11.1929 1.3072 11.3334 1.64634 11.3334 1.99996V3.99996H10.0001V1.99996H2.00008V9.99996H10.0001V7.99996H11.3334V9.99996C11.3334 10.3536 11.1929 10.6927 10.9429 10.9428C10.6928 11.1928 10.3537 11.3333 10.0001 11.3333H2.00008C1.64646 11.3333 1.30732 11.1928 1.05727 10.9428C0.807224 10.6927 0.666748 10.3536 0.666748 9.99996Z" />
  </svg>
);

export default ExportIcon;
