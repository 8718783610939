import { ResponseActypeTypes as actionTypes } from '../types/Response.actionTypes';
import {
  IEmergencyResponse,
  IEmergencyResponseFireStationData,
} from 'common/interfaces';
import { IEmergencyRequest } from 'interfaces/EmergencyRequest';

const responseLoadStart = () => ({
  type: actionTypes.RESPONSE_LOAD_START,
});

const responseFetching = () => ({
  type: actionTypes.RESPONSE_LOAD_FETCH,
});

const responseLoadSuccess = (responses: IEmergencyResponse) => ({
  type: actionTypes.RESPONSE_LOAD_SUCCESS,
  payload: responses,
});

const responseLoadStationData = (
  responses: IEmergencyResponseFireStationData
) => ({
  type: actionTypes.RESPONSE_LOAD_FSTATION,
  payload: responses,
});

const responseLoadRequestData = (responses: IEmergencyRequest) => ({
  type: actionTypes.RESPONSE_LOAD_REQUEST,
  payload: responses,
});

const responseLoadError = (errorMessage: string) => ({
  type: actionTypes.RESPONSE_LOAD_ERROR,
  payload: errorMessage,
});

export default {
  responseLoadStart,
  responseFetching,
  responseLoadSuccess,
  responseLoadStationData,
  responseLoadRequestData,
  responseLoadError,
};
