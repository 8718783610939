import React from 'react';
import { Stack } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';
import { useFormContext } from 'react-hook-form';
import { ResourceType } from 'common/types';
import EquipmentHeader from './EquipmentHeader';
import { ISelectProp } from 'interfaces/FireStations';

const ProvidedResourceEquipment: React.FC<
  {
    name: ResourceType;
    stationOptions: ISelectProp[];
    active: boolean;
  } & BoxProps
> = ({ active = false, name, stationOptions, ...props }) => {
  const { watch, control } = useFormContext();
  const fieldData = watch(name);
  return (
    <Stack
      id={`provided-tabpanel-${name}`}
      aria-labelledby={`provided-tab-${name}`}
      hidden={!active}
      role="tabpanel"
      {...props}
    >
      <Box>
        {active && fieldData && fieldData.length > 0 ? (
          fieldData?.map((v: any, idx: number) => {
            return (
              <EquipmentHeader
                key={idx}
                index={idx}
                control={control}
                name={name}
                v={v}
                stationOptions={stationOptions}
              />
            );
          })
        ) : (
          <></>
        )}
      </Box>
    </Stack>
  );
};

export default ProvidedResourceEquipment;
