import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {},
    content: {
      width: '480px',
      [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        width: '100%',
      },
    },
    footer: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: theme.spacing(4),
      paddingRight: theme.spacing(1),
      columnGap: '10px',
      '& button': {
        maxWidth: '30%',
      },
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'flex-start',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        '& button': {
          maxWidth: 'unset',
        },
      },
    },
  };
});

export default useStyles;
