import { Dispatch } from 'redux';
import { AppState } from 'store/state/AppState';
import apiFetch, {
  getEmergencResponses,
  getEmergencyRequests,
} from 'services/apiFetch';
import { dashboardActions } from 'store/actions';
import { QueryState } from 'common/enums';

const getPageNumber = (count: number, limit: number): number => {
  return Math.ceil(count / limit);
};

const getEmergencies =
  (page: number) =>
  async (dispatch: Dispatch, getState: () => AppState): Promise<any> => {
    const currentPage = page || 1;
    const { pageLimit, pageTotal, initialPageTotal } = getState().dashboard;
    // Empty emergencies and show await
    dispatch(
      dashboardActions.update({
        emergencies: [],
        queryState: QueryState.AWAIT,
        page,
      })
    );
    // Get responses list
    const respEndpoint = getEmergencResponses();
    const ttlResponse = await apiFetch(respEndpoint).then(
      (res) => res.data?.dataset || []
    );
    if (page === 1) {
      const endpoint = getEmergencyRequests({
        limit: pageLimit,
        offset: 0,
      });
      const res = await apiFetch(endpoint).then((res) => res.data || null);
      const emergenciesWithResponse = res.dataset.map((e: any) => ({
        ...e,
        responseList: ttlResponse.filter(
          (v: any) =>
            v.requestId && parseInt(v.requestId) === parseInt(e.requestId)
        ),
      }));
      let payload = {};
      if (res?.count > 0) {
        const pageCount =
          res?.count > pageLimit ? getPageNumber(res?.count, pageLimit) : 1;

        payload = {
          queryState: !res ? QueryState.READY : QueryState.FAIL,
          emergencies: emergenciesWithResponse || [], //res?.dataset || [],
          pageTotal: pageCount,
          initialPageTotal: res?.count,
        };
      } else {
        const newPageTotal = res?.dataset.length === 0 ? 0 : pageTotal;
        payload = {
          queryState: !res ? QueryState.READY : QueryState.FAIL,
          emergencies: emergenciesWithResponse || [], //res?.dataset || [],
          pageTotal: newPageTotal,
        };
      }
      dispatch(dashboardActions.update(payload));
    } else {
      const offsetCount = (currentPage - 1) * pageLimit;
      const endpoint = getEmergencyRequests({
        limit: pageLimit,
        offset: offsetCount < initialPageTotal ? offsetCount : currentPage - 1,
      });
      const res = await apiFetch(endpoint).then((res) => res.data || null);
      const emergenciesWithResponse = res.dataset.map((e: any) => ({
        ...e,
        responseList: ttlResponse.filter(
          (v: any) =>
            v.requestId && parseInt(v.requestId) === parseInt(e.requestId)
        ),
      }));
      let payload = {};
      if (res?.count > 0) {
        const pageCount =
          res?.count > pageLimit ? getPageNumber(res?.count, pageLimit) : 1;
        payload = {
          queryState: !res ? QueryState.READY : QueryState.FAIL,
          emergencies: emergenciesWithResponse || [], //res?.dataset || [],
          pageTotal: pageCount,
        };
      } else {
        const newPageTotal = res?.dataset.length === 0 ? 0 : pageTotal;
        payload = {
          queryState: !res ? QueryState.READY : QueryState.FAIL,
          emergencies: emergenciesWithResponse || [], //res?.dataset || [],
          pageTotal: newPageTotal,
        };
      }
      dispatch(dashboardActions.update(payload));
    }
  };

export default getEmergencies;
