import { NotificationActionTypes as actionTypes } from '../types/Notification.actionTypes';

const updateQueryStatus = (payload: any) => ({
  type: actionTypes.NOTIFICATION_QUERYSTATUS,
  payload,
});

const fetchingNotification = () => ({
  type: actionTypes.NOTIFICATION_FETCH,
});

const responseNotification = (payload: any) => ({
  type: actionTypes.NOTIFICATION_RESPONSE,
  payload,
});

export default {
  updateQueryStatus,
  fetchingNotification,
  responseNotification,
};
