import React, { useState } from 'react';
import { SubmitHandler, Controller } from 'react-hook-form';
import Select, { OnChangeValue } from 'react-select';
import {
  Container,
  Divider,
  Grid,
  Box,
  Stack,
  Snackbar,
  Alert,
} from '@mui/material';
import { PaperLayout } from 'components/layouts';
import {
  FormGroup,
  TextField,
  TitleText,
  Typo,
  NumberFormatPhone,
} from 'components/primitives';
import { FireStationDetailProps } from './FireStationDetail.props';
import Button from 'components/primitives/Button';
import useStyles from './FireStationDetail.styles';
import { ISelectProp } from 'interfaces/FireStations';
import { PreLoaderSnippet } from 'components/snippets';
import { AlertMsgModal, ConfirmationModal } from 'components/modals';

const FireStationDetailView: React.FC<FireStationDetailProps> = ({
  form,
  counties,
  countyOption,
  cityOption,
  awaitRequest,
  isNewForm,
  errorMessage,
  alertError,
  handleSubmitForm,
  ...props
}: FireStationDetailProps) => {
  const classes = useStyles();
  const { control, handleSubmit, watch } = form;
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const fireStationName = watch('generalInformation.name', '');
  const countyName = watch('generalInformation.county', '');
  const awaitDelete = props.processingDelete && awaitRequest;

  const onSubmit: SubmitHandler<any> = (data) => {
    if (data) {
      setIsSubmitting(true);
      handleSubmitForm(data);
    }
  };

  const handleCountyChange =
    (onChange: any) => (newValue: OnChangeValue<ISelectProp, false>) => {
      onChange(newValue?.value);
    };

  // const handleCityChange =
  //   (onChange: any) => (newValue: OnChangeValue<ISelectProp, false>) => {
  //     onChange(newValue?.value);
  //   };

  const handlePhoneChange =
    (onChange: any) => (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(NumberFormatPhone(event));
    };

  if (awaitRequest && !isSubmitting && !props.processingDelete)
    return (
      <PreLoaderSnippet
        visible={awaitRequest}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '300px',
        }}
      />
    );

  return (
    <React.Fragment>
      <Container>
        {/* Alert error message */}
        <Snackbar
          open={alertError}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          sx={{ mt: 5 }}
        >
          <Alert
            variant="outlined"
            severity="error"
            sx={{ width: '100%', backgroundColor: 'common.white' }}
            onClose={props.hideAlertError}
          >
            {errorMessage || ''}
          </Alert>
        </Snackbar>
        <TitleText title={fireStationName} />
        <PaperLayout elevation={3} style={{ margin: 0 }}>
          <Typo variant="body1" fontWeight={600}>
            General information
          </Typo>
          <Divider sx={{ mt: 1, mb: 2 }} />
          <Grid container spacing={2}>
            <Grid item xs={12} lg={4}>
              <Controller
                name={'generalInformation.name'}
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <FormGroup label="Name" error={error}>
                    <TextField
                      value={value}
                      error={!!error}
                      onChange={onChange}
                    />
                  </FormGroup>
                )}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <Controller
                name="generalInformation.county"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <FormGroup label="County" error={error}>
                    <Select
                      onChange={handleCountyChange(onChange)}
                      options={countyOption}
                      value={{ value, label: value }}
                    />
                  </FormGroup>
                )}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <Controller
                name="generalInformation.city"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <FormGroup label="City" error={error}>
                    <TextField
                      value={value}
                      onChange={onChange}
                      disabled={!countyName.length}
                    />
                    {/* <CreatableSelect
                      onChange={handleCityChange(onChange)}
                      options={cityOption}
                      value={{ value, label: value }}
                      isDisabled={!countyName.length}
                    /> */}
                  </FormGroup>
                )}
              />
            </Grid>
            <Grid item xs={12} lg={12}>
              <Controller
                name="generalInformation.address1"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <FormGroup label="Address - Line 1" error={error}>
                    <TextField value={value} onChange={onChange} />
                  </FormGroup>
                )}
              />
            </Grid>
            <Grid item xs={12} lg={12}>
              <Controller
                name="generalInformation.address2"
                control={control}
                rules={{ required: false }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <FormGroup label="Address - Line 2" error={error}>
                    <TextField value={value} onChange={onChange} />
                  </FormGroup>
                )}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <Typo variant="body1" fontWeight={600}>
                #1 Person of contact
              </Typo>
              <Divider sx={{ mt: 1, mb: 2 }} />
              <Stack spacing={1}>
                <Controller
                  name="contact1.fullName"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <FormGroup label="Full Name" error={error}>
                      <TextField value={value} onChange={onChange} />
                    </FormGroup>
                  )}
                />
                <Controller
                  name="contact1.workPhone"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <FormGroup label="Work phone" error={error}>
                      <TextField
                        value={value}
                        onChange={handlePhoneChange(onChange)}
                      />
                    </FormGroup>
                  )}
                />
                <Controller
                  name="contact1.cellPhone"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <FormGroup label="Cell phone" error={error}>
                      <TextField
                        value={value}
                        onChange={handlePhoneChange(onChange)}
                      />
                    </FormGroup>
                  )}
                />
                <Controller
                  name="contact1.emailAddress"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <FormGroup label="Email address" error={error}>
                      <TextField value={value} onChange={onChange} />
                    </FormGroup>
                  )}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Typo variant="body1" fontWeight={600}>
                #2 Person of contact
              </Typo>
              <Divider sx={{ mt: 1, mb: 2 }} />
              <Stack spacing={1}>
                <Controller
                  name="contact2.fullName"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <FormGroup label="Full Name" error={error}>
                      <TextField value={value} onChange={onChange} />
                    </FormGroup>
                  )}
                />
                <Controller
                  name="contact2.workPhone"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <FormGroup label="Work phone" error={error}>
                      <TextField
                        value={value}
                        onChange={handlePhoneChange(onChange)}
                      />
                    </FormGroup>
                  )}
                />
                <Controller
                  name="contact2.cellPhone"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <FormGroup label="Cell phone" error={error}>
                      <TextField
                        value={value}
                        onChange={handlePhoneChange(onChange)}
                      />
                    </FormGroup>
                  )}
                />
                <Controller
                  name="contact2.emailAddress"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <FormGroup label="Email address" error={error}>
                      <TextField value={value} onChange={onChange} />
                    </FormGroup>
                  )}
                />
              </Stack>
            </Grid>
          </Grid>
          <Box className={classes.footer}>
            {!isNewForm ? (
              <Button
                color="error"
                fullWidth
                onClick={props.showDeleteModal}
                disabled={awaitRequest && isSubmitting}
              >
                Delete
              </Button>
            ) : null}
            <Button
              fullWidth
              onClick={handleSubmit(onSubmit)}
              progress={awaitRequest && isSubmitting}
            >
              Save
            </Button>
          </Box>
        </PaperLayout>
      </Container>
      <AlertMsgModal
        open={props.confirmModal}
        title="Fire Station"
        onPress={props.handleConfirmation}
      >
        <React.Fragment>
          <Typo variant="body1" mb={1}>
            The Fire Station information has been{' '}
            {isNewForm ? ' created' : ' updated'}.
          </Typo>
        </React.Fragment>
      </AlertMsgModal>
      <ConfirmationModal
        open={props.deleteModal}
        loading={awaitDelete}
        title="Delete Fire Station"
        onConfirm={(e, confirm) => props.handleConfirmDelete(confirm)}
      >
        <React.Fragment>
          <Typo variant="body1" color="common.black" mb={2}>
            Please confirm that you want to delete{' '}
            <span style={{ fontWeight: '700' }}>{fireStationName}</span>.
          </Typo>
        </React.Fragment>
      </ConfirmationModal>
    </React.Fragment>
  );
};

export default FireStationDetailView;
