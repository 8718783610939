import React from 'react';
import { CircularProgress, Fade, Stack } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';
import { Typo } from 'components/primitives';

const PreLoaderView: React.FC<
  { message?: string; visible: boolean } & BoxProps
> = ({ message, visible = false, ...props }) => {
  if (!visible) return null;
  return (
    <Box {...props}>
      <Stack rowGap={2}>
        <CircularProgress size={32} sx={{ mx: 'auto' }} />
        <Typo variant="body1" fontWeight={400} color="textSecondary">
          Please wait while we load the data
        </Typo>
      </Stack>
    </Box>
  );
};

export default PreLoaderView;
