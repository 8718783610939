import { UseFormProps } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
// import { IEmergencyRequest } from 'common/interfaces';
import { IResponseForm } from 'interfaces/EmergencyResponse';

export enum ResponseFormStep {
  PERSONNEL = 'personnel',
  EQUIPMENT = 'equipment',
}

export interface ResponseFormPersonnel {
  firstName: string;
  lastName: string;
  idNumber: string;
  rateOfPay: number;
}

export interface ResponseFormEquipment {
  description: string;
  code: string;
}

export interface ResponseEquipment {
  items: ResponseFormEquipment[];
  name: string;
}

export interface ResponsePersonnel {
  items: ResponseFormPersonnel[];
  name: string;
}

export interface ResponseForm {
  [ResponseFormStep.PERSONNEL]: {
    items: ResponseFormPersonnel[];
    name: string;
    notes: string;
  }[];
  [ResponseFormStep.EQUIPMENT]: {
    items: ResponseFormEquipment[];
    name: string;
  }[];
}

export class ResponseFormData implements ResponseForm {
  [ResponseFormStep.PERSONNEL] = [];
  [ResponseFormStep.EQUIPMENT] = [];

  constructor(p?: Partial<ResponseForm>) {
    Object.assign(this, p);
  }

  public static parse(data?: Partial<IResponseForm>): ResponseFormData {
    return new ResponseFormData({
      [ResponseFormStep.PERSONNEL]: data?.personnel?.map((p) => ({
        name: p.credentialsNeeded,
        notes: p.notes,
        items: Array.from(Array(3).keys()).map(() => ({
          idNumber: '',
          firstName: '',
          lastName: '',
          rateOfPay: 0,
        })),
      })),
      [ResponseFormStep.EQUIPMENT]: data?.equipment?.map((e) => ({
        name: e.description,
        items: Array.from(Array(3).keys()).map(() => ({
          description: '',
          code: '',
        })),
      })),
    });
  }
}

export const schema = yup.object().shape({
  [ResponseFormStep.EQUIPMENT]: yup.mixed().when('$step', {
    is: (step: string) => step === ResponseFormStep.EQUIPMENT,
    then: yup.array().test({
      name: 'atLeastOneResource',
      message: 'Please enter at least one of each personnel and equipment',
      test: (equipment, { parent }) => {
        const filteredEquipmentItem = parent.equipment?.map((e: any) =>
          e.items.some((e: any) => e.description && e.code)
        );
        const filteredPersonnelItem = parent.personnel?.map((p: any) =>
          p.items.some(
            (p: any) => p.idNumber && p.firstName && p.lastName && p.rateOfPay
          )
        );
        return (
          filteredPersonnelItem.includes(true) ||
          filteredEquipmentItem.includes(true)
        );
      },
    }),
  }),
});

export const ResponseFormProps = ({
  formData = new ResponseFormData(),
  step,
}: {
  formData?: ResponseFormData;
  step: string;
}): Partial<UseFormProps> => {
  return {
    defaultValues: formData,
    resolver: yupResolver(schema),
    context: { step },
  };
};
