import dashboard from './dashboard';
import page from './page';
import token from './token';
import response from './fcresponse';
import firestation from './firestation';
import notification from './notification';
import socket from './socket';
export default {
  dashboard,
  page,
  token,
  response,
  firestation,
  notification,
  socket,
};
