import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      padding: `0 ${theme.spacing(2)}`,
      width: '100%',
      height: '100%',
      [theme.breakpoints.down('md')]: {
        padding: 0,
      },
    },
    personnelWrapper: {
      [theme.breakpoints.up('md')]: {
        flex: 1,
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      },
    },
    personnelItem: {
      marginBottom: theme.spacing(1),
      padding: theme.spacing(3),
      borderRadius: theme.spacing(1),
      backgroundColor: '#fafafa',
      [theme.breakpoints.down('md')]: {
        padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
        '& .MuiInputBase-multiline': {
          height: 'auto',
        },
      },
    },
    button: {
      marginTop: theme.spacing(1),
      flex: '0 1 auto',
    },
    removeContainer: {
      display: 'flex',
      width: '100%',
      alignItems: 'flex-end',
    },
    iconClose: {
      cursor: 'pointer',
      marginLeft: 'auto',
    },
  };
});

export default useStyles;
