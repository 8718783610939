import { makeStyles } from '@mui/styles';
import { lighten, Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {},
    regionsWrapper: {
      overflowY: 'auto',
      height: '100%',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      [theme.breakpoints.down('md')]: {
        borderRadius: '8px',
      },
    },
    countiesList: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    countyItem: {
      flex: '0 1 25%',
      margin: 0,
      fontSize: '12px',
      textTransform: 'capitalize',
      [theme.breakpoints.down('md')]: {
        flex: '0 1 50%',
      },
      '& .MuiCheckbox-root': {
        color: theme.palette.grey[600],
      },
      '& .MuiTypography-root': {
        fontSize: '12px',
        fontWeight: 600,
        color: theme.palette.grey[600],
      },
    },
  };
});

const AccordionStyles = (theme: Theme): any => ({
  root: {
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  },
});

const AccordionSummaryStyles = (theme: Theme): any => ({
  root: {
    paddingLeft: 0,
    minHeight: '48px',
    borderBottom: `solid 1px ${theme.palette.grey[100]}`,
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(2),
    },
  },
  content: {
    margin: 0,
    '& .MuiFormControlLabel-label': {
      fontSize: '13px',
      fontWeight: 600,
    },
  },
  expanded: {
    margin: 0,
  },
});

const AccordionDetailStyles = (theme: Theme): any => ({
  root: {
    padding: `0 ${theme.spacing(1)} ${theme.spacing(2)}`,
    borderBottom: `solid 1px ${theme.palette.grey[100]}`,
  },
});

export { AccordionStyles, AccordionSummaryStyles, AccordionDetailStyles };
export default useStyles;
