/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Dispatch } from 'redux';
import { QueryState } from 'common/enums';
import apiFetch, {
  getEmergencyRequestsById,
  getEmergencyResponseByRequestId,
} from 'services/apiFetch';
import { pageActions } from 'store/actions';
import { AppState } from 'store/state/AppState';
import { IEmergencyResponse } from 'interfaces/EmergencyResponse';

const getEmergency =
  (requestId: string) =>
  async (dispatch: Dispatch, getState: () => AppState) => {
    dispatch(pageActions.update({ state: QueryState.AWAIT }));

    let emergencyData = getState().page?.emergencyData;
    const responses: IEmergencyResponse[] = emergencyData?.responseList || [];
    let state = QueryState.READY;

    // Get responses list
    const respEndpoint = getEmergencyResponseByRequestId({ requestId });
    const ttlResponse = await apiFetch(respEndpoint).then(
      (res) => res.data?.dataset || []
    );
    const request = await apiFetch(getEmergencyRequestsById({ requestId }));

    const emergenciesWithResponse = {
      ...request?.data,
      responseList: ttlResponse,
    };

    if (!request.data) state = QueryState.FAIL;
    else emergencyData = emergenciesWithResponse;

    dispatch(pageActions.update({ emergencyData, responses, state }));
  };

export default getEmergency;
