import { Dispatch } from 'redux';
import { AppState } from 'store/state/AppState';
import apiFetch, { getEmergencyRequests } from 'services/apiFetch';
import { pageActions } from 'store/actions';
import { QueryState } from 'common/enums';

const getEmergencyArchive =
  (page: number) =>
  async (dispatch: Dispatch, getState: () => AppState): Promise<any> => {
    dispatch(pageActions.update({ state: QueryState.AWAIT }));
    const { pageLimit } = getState().dashboard;
    // Empty emergencies and show await
    const endpoint = getEmergencyRequests({
      archived: 1,
      limit: pageLimit,
      offset: page - 1,
    });
    const res = await apiFetch(endpoint).then((res) => res.data || null);
    const payload = {
      state: !res ? QueryState.READY : QueryState.FAIL,
      emergencies: res?.dataset || [],
    };
    dispatch(pageActions.update(payload));
  };

export default getEmergencyArchive;
