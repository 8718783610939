import React from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import clsx from 'clsx';
import { Checkbox, FormControlLabel } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';

import useStyles from './EmergencyRegions.styles';

const CountiesView: React.FC<
  {
    region: number;
    counties: any[];
  } & BoxProps
> = ({ region, counties, ...props }) => {
  const classes = useStyles();
  const { control } = useFormContext();
  const countiesField = useFieldArray({
    name: `regions[${region}].counties`,
    control,
  });
  return (
    <Box {...props} className={clsx(classes.countiesList, props.className)}>
      {countiesField.fields.map((county: any, index: number) => {
        const field = `regions.${region}.counties.${index}.checked`;
        return (
          <Controller
            key={field}
            name={field}
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControlLabel
                className={classes.countyItem}
                label={county.county}
                control={
                  <Checkbox
                    key={county.id}
                    size="small"
                    checked={value}
                    onChange={onChange}
                  />
                }
              />
            )}
          />
        );
      })}
    </Box>
  );
};

export default CountiesView;
