import React, { useState } from 'react';
import { withStyles } from '@mui/styles';
import { CloseRounded } from '@mui/icons-material';
import { Box, Grid, Tab as MuiTab, Tabs as MuiTabs } from '@mui/material';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { ResourceEditFormProps } from 'helpers/forms/resourceEditForm';
import useStyles, {
  TabItemStyles,
  TabsStyles,
} from './ProvidedResources.styles';
import { Button, Typo } from 'components/primitives';
import { AcceptTabSnippet } from 'components/snippets';
import ProvidedResourcePersonnel from './ProvidedResourcePersonnel';
import ProvidedResourceEquipment from './ProvidedResourceEquipment';
import apiFetch, { updateEmergencyResponse } from 'services/apiFetch';

const Tabs = withStyles(TabsStyles)(MuiTabs);
const TabItem = withStyles(TabItemStyles)(MuiTab);

const ProvidedResourcesView: React.FC<any> = ({
  formData,
  tabIndex,
  stationOptions,
  emergencyResponse,
  ...props
}) => {
  const [processing, setProcessing] = useState<boolean>(false);
  const classes = useStyles();
  const form = useForm(
    ResourceEditFormProps({
      formData: formData,
    })
  );

  const resourceTabs = [
    {
      name: 'Personnel',
      resource: [],
    },
    {
      name: 'Equipment',
      resource: [],
    },
  ];

  const onSubmit: SubmitHandler<any> = async (data) => {
    setProcessing(true);
    const { equipment, personnel } = data;
    const responseList = emergencyResponse?.responseList || [];
    const finalResponses: any[] = [];

    responseList.map((response: any) => {
      const stationId = response.stationId;
      const stationEq: any[] = response?.details?.equipment || [];
      const stationPe: any[] = response?.details?.personnel || [];

      const storeEqArr = stationEq.map((eqInfo: any) => {
        const eqDesc = eqInfo?.description || '';
        const tempItemEq = eqInfo;
        tempItemEq.items = equipment
          .filter((eItem: any) => {
            return eItem.name === eqDesc;
          })
          .map((eItem: any) => {
            return eItem?.items?.filter((v: any) => v.stationId === stationId);
          })
          .flat();
        tempItemEq.totalAcceptedEquipment = tempItemEq.items.length;
        return tempItemEq;
      });

      const storePeArr = stationPe.map((peInfo: any) => {
        const peName = peInfo.credentialsNeeded || '';
        const tempItemPe = peInfo;
        tempItemPe.items = personnel
          .filter((pItem: any) => {
            return pItem.name === peName;
          })
          .map((pItem: any) => {
            return pItem?.items?.filter((v: any) => v.stationId === stationId);
          })
          .flat();
        tempItemPe.totalAcceptedPersonnel = tempItemPe.items.length;
        return tempItemPe;
      });

      const detailsInfo = response?.details;
      const hasAcceptedEq = storeEqArr.some(
        (r: any) => r.totalAcceptedEqupment > 0
      );
      const hasAcceptedPe = storePeArr.some(
        (r: any) => r.totalAcceptedPersonnel > 0
      );

      const status = hasAcceptedEq || hasAcceptedPe ? 1 : 2;
      const finalDetails = {
        ...detailsInfo,
        equipment: storeEqArr,
        personnel: storePeArr,
        status,
      };
      finalResponses.push({
        ...response,
        updateDate: new Date().toISOString(),
        details: finalDetails,
      });
    });

    try {
      if (finalResponses && finalResponses.length) {
        await Promise.all(
          finalResponses.map(async (response) => {
            const resp = await apiFetch(
              updateEmergencyResponse(response, {
                responseId: response.responseId,
                authorize: true,
              })
            );
            return resp?.data;
          })
        );
        setTimeout(() => {
          setProcessing(false);
          props.onClose(true);
        }, 3000);
      }
    } catch (error) {
      setProcessing(false);
      console.log('catch error:', error);
    }
  };

  return (
    <Box className={classes.content}>
      <Box className={classes.closeIconContainer}>
        <CloseRounded onClick={() => props.onClose(false)} />
      </Box>
      <Box className={classes.titleView}>
        <Typo variant="h4">Provided resources</Typo>
      </Box>
      <Box>
        <Typo variant="body1" color="GrayText">
          You can manage the provided resources below.
        </Typo>
      </Box>
      {/* Tab Header */}
      <Tabs
        value={tabIndex}
        onChange={props.handleTabChange}
        indicatorColor="secondary"
        textColor="inherit"
        variant="fullWidth"
        aria-label="fresources-tab"
        sx={{ mt: 2 }}
      >
        {resourceTabs.map((tab) => (
          <TabItem
            id={`resources-tab-${tab.name}`}
            key={tab.name}
            aria-controls={`resources-tabpanel-${tab.name}`}
            label={
              <FormProvider {...form}>
                <AcceptTabSnippet name={tab.name} tabIndex={tabIndex} />
              </FormProvider>
            }
          />
        ))}
      </Tabs>
      {/* Tab Content */}
      <Box className={classes.tabContent}>
        <FormProvider {...form}>
          <ProvidedResourcePersonnel
            active={tabIndex === 0}
            name="personnel"
            stationOptions={stationOptions}
          />
          <ProvidedResourceEquipment
            active={tabIndex === 1}
            name="equipment"
            stationOptions={stationOptions}
          />
        </FormProvider>
        <Box className={classes.footerBtn}>
          <Grid container spacing={1} direction="row">
            <Grid item xs={6}>
              <Button
                variant="outlined"
                neutral
                fullWidth
                onClick={() => props.onClose(false)}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                progress={processing}
                disabled={processing}
                onClick={form.handleSubmit(onSubmit)}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default ProvidedResourcesView;
