import React from 'react';
import { AppBar } from '@mui/material';

import { MainLayoutProps } from './MainLayout.props';
import useStyles from './MainLayout.styles';

const MainLayoutView: React.FC<MainLayoutProps> = (props: MainLayoutProps) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <AppBar position="sticky" elevation={1}>
        {props.toolbar}
      </AppBar>
      <div className={classes.page}>{props.children}</div>
      {props.footer}
    </div>
  );
};

export default MainLayoutView;
