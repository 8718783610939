import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { QueryState } from 'common/enums';
import useSelectorSafe from 'store/selectors/useSelectorSafe';
import { AppState } from 'store/state/AppState';
import EmergencyPageState, {
  EmergencyPageEmptyState,
} from 'store/state/pages/EmergencyPageState';
import { emergencyThunk } from 'thunks';
import {
  EmergencyDetailsModal,
  EmergencyDetailsModalFocus,
  EmergencyDetailsPublicProps,
  EmergencyDetailsProps,
} from './EmergencyDetails.props';
import EmergencyDetailsView from './EmergencyDetails.view';
import { loadNotificationByRequestId } from 'thunks/notification/getNotification';
import { AppNotification } from 'store/state/NotificationState';
import { SocketAppState } from 'store/state/SocketState';
import { RoomState } from 'common/enums';
import moment from 'moment';
import _ from 'lodash';
const EmptyModalFocus = {
  modal: EmergencyDetailsModal.UNDEFINED,
  payload: undefined,
};

const EmergencyDetailsContainer: React.FC<EmergencyDetailsPublicProps> = (
  ownProps: EmergencyDetailsPublicProps
) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const pageState: EmergencyPageState = useSelectorSafe((state: AppState) => {
    const page = state?.page;
    if (page && page.key === location?.key) return page;
    return EmergencyPageEmptyState;
  });
  const { roomResponse, from } = useSelector(
    (state: SocketAppState) => state.socket
  );
  const emergencyData = pageState?.emergencyData;
  const archiveState = pageState?.archiveState;
  const { isLoading, initialPageCount, notificationData } = useSelector(
    (state: AppNotification) => state.notification
  );
  const [modalFocus, setModalFocus] =
    useState<Partial<EmergencyDetailsModalFocus>>(EmptyModalFocus);
  const [reqStationData, setReqStationData] = useState<any>([]);
  const [goToArchive, setGoToArchive] = useState<boolean>(false);
  const responseList = emergencyData?.responseList || [];

  const handleConfirmArchive = () => {
    if (emergencyData) {
      dispatch(emergencyThunk.archive(emergencyData?.requestId));
      setGoToArchive(true);
    }
  };

  const handleModalFocus = (modal: Partial<EmergencyDetailsModalFocus>) => {
    setModalFocus(modal);
  };

  const refreshEmergencyData = () => {
    const requestId = params?.requestId;
    if (requestId) {
      dispatch(emergencyThunk.getEmergency(requestId));
    }
  };

  useEffect(() => {
    const requestId = params?.requestId;
    if (roomResponse !== null && from === RoomState.FFMOB_RESPONSE) {
      const wsReqId = roomResponse.data?.requestId || 0;
      if (requestId === wsReqId) {
        dispatch(emergencyThunk.getEmergency(requestId));
      }
    }
  }, [from]);

  useEffect(() => {
    if (goToArchive && archiveState === 4) {
      navigate('/dashboard/archive');
    }
  }, [archiveState]);

  useEffect(() => {
    const locationState: any = location?.state;
    const requestId = params?.requestId;
    const identify = {
      ...pageState,
      key: location.key,
      emergencyData: locationState?.emergencyData,
    };
    // Create a new page identity
    dispatch(emergencyThunk.identify(identify));

    if (requestId) {
      // Fetch emergency data
      dispatch(emergencyThunk.getEmergency(requestId));
      // Fetch notificatoin data
      dispatch(loadNotificationByRequestId({ requestId }));
    }
  }, []);

  useEffect(() => {
    const formatReqStationData = _(notificationData)
      .uniqWith(_.isEqual)
      .groupBy('stationId')
      .map((v: any, k: any) => {
        return {
          ...v[0].stationDetails,
          stationId: v[0].stationId,
          updateDate: moment(v[0].updateDate).format('L'),
        };
      })
      .value();
    if (formatReqStationData) {
      setReqStationData(formatReqStationData);
    }
  }, [notificationData]);

  const combinedProps: EmergencyDetailsProps = {
    ...ownProps,
    awaitArchive: pageState?.archiveState === QueryState.AWAIT,
    emergencyData,
    emergencyResponses: responseList,
    modalFocus,
    reqStationData,
    handleModalFocus,
    handleConfirmArchive,
    refreshEmergencyData,
    resetModalFocus: () => setModalFocus(EmptyModalFocus),
  };

  return <EmergencyDetailsView {...combinedProps} />;
};

export default EmergencyDetailsContainer;
