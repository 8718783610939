import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import useSelectorSafe from 'store/selectors/useSelectorSafe';
import { archiveThunk } from 'thunks';
import { QueryState } from 'common/enums';
import {
  EmergencyArchiveProps,
  EmergencyArchivePublicProps,
} from './EmergencyArchive.props';
import EmergencyArchiveView from './EmergencyArchive.view';
import PageState from 'store/state/PageState';
import ArchivePageState, {
  ArchivePageEmptyState,
} from 'store/state/pages/ArchivePageState';

const EmergencyArchiveContainer: React.FC<EmergencyArchivePublicProps> = (
  ownProps: EmergencyArchivePublicProps
) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const pageState: PageState<ArchivePageState> = useSelectorSafe(
    (state: any) => state?.page || ArchivePageEmptyState
  );
  const { emergencies, page, pageTotal } = pageState;
  const awaitRequest = pageState.state === QueryState.AWAIT;
  const handlePageChange = (num: number) => {
    if (num !== page) {
      dispatch(archiveThunk.getEmergencyArchive(num));
    }
  };

  useEffect(() => {
    dispatch(archiveThunk.identify({ ...pageState, key: location.key }));
    dispatch(archiveThunk.getEmergencyArchive(1));
  }, []);

  const combinedProps: EmergencyArchiveProps = {
    ...ownProps,
    awaitRequest,
    emergencies,
    page,
    pageTotal,
    handlePageChange,
  };
  return <EmergencyArchiveView {...combinedProps} />;
};

export default EmergencyArchiveContainer;
