import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {},
    formView: {
      flex: '0 1 100%',
      [theme.breakpoints.up('lg')]: {
        maxHeight: '504px',
      },
      [theme.breakpoints.down('md')]: {
        marginBottom: '52px',
      },
    },
    stepBox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '32px',
      height: '32px',
      borderRadius: '5px',
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.common.white,
    },
    disableWrap: {
      padding: 0,
      backgroundColor: 'transparent',
    },
    sidebar: {
      flex: '0 1 360px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      rowGap: theme.spacing(2),
      minHeight: '504px',
      height: '100%',
      border: 'solid 1px #EBEBEB',
      borderRadius: 8,
      backgroundColor: '#FAFAFA',
    },
    notes: {
      marginBottom: 10,
      fontSize: '12px',
      color: theme.palette.text.secondary,
    },
    footerView: {
      position: 'fixed',
      display: 'flex',
      justifyContent: 'center',
      bottom: 0,
      padding: `0 ${theme.spacing(3)} ${theme.spacing(2)}`,
      width: '100%',
    },
    footerNext: {
      '& button': {
        width: '240px',
        height: '52px',
      },
    },
    footerSkip: {
      marginRight: '0.5rem',
      '& button': {
        width: '56px',
        height: '52px',
      },
    },
  };
});

export default useStyles;
