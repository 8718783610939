import React from 'react';
import { useFormContext } from 'react-hook-form';
import PersonnelContainer from './PersonnelContainer';
import EquipmentContainer from './EquipmentContainer';
import useStyles from './AcceptTabSnippet.style';

const AcceptTabSnippet: React.FC<{
  name: string;
  tabIndex: number;
}> = ({ name, tabIndex }) => {
  const { watch } = useFormContext();
  const fieldData = watch(name.toLowerCase());
  const totalRequest =
    fieldData && fieldData.reduce((a: any, o: any) => a + o.requested, 0);
  const totalAccepted =
    fieldData && fieldData.flatMap((i: any) => i.items).length;

  return (
    <React.Fragment>
      {name && name === 'Personnel' ? (
        <PersonnelContainer
          tabIndex={tabIndex}
          name={name}
          totalRequest={totalRequest}
          totalApprove={totalAccepted}
        />
      ) : (
        <EquipmentContainer
          tabIndex={tabIndex}
          name={name}
          totalRequest={totalRequest}
          totalApprove={totalAccepted}
        />
      )}
    </React.Fragment>
  );
};

export default AcceptTabSnippet;
