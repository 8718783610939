import React, { useState, useEffect } from 'react';
import {
  CheckCircleOutline as CheckCircleOutlineIcon,
  HighlightOff as HighlightOffIcon,
} from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box, { BoxProps } from '@mui/material/Box';
import {
  IEmergencyRequest,
  IEmergencyResponse,
  IEmergencyResponseResource,
} from 'common/interfaces';
import { responseIdFormat } from 'common/utils/String';
import { PaperLayout } from 'components/layouts';
import { Button, Typo } from 'components/primitives';
import apiFetch, { getEmergencyRequestsById } from 'services/apiFetch';
import useStyles from './ResponseCard.styles';
// import { countBy, flatMap } from 'lodash';

const ResponseCard: React.FC<
  {
    emergencyResponse: IEmergencyResponse;
    onAccept: (accept: boolean, reqPayload: any) => void;
  } & BoxProps
> = ({ emergencyResponse, onAccept, ...props }) => {
  const theme = useTheme();
  const classes = useStyles();
  const { requestId, responseId, details } = emergencyResponse;
  const { equipment, personnel } = details;
  const [emergencyData, setEmergencyData] = useState<null | IEmergencyRequest>(
    null
  );
  const countEquipment = equipment.flatMap((e: any) => e.items).length;
  const countPersonnel = personnel.flatMap((p: any) => p.items).length;
  const disableBtn = details?.status !== 0 || undefined ? true : false;
  const eventName = emergencyData?.details?.eventName || '';
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const handleAction = (accept: boolean) => () =>
    onAccept(accept, emergencyData);

  useEffect(() => {
    const fetchRequest = async () => {
      const res = await apiFetch(getEmergencyRequestsById({ requestId }));
      setEmergencyData(res.data);
    };

    if (!disableBtn) fetchRequest();
  }, []);

  return (
    <Box className={classes.root} {...props}>
      <PaperLayout elevation={3} sx={{ m: 0, p: 2 }}>
        <Box>
          <Typo mb={1} variant="body2" color="textSecondary">
            {eventName} Request #{requestId}
          </Typo>
          <Typo variant="h5" fontWeight={600}>
            {`Offer #${responseIdFormat(responseId)}`}
          </Typo>
          <Box className={classes.location} sx={{ mb: 1 }}>
            {/*
            // TODO: missing data
            <Typo color="textSecondary" variant="body1" fontWeight={600}>
              {department.city}, {department.state}
            </Typo>
            <Typo color="textSecondary" variant="body1">
              {department.distance} mi
            </Typo> */}
          </Box>
          <Box className={classes.flexBoxes} sx={{ mb: 2 }}>
            <Box className={classes.greyBox}>
              <Typo variant="body1">Personnel</Typo>
              <Typo variant="h6" fontWeight={600}>
                {countPersonnel}
              </Typo>
            </Box>
            <Box className={classes.greyBox}>
              <Typo variant="body1">Equipment</Typo>
              <Typo variant="h6" fontWeight={600}>
                {countEquipment}
              </Typo>
            </Box>
          </Box>
          <Box className={classes.flexBoxes}>
            {isMobile ? (
              <>
                <Button
                  onClick={handleAction(false)}
                  neutral
                  fullWidth
                  disabled={disableBtn}
                >
                  <HighlightOffIcon fontSize="medium" />
                </Button>
                <Button
                  onClick={handleAction(true)}
                  fullWidth
                  disabled={disableBtn}
                >
                  <CheckCircleOutlineIcon fontSize="medium" />
                </Button>
              </>
            ) : (
              <>
                <Button
                  onClick={handleAction(false)}
                  neutral
                  fullWidth
                  disabled={disableBtn}
                >
                  Decline
                </Button>
                <Button
                  onClick={handleAction(true)}
                  fullWidth
                  disabled={disableBtn}
                >
                  Accept
                </Button>
              </>
            )}
          </Box>
        </Box>
      </PaperLayout>
    </Box>
  );
};

export default ResponseCard;
