import { Dispatch } from 'redux';
import { QueryState } from 'common/enums';
import { AppState } from 'store/state/AppState';
import { pageActions } from 'store/actions';
import apiFetch, { updateEmergencyRequest } from 'services/apiFetch';

const archive =
  (requestId: number | string) =>
  async (dispatch: Dispatch, getState: () => AppState): Promise<any> => {
    dispatch(pageActions.update({ archiveState: QueryState.AWAIT }));
    // Start the archive process
    await apiFetch(updateEmergencyRequest({ requestId, archived: 1 }));

    const { emergencyData } = getState().page;
    const payload = {
      archiveState: QueryState.UPDATED,
      emergencyData: {
        ...emergencyData,
        archived: 1,
      },
    };
    dispatch(pageActions.update(payload));
  };

export default archive;
