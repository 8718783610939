const EquipmentOptions = [
  'Ambulance',
  'ARFF Truck',
  'Battalion Vehicle',
  'Brush Truck',
  'Engine/Pumper',
  'Flat Bottom Boat',
  'Generator',
  'Hazmat Truck',
  'High Water Vehicle',
  'Large Ventilation Unit',
  'Light/Air Truck',
  'Mobile Command Post',
  'Ocean Worthy Firefighting Vessel',
  'Platform',
  'Quint',
  'Rehab Truck',
  'Rescue Truck',
  'Rigid Inflatable Boat',
  'Tanker/Tender',
  'UTV',
].sort();

export { EquipmentOptions };
