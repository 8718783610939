import React, { useState } from 'react';
import { useFormContext, useFieldArray, useWatch } from 'react-hook-form';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  Checkbox as MuiCheckbox,
  FormControlLabel,
  IconButton,
  Stack,
} from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';
import { IRegion } from 'common/interfaces';
import { Typo } from 'components/primitives';
import CountiesView from './Counties.view';
import useStyles, {
  AccordionStyles,
  AccordionSummaryStyles,
  AccordionDetailStyles,
} from './EmergencyRegions.styles';
import { filter } from 'lodash';

const Accordion = withStyles(AccordionStyles)(MuiAccordion);
const AccordionDetails = withStyles(AccordionDetailStyles)(MuiAccordionDetails);
const AccordionSummary = withStyles(AccordionSummaryStyles)(
  MuiAccordionSummary
);

const CheckBox = ({
  region,
  onChange,
}: {
  region: any;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  const len = region.counties?.length;
  const checked = region.counties.filter((c: any) => c.checked)?.length;
  const opacity = checked === 0 || checked === len ? 1 : 0.5;
  return (
    <MuiCheckbox checked={checked > 0} sx={{ opacity }} onChange={onChange} />
  );
};

const EmergencyRegionsView: React.FC<
  {
    name: string;
  } & BoxProps
> = ({ name, ...props }) => {
  const classes = useStyles();
  const { control, getValues } = useFormContext();
  const [expandedRegions, setExpandedRegions] = useState<number[]>([]);
  const regionsField = useFieldArray({ control, name });
  // Let's watch any changes in a regions
  useWatch({ name, control });

  const onExpand = (region: number) => {
    const indexes = [...expandedRegions];
    const index = expandedRegions.findIndex((x) => x === region);
    if (index === -1) indexes.push(region);
    else indexes.splice(index, 1);
    setExpandedRegions(indexes);
  };

  const onToggle =
    (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const { region, counties }: any = regionsField.fields[index];
      const active = !counties.some((c: any) => !c.checked);
      const expanded = expandedRegions.includes(region);
      regionsField.update(index, {
        counties: counties.map((c: any) => ({ ...c, checked: !active })),
        region: region,
      });
      // Let's force expand, to view all checked counties
      if (!active && !expanded) onExpand(region);
    };

  return (
    <Box {...props} height="100%">
      <Box className={classes.regionsWrapper}>
        {regionsField.fields.map((region: any, index: number) => {
          const expanded = expandedRegions.includes(region.region);
          const counties = filter(region.counties, { checked: true })?.length;
          return (
            <Accordion
              // Important! Make sure to use array field Id,
              // this will force to reload the elements after the update
              key={region.id}
              elevation={0}
              expanded={expanded}
              square
              disableGutters
            >
              <AccordionSummary
                expandIcon={
                  <IconButton onClick={() => onExpand(region.region)}>
                    <ExpandMoreIcon />
                  </IconButton>
                }
                aria-controls={`region[${region.name}}]-header`}
                id={`region[${region.name}}]-header`}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ width: '100%' }}
                >
                  <FormControlLabel
                    control={
                      <CheckBox region={region} onChange={onToggle(index)} />
                    }
                    label={`Region ${region.region}`}
                  />
                  {counties > 0 && (
                    <Typo variant="body2" color="textSecondary">
                      {counties} selected
                    </Typo>
                  )}
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                <CountiesView region={index} counties={region.counties} />
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Box>
    </Box>
  );
};

export default EmergencyRegionsView;
