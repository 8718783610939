import React from 'react';
import Box from '@mui/material/Box';
import { Typo } from 'components/primitives';
import useStyles from './AcceptTabSnippet.style';

const PersonnelContainer: React.FC<{
  tabIndex: number;
  name: string;
  totalRequest: number;
  totalApprove: number;
}> = ({ tabIndex, name, totalRequest, totalApprove }) => {
  const classes = useStyles();
  const active: boolean = tabIndex === 0;
  return (
    <React.Fragment>
      <Box className={classes.tabContainer}>
        <Typo variant="h6">{name}</Typo>
        <Box flexDirection="row" display="flex">
          <Box flexDirection="row" display="flex" alignItems="center">
            <Typo variant="caption" className={classes.title}>
              Total Request:
            </Typo>
            <Typo className={active ? classes.count : classes.countDefault}>
              {totalRequest}
            </Typo>
          </Box>
          <Box flexDirection="row" display="flex" alignItems="center">
            <Typo variant="caption" className={classes.title}>
              Total Approved:
            </Typo>
            <Typo className={active ? classes.count : classes.countInActive}>
              {totalApprove}
            </Typo>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default PersonnelContainer;
