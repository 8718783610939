import React from 'react';
import { TypographyProps } from '@mui/material/Typography';
import { CheckCircle } from '@mui/icons-material';
import { Typo } from 'components/primitives';

const StepBox: React.FC<
  { complete?: boolean; step?: number } & TypographyProps
> = ({
  complete = false,
  fontWeight = 600,
  step = 1,
  variant = 'body1',
  ...props
}) => {
  return (
    <Typo {...props} variant={variant} fontWeight={fontWeight}>
      {complete ? <CheckCircle sx={{ fontSize: 16 }} /> : step}
      {/* {s.step < active ? (
          <CheckCircle sx={{ fontSize: '16px' }} />
        ) : (
          `${i + 1}`
        )} */}
    </Typo>
  );
};

export default StepBox;
