import React from 'react';
import { Control, useWatch } from 'react-hook-form';
import { flatMap } from 'lodash';
import { Checkbox } from '@mui/material';
import { IEmergencyResponseResource } from 'common/interfaces';
import { Typo } from 'components/primitives';

const ResourceTabSnippet: React.FC<{
  control: Control;
  name: string;
  onCheckAll: (name: string, accept: boolean) => void;
}> = ({ control, name, onCheckAll }) => {
  const tabWatch = useWatch({
    name: name.toLowerCase(),
    control: control,
  });
  const items = flatMap(
    tabWatch.map((r: IEmergencyResponseResource) => r.items)
  );
  const checked = items.filter((item: any) => item.accepted).length;

  const onToggle = () => {
    const unchecked = checked === items.length;
    onCheckAll(name.toLowerCase(), !unchecked);
  };

  return (
    <React.Fragment>
      <Typo variant="h6">
        <Checkbox
          checked={checked > 0}
          sx={{
            opacity: checked > 0 && checked < items.length ? 0.5 : 1,
          }}
          onChange={onToggle}
        />
        {name}
      </Typo>
      <Typo variant="body2" fontWeight={600}>
        {checked} items
      </Typo>
    </React.Fragment>
  );
};

export default ResourceTabSnippet;
