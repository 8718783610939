import React from 'react';
import { Divider, Paper } from '@mui/material';
import { PaperProps } from './PaperLayout.props';
import useStyles from './PaperLayout.styles';

const PaperLayout: React.FC<PaperProps> = ({
  title,
  titleView,
  ...props
}: PaperProps) => {
  return (
    <Paper
      elevation={0}
      sx={{
        mt: { xs: 3, md: 6 },
        mx: { lg: 8 },
        p: { xs: 2, md: 3 },
      }}
      {...props}
    >
      {titleView && (
        <React.Fragment>
          {titleView}
          <Divider sx={{ mb: 4 }} />
        </React.Fragment>
      )}
      {props.children}
    </Paper>
  );
};

export default PaperLayout;
