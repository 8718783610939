export enum FireStationActionTypes {
  FIRESTATION_FETCH = 'FIRESTATION_FETCH',
  FIRESTATION_LOAD_STATIONS = 'FIRESTATION_LOAD_STATIONS',
  FIRESTATION_LOAD_COUNTIES = 'FIRESTATION_LOAD_COUNTIES',
  FIRESTATION_LOAD_CITIES = 'FIRESTATION_LOAD_CITIES',
  FIRESTATION_LOAD_CONTACTS = 'FIRESTATION_LOAD_CONTACTS',
  FIRESTATION_ERROR = 'FIRESTATION_ERROR',
  FIRESTATION_LOAD_FS_LIST = 'FIRESTATION_LOAD_FS_LIST',
  FIRESTATION_QUERYSTATUS = 'FIRESTATION_QUERYSTATUS',
  FIRESTATION_FETCHPAGE = 'FIRESTATION_FETCHPAGE',
  FIRESTATION_LOAD_STATION = 'FIRESTATION_LOAD_STATION',
  FIRESTATION_RESET = 'FIRESTATION_RESET',
}
