import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { SubmitHandler, Controller } from 'react-hook-form';
import { Container, Divider, Grid, ButtonGroup } from '@mui/material';
import { PaperLayout } from 'components/layouts';
import {
  Button,
  FormGroup,
  TextField,
  TitleText,
  Typo,
} from 'components/primitives';
import { CoordinatorProfileProps } from './CoordinatorProfile.props';

import useStyles from './CoordinatorProfile.styles';

const CoordinatorProfileView: React.FC<CoordinatorProfileProps> = (
  props: CoordinatorProfileProps
) => {
  const account = props.account;
  const { control, handleSubmit, watch } = props.form;
  const [onVacation, setOnVacation] = useState<boolean>(false);

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      // console.log(value);
      // console.log(name);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  // if (!account) return null;
  const toggleSwitch = () => {
    setOnVacation(!onVacation);
  };

  const onSubmit: SubmitHandler<any> = (data) => {
    // console.log('onSubmit Trigger', data);
  };
  return (
    <Container>
      <TitleText title={`Fire Coordinator #${account?.pvAccountID}`} />
      <PaperLayout elevation={3} style={{ margin: 0 }}>
        <Typo variant="h5">Profile information</Typo>
        <Divider sx={{ mt: 1, mb: 2 }} />
        <Grid container spacing={2} mb={3}>
          <Grid item xs={12} lg={6}>
            <Controller
              name={'pvFirstName'}
              control={control}
              rules={{ required: true }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormGroup label="Name" error={error}>
                  <TextField
                    // defaultValue={account?.pvFirstName || ''}
                    value={value}
                    onChange={onChange}
                    aria-readonly
                  />
                </FormGroup>
              )}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Controller
              name={'pvLastName'}
              control={control}
              rules={{ required: true }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormGroup label="Surname" error={error}>
                  <TextField value={value} onChange={onChange} aria-readonly />
                </FormGroup>
              )}
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <Controller
              name={'pvAddress'}
              control={control}
              rules={{ required: true }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormGroup label="Address - Line 1" error={error}>
                  <TextField value={value} onChange={onChange} aria-readonly />
                </FormGroup>
              )}
            />
          </Grid>
          <Grid item lg={4}>
            <ButtonGroup variant="outlined" aria-label="outlined button group">
              <Button
                sx={{ width: 120 }}
                alternate={!onVacation}
                onClick={toggleSwitch}
              >
                On Vacation
              </Button>
              <Button
                sx={{ width: 120 }}
                alternate={onVacation}
                onClick={toggleSwitch}
              >
                Available
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>
        <Button sx={{ width: 120 }} onClick={handleSubmit(onSubmit)}>
          Save
        </Button>
      </PaperLayout>
    </Container>
  );
};

export default CoordinatorProfileView;
