import { IEmergencyRequest, IEmergencyResponse } from 'common/interfaces';

export enum DashboardMainModal {
  UNDEFINED = 0,
  ACCEPT = 1,
  DECLINE = 2,
}

export interface DashboardModalFocus {
  modal: DashboardMainModal;
  payload: IEmergencyResponse;
  requestPayload: IEmergencyRequest;
}

export interface DashboardMainPublicProps {
  /** public props */
}

export interface DashboardMainCanceledProps {
  awaitRequest: boolean;
  emergencies: any[];
  modalFocus: Partial<DashboardModalFocus>;
  page: number;
  pageLimit: number;
  pageTotal: number;
  initialPageTotal: number;
  responses: any[];
  handlePageChange: (num: number) => void;
  handleModalFocus: (modalFocus: Partial<DashboardModalFocus>) => void;
  navigateToEmergency: (e: IEmergencyRequest) => void;
  navigateToNewEmergency: () => void;
  refreshResponseData: () => void;
}

export type DashboardMainProps = DashboardMainPublicProps &
  DashboardMainCanceledProps;
