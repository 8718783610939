import React from 'react';
import { withStyles } from '@mui/styles';
import MuiButton, { ButtonProps } from '@mui/material/Button';

import { ButtonStyles } from './ButtonSmall.styles';

const StyledButton = withStyles(ButtonStyles)(MuiButton);

const ButtonSmall: React.FC<ButtonProps> = (props) => (
  <StyledButton {...props} size="small" variant="contained" disableElevation>
    {props.children}
  </StyledButton>
);

export default ButtonSmall;
