import React, { useState } from 'react';
import ProvidedResourcesView from './ProvidedResources.view';
import { ResourceEditFormData } from 'helpers/forms/resourceEditForm';
import { ISelectProp } from 'interfaces/FireStations';

const ProvidedResourcesContainer: React.FC<any> = (publicProps: any) => {
  const { emergencyResponse } = publicProps;
  const details = emergencyResponse?.details || null;
  const responseList = emergencyResponse?.responseList || [];
  const [tabIndex, setTabIndex] = useState<number>(0);
  let stationOptions: ISelectProp[] = [];
  let formData: ResourceEditFormData = {
    personnel: [],
    equipment: [],
  };

  if (details && responseList.length) {
    stationOptions = responseList.map((request: any) => {
      const fireStationOptions = {
        value: request.details.fireStationInfo.stationId,
        label: request.details.fireStationInfo.details.name,
      };
      return fireStationOptions;
    });

    const data = responseList;
    const newEquipment: any = [];
    const newPersonnel: any = [];
    const formatedEq: any = [];
    const formatedPe: any = [];

    data.map((request: any) => {
      const fireStationID = request.details.fireStationInfo.stationId || 0;
      request.details.equipment = request.details.equipment.map(
        (equipment: any) => {
          equipment.items = equipment.items.map((item: any) => {
            item['stationId'] = fireStationID;
            item['requested'] = parseInt(equipment?.number || '0');
            return item;
          });
          return equipment;
        }
      );
      newEquipment.push(...request.details.equipment);

      request.details.personnel = request.details.personnel.map(
        (personnel: any) => {
          personnel.items = personnel.items.map((item: any) => {
            item['stationId'] = fireStationID;
            item['requested'] = parseInt(personnel?.number || '0');
            return item;
          });
          return personnel;
        }
      );
      newPersonnel.push(...request.details.personnel);
      return request;
    });

    const personnelGroup = newPersonnel.reduce((r: any, a: any) => {
      r[a.credentialsNeeded] = r[a.credentialsNeeded] || [];
      r[a.credentialsNeeded].push(a);
      return r;
    }, Object.create(null));

    for (const [key, value] of Object.entries(personnelGroup)) {
      const ob: any = { requested: 0, name: key, items: value };
      formatedPe.push(ob);
    }

    formatedPe.map((item: any, idx: number) => {
      const items = item?.items.flatMap((i: any) => {
        return i.items.filter((a: any) => a?.accepted);
      });
      const getRequestNum = data[0].details?.personnel[idx].number;
      item.requested = parseInt(getRequestNum || '0');
      item.items = items;
      return item;
    });

    const equipmentGroup = newEquipment.reduce((r: any, a: any) => {
      r[a.description] = r[a.description] || [];
      r[a.description].push(a);
      return r;
    }, Object.create(null));

    for (const [key, value] of Object.entries(equipmentGroup)) {
      const ob: any = { requested: 0, name: key, items: value };
      formatedEq.push(ob);
    }

    formatedEq.map((item: any, idx: number) => {
      const items = item?.items.flatMap((i: any) => {
        return i.items.filter((a: any) => a?.accepted);
      });
      const getRequestNum = data[0].details?.equipment[idx].number;
      item.requested = parseInt(getRequestNum || '0');
      item.items = items;
      return item;
    });

    formData = {
      personnel: formatedPe,
      equipment: formatedEq,
    };
  }

  const combineProps = {
    ...publicProps,
    stationOptions,
    formData,
    tabIndex,
    handleTabChange: (_e: React.SyntheticEvent, index: number) =>
      setTabIndex(index),
  };
  return <ProvidedResourcesView {...combineProps} />;
};

export default ProvidedResourcesContainer;
