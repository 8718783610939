import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {},
    textarea: {
      resize: 'none',
      padding: theme.spacing(3),
      width: '100%',
      lineHeight: theme.spacing(3),
      borderRadius: theme.spacing(1),
      borderColor: '#AEAEAE',
      fontFamily: 'inherit',
      color: theme.palette.text.secondary,
    },
  };
});

export default useStyles;
