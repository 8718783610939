import React from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { AddCircle as AddCircleIcon } from '@mui/icons-material';
import { Grid, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import { Button, FormGroup, TextField, Typo } from 'components/primitives';
import useStyles from './RequestEquipment.styles';

const RequestEquipmentChildView = ({
  rootName,
  // emergencyDetails,
  field,
  isDisabled,
}: any) => {
  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { control } = useFormContext();
  const equipment = useFieldArray({ control, name: rootName });

  const handleAppend = () => {
    equipment.append({
      description: '',
      code: '',
    });
  };

  const renderEquipmentFields = (
    rootName: string,
    items: any,
    index: number
  ) => {
    return (
      <Grid
        key={`${rootName}.${index}`}
        container
        spacing={0.5}
        className={classes.equipmentItem}
      >
        <Grid item className={classes.nameColumn} xs={12} lg={8}>
          {!isMobile && <span>{index + 1}.</span>}
          <Box sx={{ flex: 1 }}>
            <Controller
              name={`${rootName}.${index}.description`}
              control={control}
              rules={{ required: true }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormGroup label={isMobile ? 'Description' : ''} error={error}>
                  <TextField
                    value={value}
                    error={!!error}
                    onChange={onChange}
                    disabled={isDisabled}
                  />
                </FormGroup>
              )}
            />
          </Box>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Controller
            name={`${rootName}.${index}.code`}
            control={control}
            rules={{ required: false }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FormGroup label={isMobile ? 'FEMA Cost Code' : ''} error={error}>
                <TextField
                  value={value}
                  error={!!error}
                  onChange={onChange}
                  disabled={isDisabled}
                />
              </FormGroup>
            )}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <React.Fragment>
      <Box className={classes.titleBox}>
        <Box className={classes.titleHeader}>
          <Typo variant="body1" fontWeight={600}>
            {field?.name}
          </Typo>
          <Stack alignItems="center" direction="row" columnGap="10px">
            <span>Total: {field.items.length}</span>
            <Button
              className={classes.entryButton}
              endIcon={<AddCircleIcon />}
              onClick={handleAppend}
              disabled={isDisabled}
            >
              New entry
            </Button>
          </Stack>
        </Box>
      </Box>
      <Stack rowGap={isMobile ? 1.5 : 0} mb={1}>
        {!isMobile && (
          <Grid mb={1} spacing={1} container>
            <Grid item lg={8} className={classes.titleColumn}>
              <span style={{ marginLeft: '24px' }}>Vehicle Description</span>
            </Grid>
            <Grid item lg={4} className={classes.titleColumn}>
              Fema Cost Code
            </Grid>
          </Grid>
        )}
        {equipment.fields?.map((field, index) =>
          renderEquipmentFields(rootName, field, index)
        )}
      </Stack>
    </React.Fragment>
  );
};

export default RequestEquipmentChildView;
