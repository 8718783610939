import React, { MouseEvent } from 'react';
import clsx from 'clsx';
import {
  HighlightOff as CloseOutlinedIcon,
  TaskAlt as CheckOutlinedIcon,
  ChevronRight as ChevronRightIcon,
} from '@mui/icons-material';
import { Stack } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';
import { PaperProps } from '@mui/material/Paper';
import { IEmergencyResponse } from 'common/interfaces';
import { Button, Typo } from 'components/primitives';
import { PaperLayout } from 'components/layouts';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import useStyles from './ResponseItemBox.styles';

const ResponseItemBoxView: React.FC<
  {
    emergencyData: IEmergencyResponse;
    elevation?: number;
    meta?: { label: string | number; value: string | number }[];
    name: string;
    paperProps?: PaperProps;
    onSelect?: (e: MouseEvent) => void;
    onResponse: (accept: boolean) => void;
    // viewProvided: () => void;
  } & BoxProps
> = ({
  emergencyData,
  paperProps,
  meta = [],
  name,
  onSelect,
  onResponse,
  // viewProvided,
  ...props
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { equipment, personnel } = emergencyData?.details || {};
  const status = emergencyData?.details.status || 0;
  const countEquipmentItems: number = equipment.reduce(
    (sum, e) => sum + (e.items && e.items.length),
    0
  );
  const countPersonnelItems: number = personnel.reduce(
    (sum, e) => sum + (e.items && e.items.length),
    0
  );

  return (
    <a aria-hidden="true" onClick={onSelect}>
      <Box {...props}>
        <PaperLayout
          sx={{ px: 2, py: 1, minHeight: '50px' }}
          {...paperProps}
          className={clsx(classes.root)}
        >
          <Box className={clsx(classes.leftColumn, 'wrapper')}>
            <Typo variant="h5">
              <strong>{name}</strong>
            </Typo>
            {meta?.length > 0 && (
              <Stack direction="row" columnGap={1} className={classes.meta}>
                {meta.map((m) => (
                  <Typo key={m.label} variant="body2" color="textSecondary">
                    {m.label}
                    <strong>{m.value || ''}</strong>
                  </Typo>
                ))}
              </Stack>
            )}
          </Box>
          <Stack direction="row" className={clsx(classes.resources, 'wrapper')}>
            <Stack
              direction="row"
              columnGap={2}
              sx={{ width: { xs: '100%' }, mr: { sm: 5 } }}
            >
              <Box className={classes.greyBox}>
                <Typo variant="body2" color="textSecondary">
                  Personnel
                </Typo>
                <Typo variant="h5" textAlign="right">
                  {countPersonnelItems}
                </Typo>
                <ChevronRightIcon />
              </Box>
              <Box className={classes.greyBox}>
                <Typo variant="body2" color="textSecondary">
                  Equipment
                </Typo>
                <Typo variant="h5" textAlign="right">
                  {countEquipmentItems}
                </Typo>
                <ChevronRightIcon />
              </Box>
            </Stack>
            {isMobile ? (
              <Stack
                direction="row"
                columnGap={1}
                className={classes.responseButtons}
              >
                {status === 0 && (
                  <React.Fragment>
                    <Button
                      className={classes.squareButton}
                      onClick={() => onResponse(false)}
                      neutral
                    >
                      <CloseOutlinedIcon />
                    </Button>
                    <Button
                      className={classes.squareButton}
                      onClick={() => onResponse(true)}
                    >
                      <CheckOutlinedIcon />
                    </Button>
                  </React.Fragment>
                )}
                {status === 1 && (
                  <React.Fragment>
                    <Button>Accepted</Button>
                  </React.Fragment>
                )}
                {status === 2 && (
                  <React.Fragment>
                    <Button neutral>Declined</Button>
                  </React.Fragment>
                )}
              </Stack>
            ) : (
              <Stack
                direction="row"
                columnGap={2}
                mr={2}
                className={
                  status === 0
                    ? clsx(classes.responseButtons, 'slider')
                    : clsx(classes.responseButtons, 'float-right')
                }
              >
                {status === 0 && (
                  <React.Fragment>
                    <Button onClick={() => onResponse(false)} neutral>
                      Decline
                    </Button>
                    <Button onClick={() => onResponse(true)}>Accept</Button>
                  </React.Fragment>
                )}
                {status === 1 && (
                  <React.Fragment>
                    <Button>Accepted</Button>
                  </React.Fragment>
                )}
                {status === 2 && (
                  <React.Fragment>
                    <Button neutral>Declined</Button>
                  </React.Fragment>
                )}
              </Stack>
            )}
          </Stack>
        </PaperLayout>
      </Box>
    </a>
  );
};

export default ResponseItemBoxView;
