import React from 'react';
import { Box, Container, Divider, FormHelperText, Link } from '@mui/material';
import { MainLayout, PaperLayout } from 'components/layouts';
import {
  FormGroup,
  PasswordField,
  TextField,
  Typo,
} from 'components/primitives';
import { NextButton } from 'components/primitives/Button';
import { LoginProps, UserLogin } from './Login.props';
import useStyles from './Login.styles';

const LoginView: React.FC<LoginProps> = ({
  inProgress,
  errMessage,
  login,
  handleChange,
  handleLogin,
}: LoginProps) => {
  const classes = useStyles();
  const loginDisabled = !login.username || !login.password;
  const onFieldChange = (e: any) => {
    handleChange(e.target.name as keyof UserLogin, e.target.value);
  };
  const onLogin = () => handleLogin();
  const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') handleLogin();
  };

  return (
    <MainLayout
      footer={
        <Box className={classes.footer}>
          <Typo variant="body1" textAlign="center">
            All data in this app is to be considered FOUO and not for public
            dissemination
          </Typo>
        </Box>
      }
    >
      <React.Fragment>
        <Typo variant="h1" my={5} textAlign="center">
          Firefighter Mobilization
        </Typo>
        <Container maxWidth="xs">
          <PaperLayout elevation={3} sx={{ p: 3 }}>
            <Box>
              <FormGroup
                label="Username"
                color="textSecondary"
                fontWeight={700}
              >
                <TextField
                  name="username"
                  error={!!errMessage}
                  value={login?.username || ''}
                  onChange={onFieldChange}
                  onKeyPress={onKeyPress}
                />
              </FormGroup>
              <FormGroup
                label="Password"
                color="textSecondary"
                fontWeight={700}
                mt={3}
              >
                <PasswordField
                  name="password"
                  error={!!errMessage}
                  value={login?.password || ''}
                  onChange={onFieldChange}
                  onKeyPress={onKeyPress}
                />
              </FormGroup>
              {errMessage && (
                <FormHelperText sx={{ mt: 1, lineHeight: '12px' }} error>
                  {errMessage}
                </FormHelperText>
              )}
              <NextButton
                sx={{ mt: errMessage ? 2 : 3, mb: 3 }}
                disabled={loginDisabled}
                progress={inProgress}
                onClick={onLogin}
                fullWidth
              >
                Login
              </NextButton>
            </Box>
          </PaperLayout>
        </Container>
      </React.Fragment>
    </MainLayout>
  );
};

export default LoginView;
