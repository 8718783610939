import React from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import {
  AddCircleRounded,
  CloseOutlined as CloseIcon,
} from '@mui/icons-material';
import { Grid, Paper, Stack } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';
import { Button, FormGroup, TextField } from 'components/primitives';
import useStyle from './EmergencyPersonnel.styles';
import Select, { OnChangeValue } from 'react-select';
import { ISelectProp } from 'interfaces/FireStations';
import { credentialOptions } from 'common/options';

const EmergencyPersonnelView: React.FC<{ name: string } & BoxProps> = ({
  name,
  ...props
}) => {
  const classes = useStyle();
  const { control } = useFormContext();
  const personnelField = useFieldArray({ control, name });

  const onAddEntry = () => {
    personnelField.append({
      number: '',
      credentialsNeeded: '',
      notes: '',
    });
    setTimeout(() => {
      const fieldIndex = personnelField.fields.length || 0;
      const selectedEl = document.getElementById('personnel-' + fieldIndex);
      selectedEl?.scrollIntoView();
    }, 15);
  };

  const onRemoveEntry = (i: number) => {
    personnelField.remove(i);
  };

  const validateKeyPressNumber = (event: React.KeyboardEvent) => {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  };

  const handleSelect =
    (onChange: (value: string | undefined | null) => void) =>
    (newValue: OnChangeValue<ISelectProp, false>) => {
      onChange(newValue?.value);
    };

  const selectLabel = (value: string | undefined): ISelectProp | undefined => {
    if (value) {
      return credentialOptions.find((m: ISelectProp) => m.value === value);
    } else {
      return undefined;
    }
  };

  return (
    <Box {...props} height="100%">
      <Box className={classes.root}>
        <Box className={classes.personnelWrapper}>
          {personnelField.fields.map((item, index) => {
            return (
              <Paper
                id={`personnel-${index}`}
                key={item.id}
                elevation={0}
                className={classes.personnelItem}
                variant="outlined"
              >
                <Stack rowGap={2}>
                  <>
                    {index > 0 ? (
                      <Box className={classes.removeContainer}>
                        <CloseIcon
                          className={classes.iconClose}
                          onClick={() => onRemoveEntry(index)}
                        />
                      </Box>
                    ) : null}
                  </>
                  <Grid item xs={12} lg={12}>
                    <Controller
                      name={`${name}.${index}.number`}
                      control={control}
                      rules={{ required: true }}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <FormGroup label="Number" error={error}>
                          <TextField
                            value={value}
                            error={!!error}
                            onChange={onChange}
                            onKeyPress={(e: React.KeyboardEvent) =>
                              validateKeyPressNumber(e)
                            }
                            type="number"
                          />
                        </FormGroup>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <Controller
                      name={`${name}.${index}.credentialsNeeded`}
                      control={control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <FormGroup label="Credentials needed" error={error}>
                          <Select
                            options={credentialOptions}
                            value={selectLabel(value)}
                            onChange={handleSelect(onChange)}
                          />
                        </FormGroup>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <Controller
                      name={`${name}.${index}.notes`}
                      control={control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <FormGroup label="Additional notes" error={error}>
                          <TextField
                            value={value}
                            onChange={onChange}
                            multiline
                          />
                        </FormGroup>
                      )}
                    />
                  </Grid>
                </Stack>
              </Paper>
            );
          })}
        </Box>
        <Button
          endIcon={<AddCircleRounded />}
          className={classes.button}
          onClick={onAddEntry}
          alternate
          fullWidth
        >
          New Entry
        </Button>
      </Box>
    </Box>
  );
};

export default EmergencyPersonnelView;
