import { QueryState } from 'common/enums';
import { IRegion } from 'interfaces/Region';
import { RequestFormStep } from 'helpers/forms/requestForm';
import { StackableStep } from 'components/fragments/StackableSteps';
import PageState from '../PageState';

interface RequestPageState {
  state: QueryState;
  regions: IRegion[];
  steps: StackableStep[];
  newRequestNumber: number;
}

export const RequestPageEmptyState: RequestPageState = {
  regions: [],
  state: QueryState.UNDEFINED,
  newRequestNumber: 0,
  steps: [
    {
      step: RequestFormStep.REQUEST_DETAILS,
      title: 'Request details',
      active: true,
    },
    {
      step: RequestFormStep.PERSONNEL,
      title: 'Add personnel',
    },
    {
      step: RequestFormStep.EQUIPMENT,
      title: 'Add equipment',
    },
    {
      step: RequestFormStep.REGIONS,
      title: 'Select regions',
    },
    {
      step: RequestFormStep.SEND_REQUEST,
      title: 'Send request',
    },
  ],
};

export default RequestPageState;
