import { AxiosRequestConfig, AxiosRequestHeaders } from 'axios';

const baseURL =
  process.env.REACT_APP_NODE_ENV === undefined
    ? 'https://h7r3q2op84.execute-api.us-east-1.amazonaws.com/prod/public/api/ffmob/'
    : process.env.REACT_APP_PALMETTO_API;

const config: { headers: AxiosRequestHeaders } & AxiosRequestConfig = {
  baseURL,
  headers: {
    'Content-Type': 'application/json',
    // 'Access-Control-Allow-Origin': '*',
  },
  responseType: 'json',
};

export default config;
