import React from 'react';
import {
  Box,
  Stack,
  Grid,
  IconButton,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  Control,
  FieldValues,
  useFieldArray,
  Controller,
} from 'react-hook-form';
import {
  AddCircle as AddCircleIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';
import { OnChangeValue } from 'react-select';
import { ResourceType } from 'common/types';
import {
  Typo,
  ButtonSmall,
  TextField,
  SingleSelect,
} from 'components/primitives';
import HeaderTitlePersonnel from './HeaderTitlePersonnel';
import useStyles from './ProvidedResources.styles';
import { ISelectProp } from 'interfaces/FireStations';

const PersonnalHeader: React.FC<{
  v: any;
  name: ResourceType;
  index: number;
  control: Control<FieldValues, any>;
  stationOptions: ISelectProp[];
}> = ({ name, index, stationOptions, control, v }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const totalRequested = v?.requested || 0;
  const totalAccepted = v?.items?.filter((item: any) => item.accepted).length;
  const titleName = v?.name;
  const rootName = `${name}.${index}.items`;
  const personnalField = useFieldArray({
    control,
    name: rootName,
  });

  const onAddPersonnel = () => {
    personnalField.append({
      firstName: '',
      lastName: '',
      stationId: '',
      idNumber: '',
      rateOfPay: '',
      accepted: true,
    });
  };

  const onRemovePersonnel = (i: number) => {
    if (personnalField.fields.length > 0) personnalField.remove(i);
    else return null;
  };

  const selectNameLabel = (
    value: string | undefined
  ): ISelectProp | undefined => {
    if (value) {
      return stationOptions.find((m: ISelectProp) => m.value === value);
    } else {
      return undefined;
    }
  };

  const handleSelectChange =
    (onChange: (value: string | undefined | null) => void) =>
    (newValue: OnChangeValue<ISelectProp, false>) => {
      onChange(newValue?.value);
    };

  return (
    <React.Fragment key={index}>
      <Box className={classes.contentStackView}>
        {/* Headers */}
        <Stack
          direction="row"
          pb={1}
          rowGap={1}
          className={classes.headerGroup}
        >
          <Typo variant="h6" fontWeight={700}>
            {titleName?.length > 0 ? titleName : 'No Description'}
          </Typo>
          <Box className={classes.rightColGroup}>
            <Box className={classes.wrapCenter}>
              <Typo variant="h6" className={classes.title}>
                Requested:
              </Typo>
              <Typo variant="h5" className={classes.requestCount}>
                {totalRequested}
              </Typo>
            </Box>
            <Box className={classes.wrapCenter}>
              <Typo variant="h6" className={classes.title}>
                Accepted:
              </Typo>
              <Typo variant="h5" className={classes.acceptCount}>
                {totalAccepted}
              </Typo>
            </Box>
            {isMobile ? (
              <IconButton onClick={() => null}>
                <AddCircleIcon color="primary" />
              </IconButton>
            ) : (
              <ButtonSmall
                sx={{ marginLeft: '12px' }}
                startIcon={<AddCircleIcon />}
                onClick={onAddPersonnel}
              >
                <Typo fontWeight={700}>Add new</Typo>
              </ButtonSmall>
            )}
          </Box>
        </Stack>
        {/* Title Column Headers */}
        <HeaderTitlePersonnel />
        {/*  TextField Details */}
        {personnalField.fields?.map((_item: any, indx: number) => (
          <React.Fragment key={indx}>
            <Grid container spacing={1}>
              <Grid item xs={2}>
                <Controller
                  name={`${rootName}.${indx}.firstName`}
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      error={Boolean(error)}
                      placeholder="First Name"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={2}>
                <Controller
                  name={`${rootName}.${indx}.lastName`}
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      error={Boolean(error)}
                      placeholder="Last Name"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Controller
                  name={`${rootName}.${indx}.stationId`}
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <SingleSelect
                      error={Boolean(error)}
                      options={stationOptions}
                      value={selectNameLabel(value)}
                      onChange={handleSelectChange(onChange)}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={2}>
                <Controller
                  name={`${rootName}.${indx}.idNumber`}
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      error={Boolean(error)}
                      placeholder="ID Number"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={2}>
                <Controller
                  name={`${rootName}.${indx}.rateOfPay`}
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      error={Boolean(error)}
                      placeholder="Rate of pay"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={1}>
                <IconButton onClick={() => onRemovePersonnel(indx)}>
                  <DeleteIcon sx={{ color: 'common.black' }} />
                </IconButton>
              </Grid>
            </Grid>
          </React.Fragment>
        ))}
      </Box>
    </React.Fragment>
  );
};

export default PersonnalHeader;
