// noinspection JSIgnoredPromiseFromCall

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { QueryState } from 'common/enums';
import { AppState } from 'store/state/AppState';
import { tokenActions } from 'store/actions';
import useSelectorSafe from 'store/selectors/useSelectorSafe';
import {
  ProtectedPageProps,
  ProtectedPagePublicProps,
} from './ProtectedPage.props';
import ProtectedPageView from './ProtectedPage.view';
import { WebSocketContainer } from 'components/snippets';

const ProtectedPageContainer: React.FC<ProtectedPagePublicProps> = (
  ownProps: ProtectedPagePublicProps
) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const appState: any = useSelectorSafe((state: AppState) => state);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [openTooltip, setOpenTooltip] = useState<boolean>(false);
  const [userAvatar, setUserAvatar] = useState<string>('');
  const handleLogout = () => {
    localStorage.clear();
    dispatch(tokenActions.clear());
    navigate('/login');
  };
  const navigateTo = (path?: string) => {
    navigate(['dashboard', path].filter(Boolean).join('/'), { state: null });
  };

  const getUserCredentialsDetail = async () => {
    try {
      const urlPalmetto =
        'https://www.palmettoeoc.com/api/accounts/' +
        appState.token?.id +
        '?access_token=' +
        appState.token?.authorization;

      const userPalmetto = await axios
        .get(urlPalmetto)
        .then((res) => res?.data || null);

      if (userPalmetto) {
        console.log('<<< userPalmetto >>>');
        console.log(userPalmetto);
        const firstName = userPalmetto?.ncPersonGivenName || 'A';
        const lastName = userPalmetto?.ncPersonSurName || 'A';
        const initial = firstName?.charAt(0) + lastName?.charAt(0);
        setUserAvatar(initial);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (appState.token?.authorization) {
      getUserCredentialsDetail();
      if (location.pathname === '/') navigate('dashboard');
    } else {
      navigate('/login');
    }
  }, [appState.token]);

  if (appState.token) {
    const combinedProps: ProtectedPageProps = {
      ...ownProps,
      userAvatar,
      awaitState: appState?.page?.state === QueryState.AWAIT,
      openDrawer,
      openTooltip,
      token: appState.token,
      handleDrawer: (open: boolean) => setOpenDrawer(open),
      handleTooltip: (open: boolean) => setOpenTooltip(open),
      handleLogout,
      navigateTo,
    };
    return (
      <>
        <ProtectedPageView {...combinedProps} />
        <WebSocketContainer />
      </>
    );
  }
  return <Navigate to="login" />;
};

export default ProtectedPageContainer;
