const CredentialOptions = [
  'Aerial Operator',
  'Boat Operator',
  'Collapse Rescue Technician',
  'Communications Technician (ComT)',
  'Driver Operator',
  'EMT',
  'Firefighter 1',
  'Firefighter 2',
  'Fire Investigator',
  'Fire Marshal',
  'Fire Officer I',
  'Fire Officer II',
  'Hazmat Tech',
  'Incident Commander',
  'Interior Firefighter',
  'Paramedic',
  'Rope Rescue Tech',
  'Safety Officer',
  'Scuba Diver',
  'Strike Team Leader',
  'Swift Water Tech',
  'Tactical Dispatcher',
].sort();

export { CredentialOptions };
