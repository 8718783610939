import React, { FC, ReactElement } from 'react';
import { Box, Link } from '@mui/material';
import { Typo } from 'components/primitives';
import { MainLayout } from 'components/layouts';

const NotFound: React.FC = () => {
  return (
    <MainLayout>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="70vh"
      >
        <Typo fontSize="120px" fontWeight={400} color="primary">
          404
        </Typo>
        <Typo variant="h4">Oops! That page can’t be found.</Typo>
        <Typo variant="body1" style={{ marginTop: '2rem' }}>
          <Link href="/">Back to main site</Link>
        </Typo>
      </Box>
    </MainLayout>
  );
};
export default NotFound;
