// noinspection ExceptionCaughtLocallyJS

import * as AWS from 'aws-sdk';
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool,
  CognitoIdToken,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import { IUserPortal } from 'interfaces/userportal.interface';
import { getAccountFromIdToken } from 'common/utils/extractIdToken';
import { IidTokenSession } from 'interfaces/session.interface';

AWS.config.region = 'us-east-1';
const USER_POOL_ID = 'us-east-1_3f1efBpoo';
const USER_POOL_CLIENT_ID = '4i0bpro8bq9cusqkigt1j4gb9f';
const USER_POOL: CognitoUserPool = new CognitoUserPool({
  UserPoolId: USER_POOL_ID,
  ClientId: USER_POOL_CLIENT_ID,
  AdvancedSecurityDataCollectionFlag: true,
});
let COGNITO_USER: CognitoUser | null;

function SetNewCognitoUser(payload: {
  username: string;
  password: string;
}): Promise<CognitoUser | null> {
  return new Promise((res, rej) => {
    try {
      const currentUser = new CognitoUser({
        Username: payload.username,
        Pool: USER_POOL,
      });
      // !:NEED THIS TO TRIGGER USER MIGRATION
      currentUser.setAuthenticationFlowType('USER_PASSWORD_AUTH');
      currentUser.authenticateUser(
        new AuthenticationDetails({
          Username: payload.username,
          Password: payload.password,
          // ClientMetadata: {
          //   ActiveGroupID: payload.activeId ? payload.activeId.toString() : '0',
          // },
        }),
        {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          onSuccess(_session) {
            COGNITO_USER = currentUser;
            console.log('onSuccess currentUser >>>', currentUser);
            return res(currentUser);
          },
          onFailure(error) {
            //	? "code": "NotAuthorizedException"
            //	? "name": "NotAuthorizedException"
            //	? "message": "Incorrect username or password."
            //	? "code": "UserNotFoundException"
            //  ? "name": "UserNotFoundException"
            console.log('onFailure error >>>', error);
            let message = 'Un-authorize User Account';
            const listPropertyNames = Object.keys(error);
            // eslint-disable-next-line no-restricted-syntax
            for (const key in listPropertyNames) {
              if (key === 'NotAuthorizedException') {
                message = 'Incorrect username or password.';
              }
            }
            return rej(message);
          },
        }
      );
    } catch (error) {
      console.log(error);
    }
  });
}

function checkActiveSession(payload: {
  username: string;
  password: string;
  activeId?: string;
}): Promise<CognitoUser | null> {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (res, rej) => {
    try {
      let currentUser = USER_POOL && USER_POOL.getCurrentUser();
      if (currentUser !== null) {
        currentUser = await SetNewCognitoUser(payload);
        return res(currentUser);
      } else {
        currentUser = await SetNewCognitoUser(payload);
        return res(currentUser);
      }
    } catch (error) {
      console.log(error);
      rej(error);
    }
  });
}

export function refreshSession(): Promise<CognitoUserSession> {
  return new Promise((res, rej) => {
    try {
      const cognitoUserName = COGNITO_USER?.getUsername();
      const currentCognitoUser = USER_POOL && USER_POOL.getCurrentUser();
      const cognitoRefreshToken =
        COGNITO_USER && COGNITO_USER?.getSignInUserSession()?.getRefreshToken();
      if (currentCognitoUser !== null && cognitoRefreshToken) {
        console.log('Executing Cognito Refresh Session');
        currentCognitoUser.refreshSession(
          cognitoRefreshToken,
          (error, session) => {
            if (error) {
              rej(error);
            } else {
              res(session);
            }
          }
        );
      } else {
        if (cognitoUserName && USER_POOL && cognitoRefreshToken) {
          const cognitoUser = new CognitoUser({
            Username: cognitoUserName,
            Pool: USER_POOL,
          });
          cognitoUser.refreshSession(cognitoRefreshToken, (error, session) => {
            if (error) {
              return rej(error);
            } else {
              COGNITO_USER = cognitoUser;
              return res(session);
            }
          });
        } else {
          rej('No Active Cognito User');
        }
      }
    } catch (error) {
      console.log(error);
      rej(error);
    }
  });
}

const cognitoLogin = async (payload: {
  username: string;
  password: string;
}) => {
  try {
    console.log('Execute Cognito Login');
    const userCognitoAccount: CognitoUser | null = await checkActiveSession(
      payload
    )
      .then((session) => session)
      .catch((e) => {
        console.log('checkActiveSession error >>>', e);
        throw e;
      });

    if (userCognitoAccount) {
      let idToken: CognitoIdToken | undefined;
      if (userCognitoAccount?.getSignInUserSession()) {
        idToken = userCognitoAccount?.getSignInUserSession()?.getIdToken();
      }

      if (!idToken) throw Error('No Id Token');
      console.log('🚀~file:cognitoThunk.ts:316 ~ idToken: ', idToken);
      const tokenSession: Partial<IidTokenSession> = idToken;
      const jwtToken = idToken.getJwtToken();
      let accountInfo: Partial<IUserPortal> = getAccountFromIdToken(idToken);
      accountInfo = JSON.parse(accountInfo as string);
      console.log('PARSE accountInfo >>>', accountInfo);
      return {
        jwtToken,
        accountInfo,
        cognitoUser: tokenSession,
        activeGroupId: accountInfo.pvActiveGroupID,
      };
    }

    throw Error('Cognito Login Error');
  } catch (error) {
    console.log('cognitoLogin:', error);
  }
};

export { cognitoLogin };
