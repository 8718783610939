import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      minHeight: '400px',
    },
    links: {
      display: 'flex',
      justifyContent: 'space-between',
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(12),
    },
    footer: {
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      left: 0,
      bottom: 0,
      padding: `0 ${theme.spacing(3)}`,
      width: '100%',
      height: '58px',
      backgroundColor: theme.palette.common.white,
    },
  };
});

export default useStyles;
