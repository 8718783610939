import React from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import {
  AddCircleRounded,
  CloseOutlined as CloseIcon,
} from '@mui/icons-material';
import { Paper, Stack } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';
import { Button, FormGroup, TextField } from 'components/primitives';
import useStyle from './EmergencyEquipment.styles';
import Select, { OnChangeValue } from 'react-select';
import { ISelectProp } from 'interfaces/FireStations';
import { equipmentOptions } from 'common/options';

const EmergencyEquipmentView: React.FC<{ name: string } & BoxProps> = ({
  name,
  ...props
}) => {
  const classes = useStyle();
  const { control } = useFormContext();
  const equipmentField = useFieldArray({
    control,
    name,
  });
  const onAddEntry = () => {
    equipmentField.append({
      number: '',
      credentialsNeeded: '',
      notes: '',
    });
    setTimeout(() => {
      const fieldIndex = equipmentField.fields.length || 0;
      const selectedEl = document.getElementById('equipment-' + fieldIndex);
      selectedEl?.scrollIntoView();
    }, 15);
  };
  const onRemoveEntry = (i: number) => {
    equipmentField.remove(i);
  };
  const validateKeyPressNumber = (event: React.KeyboardEvent) => {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  };
  const handleSelect =
    (onChange: (value: string | undefined | null) => void) =>
    (newValue: OnChangeValue<ISelectProp, false>) => {
      onChange(newValue?.value);
    };
  const selectLabel = (value: string | undefined): ISelectProp | undefined => {
    if (value) {
      return equipmentOptions.find((m: ISelectProp) => m.value === value);
    } else {
      return undefined;
    }
  };
  return (
    <Box {...props} height="100%">
      <Box className={classes.root}>
        <Box className={classes.equipmentWrapper}>
          {equipmentField.fields.map((item, index) => (
            <Paper
              id={`equipment-${index}`}
              key={item.id}
              elevation={0}
              className={classes.equipmentItem}
              variant="outlined"
            >
              <Stack rowGap={2}>
                <>
                  {index > 0 ? (
                    <Box className={classes.removeContainer}>
                      <CloseIcon
                        className={classes.iconClose}
                        onClick={() => onRemoveEntry(index)}
                      />
                    </Box>
                  ) : null}
                </>
                <Controller
                  name={`${name}.${index}.number`}
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <FormGroup label="Number" error={error}>
                      <TextField
                        value={value}
                        error={!!error}
                        onChange={onChange}
                        onKeyPress={(e: React.KeyboardEvent) =>
                          validateKeyPressNumber(e)
                        }
                        type="number"
                      />
                    </FormGroup>
                  )}
                />
                <Controller
                  name={`${name}.${index}.description`}
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <FormGroup label="Vehicle Description" error={error}>
                      <Select
                        options={equipmentOptions}
                        value={selectLabel(value)}
                        onChange={handleSelect(onChange)}
                      />
                    </FormGroup>
                  )}
                />
                <Controller
                  name={`${name}.${index}.code`}
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <FormGroup label="Special Capabilities" error={error}>
                      <TextField
                        value={value}
                        error={!!error}
                        onChange={onChange}
                      />
                    </FormGroup>
                  )}
                />
              </Stack>
            </Paper>
          ))}
        </Box>
        <Button
          endIcon={<AddCircleRounded />}
          className={classes.button}
          onClick={onAddEntry}
          alternate
          fullWidth
        >
          New Entry
        </Button>
      </Box>
    </Box>
  );
};

export default EmergencyEquipmentView;
