import React, { useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment } from '@mui/material';
import OutlinedInput, { OutlinedInputProps } from '@mui/material/OutlinedInput';

const PasswordField: React.FC<OutlinedInputProps> = (
  props: OutlinedInputProps
) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const toggleVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <OutlinedInput
      {...props}
      type={showPassword ? 'text' : 'password'}
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            edge="end"
            onClick={toggleVisibility}
            onMouseDown={toggleVisibility}
          >
            {showPassword ? (
              <Visibility fontSize="small" />
            ) : (
              <VisibilityOff fontSize="small" />
            )}
          </IconButton>
        </InputAdornment>
      }
      fullWidth
    />
  );
};

export default PasswordField;
