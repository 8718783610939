import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {},
    searchBox: {
      padding: theme.spacing(2),
      borderRadius: '8px',
      backgroundColor: theme.palette.grey[200],
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(2),
        padding: theme.spacing(1),
      },
    },
    listItemView: {
      marginRight: '65px',
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
    },
    listView: {
      width: '100%',
      borderRadius: '5px',
      backgroundColor: theme.palette.background.paper,
    },
    listItem: {
      padding: theme.spacing(2),
      borderBottom: `solid 1px ${theme.palette.grey[200]}`,
      [theme.breakpoints.down('md')]: {
        padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
      },
    },
    greyText: {
      color: theme.palette.grey[900],
    },
    iconCheckout: {
      marginTop: theme.spacing(0.2),
      fontSize: '16px',
      color: theme.palette.grey[800],
    },
    iconButton: {
      borderRadius: '5px',
      backgroundColor: theme.palette.grey[50],
      color: theme.palette.grey[800],
    },
    menuItemPlaceholder: {
      display: 'flex',
      alignItems: 'center',
    },
    pagination: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: theme.spacing(2),
    },
    selectMenu: {
      '& .MuiSelect-select': {
        display: 'flex',
        alignItems: 'center',
      },
    },
  };
});

export default useStyles;
