import React, { useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CreatableSelect from 'react-select/creatable';
import Select, { SingleValue } from 'react-select';
import {
  AddBox as AddBoxIcon,
  ChevronRight as ChevronRightIcon,
  LibraryAddCheckOutlined as LibraryAddCheckOutlinedIcon,
} from '@mui/icons-material';
import {
  Box,
  Container,
  IconButton,
  Grid,
  List,
  ListItem,
  Stack,
  Pagination,
} from '@mui/material';
import {
  FormGroup,
  TitleText,
  TextField,
  Button,
  Typo,
  Modal,
} from 'components/primitives';
import { PreLoaderSnippet } from 'components/snippets';
import { FireStationInfoForm } from 'components/fragments';
import useStyles from './FireStations.styles';
import { FireStationsProps } from './FireStations.props';
import { payloadFireStationInfo } from 'helpers/forms/firestationForm';
import apiFetch, { postFireStation } from 'services/apiFetch';
import { ISelectProp } from 'interfaces/FireStations';

const FireStationsView: React.FC<FireStationsProps> = (
  props: FireStationsProps
) => {
  const location = useLocation();
  const navigate = useNavigate();
  const classes = useStyles();
  const [awaitSave, setAwaitSave] = useState<boolean>(false);
  const selectRef = useRef<any>(null);
  const disableCity = props.selectCounty === '' ? true : false;

  const onNavigateToFireStation = (num: number) => {
    navigate(`${location?.pathname}/${num}`);
  };

  const handleModalAction = async (accept: boolean, payload: any) => {
    if (accept === false) return props.handleModal(false);
    if (accept) {
      setAwaitSave(true);
      // props.handleModal(false);
      const body: payloadFireStationInfo = {
        details: {
          ...payload,
          zip: '',
        },
      };

      const res = await apiFetch(postFireStation(body)).then(
        (res) => res.data || null
      );

      if (res) {
        setAwaitSave(false);
        props.handleRefreshList();
        props.handleModal(false);
      }
    }
  };

  const getRef = (ref: any) => {
    if (ref && ref?.current) {
      ref?.current.clearValue();
    }
  };

  const handleCountyChange = (newValue: SingleValue<ISelectProp>) => {
    const countyValue = (newValue && newValue.value) || '';
    props.handleChangeCounty(countyValue);
    getRef(selectRef);
  };

  const handleCityChange = (newValue: SingleValue<ISelectProp>) => {
    const cityValue = (newValue && newValue.value) || '';
    props.handleChangeCity(cityValue);
  };

  return (
    <React.Fragment>
      <Container>
        <TitleText title="Fire Stations" opposite>
          <Button
            startIcon={<AddBoxIcon />}
            sx={{ minWidth: '140px' }}
            // onClick={() => props.handleModal(true)}
            onClick={() => navigate(`${location?.pathname}/new`)}
          >
            Add new
          </Button>
        </TitleText>

        {/* Search box */}
        <Box className={classes.searchBox}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={4}>
              <FormGroup label="Filter by name">
                <TextField
                  placeholder="Enter a keyword"
                  value={props.keyword}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    props.handleChangeKeyword(event)
                  }
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormGroup label="County">
                <Select
                  isClearable
                  onChange={handleCountyChange}
                  options={props.countyOption}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormGroup label="City">
                <Select
                  isDisabled={disableCity}
                  onChange={handleCityChange}
                  options={props.cityArr}
                  ref={selectRef}
                />
              </FormGroup>
            </Grid>
          </Grid>
        </Box>
        <PreLoaderSnippet
          visible={props.awaitRequest}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '300px',
          }}
        />
        {/* Fire stations list */}
        <List className={classes.listView} sx={{ mt: 2 }} disablePadding>
          {props.fireStations &&
            props.fireStations.map((f) => {
              return (
                <React.Fragment key={f.stationId}>
                  <ListItem
                    className={classes.listItem}
                    secondaryAction={
                      <IconButton
                        className={classes.iconButton}
                        onClick={() => onNavigateToFireStation(f.stationId)}
                      >
                        <ChevronRightIcon />
                      </IconButton>
                    }
                    disablePadding
                  >
                    <Box className={classes.listItemView}>
                      <Typo variant="h6">{f.name}</Typo>
                      <Stack direction="row" columnGap={1}>
                        <Stack
                          sx={{ flexDirection: { xs: 'column', md: 'row' } }}
                        >
                          <Stack direction="row" mr={1.5}>
                            <Typo
                              variant="h6"
                              fontWeight={400}
                              className={classes.greyText}
                            >
                              {f.city}
                            </Typo>
                            <Typo
                              ml={1}
                              variant="h6"
                              fontWeight={400}
                              className={classes.greyText}
                            >
                              {f.state ? f.state : 'SC'}
                            </Typo>
                          </Stack>
                          <Stack direction="row" columnGap={1}>
                            <Typo
                              variant="h6"
                              fontWeight={400}
                              color="textSecondary"
                            >
                              Contacts
                            </Typo>
                            <LibraryAddCheckOutlinedIcon
                              className={classes.iconCheckout}
                              fontSize="small"
                            />
                          </Stack>
                        </Stack>
                      </Stack>
                    </Box>
                  </ListItem>
                </React.Fragment>
              );
            })}
        </List>
        {!props.awaitRequest && props.pageTotal > 1 && (
          <Pagination
            className={classes.pagination}
            count={props.pageTotal}
            page={props.page}
            shape="rounded"
            onChange={(e, num) => props.handlePageChange(num)}
          />
        )}
      </Container>
      <Modal open={props.modalStatus}>
        <FireStationInfoForm
          onAccept={(x: boolean, payload: any) => handleModalAction(x, payload)}
          awaitSave={awaitSave}
        />
      </Modal>
    </React.Fragment>
  );
};

export default FireStationsView;
