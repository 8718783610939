import { FireStationActionTypes as actionTypes } from '../types/FireStation.actionTypes';
import { ActionsFireStationProp } from 'interfaces/FireStations';
const fetchData = () => ({
  type: actionTypes.FIRESTATION_FETCH,
});

const responseStation = (payload: any) => ({
  type: actionTypes.FIRESTATION_LOAD_STATIONS,
  payload,
});

const responseFSList = (payload: any) => ({
  type: actionTypes.FIRESTATION_LOAD_FS_LIST,
  payload,
});

const responseCounties = (payload: any) => ({
  type: actionTypes.FIRESTATION_LOAD_COUNTIES,
  payload,
});

const responseCities = (payload: any) => ({
  type: actionTypes.FIRESTATION_LOAD_CITIES,
  payload,
});

const responseContacts = (payload: any) => ({
  type: actionTypes.FIRESTATION_LOAD_CONTACTS,
  payload,
});

const responseError = (payload: string) => ({
  type: actionTypes.FIRESTATION_ERROR,
  payload,
});

const updateQueryStatus = (payload: ActionsFireStationProp) => ({
  type: actionTypes.FIRESTATION_QUERYSTATUS,
  payload,
});

const fetchFireStationPage = (payload: any) => ({
  type: actionTypes.FIRESTATION_FETCHPAGE,
  payload,
});

const responseFireStationById = (payload: any) => ({
  type: actionTypes.FIRESTATION_LOAD_STATION,
  payload,
});

const resetFireStationState = () => ({
  type: actionTypes.FIRESTATION_RESET,
});

export default {
  fetchData,
  responseStation,
  responseCounties,
  responseCities,
  responseContacts,
  responseError,
  responseFSList,
  updateQueryStatus,
  fetchFireStationPage,
  responseFireStationById,
  resetFireStationState,
};
