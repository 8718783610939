import { SocketActionTypes as actionTypes } from '../types/Socket.actionTypes';

const requestReceived = (payload: any) => ({
  type: actionTypes.SOCKET_REQUEST,
  payload,
});

const responseReceived = (payload: any) => ({
  type: actionTypes.SOCKET_RESPONSE,
  payload,
});

const notificationReceived = (payload: any) => ({
  type: actionTypes.SOCKET_NOTIFICATION,
  payload,
});

export default {
  requestReceived,
  responseReceived,
  notificationReceived,
};
