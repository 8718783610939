// noinspection JSUnusedLocalSymbols

import React, { useState } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import moment from 'moment';
import { sumBy } from 'lodash';
import { Box, Container, Fade, Pagination, Stack } from '@mui/material';
import {
  IEmergencyResponse,
  IEmergencyRequest,
  ISize,
} from 'common/interfaces';
import { Modal, TitleText, Typo } from 'components/primitives';
import {
  AcceptResources,
  DismissOfferResources,
  RequestItemBox,
} from 'components/fragments';
import {
  EmptyEmergencySnippet,
  ResponseCardSnippet,
  RequestResourceSnippet,
  PreLoaderSnippet,
} from 'components/snippets';
import { DashboardMainModal, DashboardMainProps } from './DashboardMain.props';
import apiFetch, {
  updateEmergencyResponse,
  updateEmergencyRequest,
} from 'services/apiFetch';
import useStyles from './DashboardMain.styles';
import useWindowSize from 'common/hook/useWindowSize';

const emailTemplate =
  'Attention Chief and/or designee, thank you for your support and offer ' +
  'for the requested Firefighter Mobilization response. At this time, the response is full. ' +
  'Please continue to monitor for further requests as this incident continues to expand. ' +
  'Thank you for your continued support.';

const DashboardMainView: React.FC<DashboardMainProps> = ({
  awaitRequest,
  emergencies,
  page,
  pageLimit,
  pageTotal,
  responses,
  initialPageTotal,
  ...props
}: DashboardMainProps) => {
  const windowSize: ISize = useWindowSize();
  const classes = useStyles(windowSize);
  const modalFocus = props.modalFocus;
  const isEmpty = !awaitRequest && emergencies.length === 0;
  const filterResponse = responses.filter((i: any) => i.details.status === 0);
  const countResponses = filterResponse.length || 0;
  const [emailMsg, setEmailMsg] = useState<string>(emailTemplate);

  const onPerformResponse = (
    accept: boolean,
    payload: IEmergencyResponse,
    requestPayload: IEmergencyRequest
  ) => {
    props.handleModalFocus({
      modal: accept ? DashboardMainModal.ACCEPT : DashboardMainModal.DECLINE,
      payload,
      requestPayload,
    });
  };

  const onAcceptResources = async (
    ok: boolean,
    payload: any,
    requestPayload: any,
    status: DashboardMainModal
  ) => {
    if (ok) {
      const details = payload?.details;
      const responsePayload = {
        ...payload,
        details: {
          ...details,
          status,
        },
      };
      const reqDetails = requestPayload?.details;
      if (!requestPayload) return;
      const reqPayload = {
        ...requestPayload,
        details: {
          ...reqDetails,
          status,
          equipment: details.equipment,
          personnel: details.personnel,
        },
        responseId: responsePayload.responseId,
      };

      await apiFetch(updateEmergencyRequest(reqPayload))
        .then(() => {
          apiFetch(
            updateEmergencyResponse(responsePayload, {
              responseId: payload.responseId,
              authorize: true,
            })
          );
        })
        .catch((error) => console.log(error))
        .finally(() => {
          props.handleModalFocus({ modal: DashboardMainModal.UNDEFINED });
          props.refreshResponseData();
        });
    } else {
      props.handleModalFocus({ modal: DashboardMainModal.UNDEFINED });
    }
  };

  const onDeclineResources = async (
    ok: boolean,
    payload: any,
    requestPayload: any,
    status: DashboardMainModal
  ) => {
    if (ok) {
      const details = payload.details;
      const responsePayload = {
        ...payload,
        details: {
          ...details,
          status,
          declineMessage: emailMsg,
        },
      };

      const reqDetails = requestPayload.details;
      const reqPayload = {
        ...requestPayload,
        details: {
          ...reqDetails,
          status,
          equipment: details.equipment,
          personnel: details.personnel,
          declineMessage: emailMsg,
        },
      };

      await apiFetch(
        updateEmergencyResponse(responsePayload, {
          responseId: payload.responseId,
          authorize: true,
        })
      )
        .then(({ data, status }) => {
          // Todo: Do something after update response decline/accept
          console.log('Update Response Status:', status);
          apiFetch(updateEmergencyRequest(reqPayload));
        })
        .catch((error) => console.log(error))
        .finally(() => {
          props.handleModalFocus({ modal: DashboardMainModal.UNDEFINED });
          props.refreshResponseData();
        });
    } else {
      setEmailMsg(emailTemplate);
      props.handleModalFocus({ modal: DashboardMainModal.UNDEFINED });
    }
  };

  const changeTextEmailBody = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailMsg(e.target.value);
  };

  return (
    <React.Fragment>
      {countResponses > 0 && (
        <Container>
          <TitleText title="Latest offers">
            <Box className={classes.responseBadge}>
              <Typo variant="h4">{countResponses}</Typo>
            </Box>
          </TitleText>
        </Container>
      )}
      <Fade in={countResponses > 0}>
        <Box style={{ height: 'auto', width: '100%' }}>
          <Box className={classes.responseContainer}>
            <ScrollContainer className={classes.responseList} horizontal>
              {filterResponse.map((r) => (
                <ResponseCardSnippet
                  key={r.responseId}
                  sx={{ mb: 1 }}
                  className={classes.responseItem}
                  emergencyResponse={r}
                  onAccept={(x: boolean, y: any) => onPerformResponse(x, r, y)}
                />
              ))}
            </ScrollContainer>
          </Box>
        </Box>
      </Fade>
      <Container component="main">
        <TitleText title="Incidents">
          <Box className={classes.responseBadge}>
            <Typo variant="h4">{initialPageTotal}</Typo>
          </Box>
        </TitleText>
        <PreLoaderSnippet
          visible={awaitRequest}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '300px',
          }}
        />
        <Fade in={!awaitRequest && isEmpty}>
          <Box>
            {isEmpty && (
              <EmptyEmergencySnippet onCreate={props.navigateToNewEmergency} />
            )}
          </Box>
        </Fade>
        <Fade in={!awaitRequest}>
          <Box>
            <Stack spacing={1}>
              {emergencies.map((e: any) => {
                const createdAt = moment(e.createDate).format('L');
                const regions = e?.details.regions?.length || 0;
                const counties = e?.details.regions.reduce(
                  (sum: number, r: any) => sum + r.counties?.length,
                  0
                );
                const { equipment, personnel } = e.details || {};
                const combinePersonnel = e.responseList.flatMap((p: any) => {
                  const pa = [];
                  pa.push(p.details.personnel.flatMap((f: any) => f?.items));
                  const acceptP = pa.flatMap((a: any) => a);
                  return acceptP.filter((a: any) => a.accepted);
                });
                const combineEquipment = e.responseList.flatMap((e: any) => {
                  const eq = [];
                  eq.push(e.details.equipment.flatMap((f: any) => f?.items));
                  const acceptE = eq.flatMap((a: any) => a);
                  return acceptE.filter((a: any) => a.accepted);
                });
                const ttlApprovePersonnel = combinePersonnel.length || 0;
                const ttlApproveEquipment = combineEquipment.length || 0;

                const reqPersonnel = sumBy(personnel, (value: any) =>
                  parseInt(value.number)
                );
                const reqEquipment = sumBy(equipment, (value: any) =>
                  parseInt(value.number || 0)
                );
                const responseCount =
                  e?.responseCount > 0 ? e?.responseCount : '0';

                return (
                  <RequestItemBox
                    key={e.requestId}
                    name={`${e?.details?.eventName} Request #${e.requestId}`}
                    emergencyData={e}
                    meta={[
                      { label: 'Created on', value: createdAt },
                      {
                        label: 'Sent to',
                        value: `${regions} Regions / ${counties} Counties`,
                      },
                      { label: 'Responses', value: responseCount },
                    ]}
                    sx={{ cursor: 'pointer' }}
                    onClick={() => props.navigateToEmergency(e)}
                  >
                    <RequestResourceSnippet
                      title="Personnel"
                      meta={[
                        {
                          label: 'Requested',
                          value: reqPersonnel,
                        },
                        { label: 'Accepted', value: ttlApprovePersonnel },
                      ]}
                    />
                    <RequestResourceSnippet
                      title="Equipment"
                      meta={[
                        { label: 'Requested', value: reqEquipment },
                        { label: 'Accepted', value: ttlApproveEquipment },
                      ]}
                    />
                  </RequestItemBox>
                );
              })}
            </Stack>
          </Box>
        </Fade>
        {!awaitRequest && pageTotal > 1 && (
          <Pagination
            className={classes.pagination}
            count={pageTotal}
            page={page}
            shape="rounded"
            onChange={(e, num) => props.handlePageChange(num)}
          />
        )}
      </Container>

      <Modal open={modalFocus.modal === DashboardMainModal.DECLINE}>
        <DismissOfferResources
          onOk={(e, ok) =>
            onDeclineResources(
              ok,
              modalFocus?.payload,
              modalFocus?.requestPayload,
              DashboardMainModal.DECLINE
            )
          }
          emailMsg={emailMsg}
          changeTextEmailBody={changeTextEmailBody}
        />
      </Modal>

      <Modal open={modalFocus.modal === DashboardMainModal.ACCEPT}>
        <AcceptResources
          emergencyResponse={modalFocus?.payload}
          onAccept={(x: boolean, payload: any) =>
            onAcceptResources(
              x,
              payload,
              modalFocus?.requestPayload,
              DashboardMainModal.ACCEPT
            )
          }
        />
      </Modal>
    </React.Fragment>
  );
};

export default DashboardMainView;
