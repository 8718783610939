/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { handleActions } from 'redux-actions';
import { presetReducers } from 'store/utils/Redux';
import PageState from 'store/state/PageState';
import { pageActions } from '../actions';

const PageEmptyState: PageState<any> = { key: null };

const reducer = handleActions(
  {
    [String(pageActions.identify)]: (state, action) => action.payload,
    [String(pageActions.update)]: presetReducers.makeMerge(),
    [String(pageActions.clear)]: () => PageEmptyState,
  },
  PageEmptyState
);

export default reducer;
