import React, { useRef } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  AddCircleRounded as AddCircleRoundedIcon,
  AccountCircle as AccountCircleIcon,
  Apartment as ApartmentIcon,
  Archive as ArchiveIcon,
  Dashboard as DashboardIcon,
  Logout as LogoutIcon,
  Menu as MenuIcon,
} from '@mui/icons-material';
import {
  Avatar,
  Box,
  IconButton,
  LinearProgress,
  ListItemIcon,
  Menu,
  MenuItem,
  Toolbar,
} from '@mui/material';
import { ProtectedPageProps } from './ProtectedPage.props';
import { MainLayout } from 'components/layouts';
import { Button, Typo } from 'components/primitives';
import DrawerMenu, { DrawerMenuItem } from 'components/primitives/DrawerMenu';
import useStyles from './ProtectedPage.styles';
import clsx from 'clsx';

const MainMenuItems: DrawerMenuItem[] = [
  {
    name: 'dashboard',
    title: 'Dashboard',
    path: '/dashboard',
    icon: <DashboardIcon fontSize="small" />,
  },
  {
    name: 'archive',
    title: 'Archive',
    path: '/dashboard/archive',
    icon: <ArchiveIcon fontSize="small" />,
  },
  {
    name: 'fire-stations',
    title: 'Fire Stations',
    path: '/dashboard/fire-stations',
    icon: <ApartmentIcon fontSize="small" />,
  },
];

const ProtectedPageView: React.FC<ProtectedPageProps> = ({
  awaitState,
  openDrawer,
  ...props
}: ProtectedPageProps) => {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const classes = useStyles();
  const avatarRef = useRef(null);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const onCreateEmergency = () => props.navigateTo('emergencies/new');
  const onShowProfile = () => props.navigateTo('profile');
  const onToggleTooltip = () => props.handleTooltip(!props.openTooltip);
  const onToggleSlideMenu = () => props.handleDrawer(!openDrawer);
  const onLogout = () => props.handleLogout();
  const onMenuClick = (menu: DrawerMenuItem) => {
    if (menu.path) navigate(menu.path);
    onToggleSlideMenu();
  };

  return (
    <React.Fragment>
      <MainLayout
        toolbar={
          <React.Fragment>
            {awaitState && <LinearProgress />}
            <Toolbar className={classes.toolbar}>
              <Box flex="1">
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 2 }}
                  onClick={onToggleSlideMenu}
                >
                  <MenuIcon />
                </IconButton>
                <DrawerMenu
                  activePathname={location?.pathname}
                  open={openDrawer}
                  items={MainMenuItems}
                  onMenuClick={onMenuClick}
                  onClose={onToggleSlideMenu}
                />
              </Box>
              {isMobile ? (
                <Button
                  className={clsx(classes.createButton, classes.iconButton)}
                  onClick={onCreateEmergency}
                  alternate
                >
                  <AddCircleRoundedIcon fontSize="medium" />
                </Button>
              ) : (
                <Button
                  className={classes.createButton}
                  startIcon={<AddCircleRoundedIcon fontSize="large" />}
                  onClick={onCreateEmergency}
                  alternate
                >
                  Create incident
                </Button>
              )}
              <IconButton
                aria-controls={props.openTooltip ? 'account-menu' : undefined}
                aria-expanded={props.openTooltip ? 'true' : undefined}
                aria-haspopup="true"
                size="small"
                onClick={onToggleTooltip}
              >
                <Avatar ref={avatarRef} className={classes.avatar}>
                  <Typo variant="h5">{props.userAvatar}</Typo>
                </Avatar>
              </IconButton>
            </Toolbar>
          </React.Fragment>
        }
      >
        {/* Adding key will force refresh the child component on navigate */}
        <Outlet key={location.key} />
      </MainLayout>
      <Menu
        id="account-menu"
        anchorEl={avatarRef?.current}
        open={props.openTooltip}
        onClose={onToggleTooltip}
        onClick={onToggleTooltip}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            minWidth: 180,
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {/* <MenuItem onClick={onShowProfile}>
          <ListItemIcon>
            <AccountCircleIcon fontSize="small" color="primary" />
          </ListItemIcon>
          <Typo variant="h6" color="common.black">
            Account
          </Typo>
        </MenuItem> */}
        <MenuItem onClick={onLogout}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" color="primary" />
          </ListItemIcon>
          <Typo variant="h6" color="common.black">
            Logout
          </Typo>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

export default ProtectedPageView;
