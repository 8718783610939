/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useState, useRef } from 'react';
import { FormProvider, Control } from 'react-hook-form';
import { flatMap, some } from 'lodash';
import { withStyles } from '@mui/styles';
import {
  Box,
  Stack,
  Divider,
  Tabs as MuiTabs,
  Tab as MuiTab,
  useMediaQuery,
  useTheme,
  List,
  ListItem,
  IconButton,
} from '@mui/material';
import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material';
import { IEmergencyResponseResource } from 'common/interfaces';
import { responseIdFormat } from 'common/utils/String';
import { Button, Typo } from 'components/primitives';
import { ResourceTabSnippet } from 'components/snippets';
import { AcceptResourcesProps } from './AcceptResources.props';
import useStyles, { TabsStyles, TabItemStyles } from './AcceptResources.styles';
import ResourceTabPanel from '../ResourceTabPanel';

const Tabs = withStyles(TabsStyles)(MuiTabs);
const TabItem = withStyles(TabItemStyles)(MuiTab);

const hasResourceAccepted = (arr: IEmergencyResponseResource[]) => {
  return some(flatMap(arr.map((r: IEmergencyResponseResource) => r.items)), {
    accepted: true,
  });
};

const AcceptResourcesView: React.FC<AcceptResourcesProps> = ({
  emergencyResponse,
  form,
  tabIndex,
  ...props
}: AcceptResourcesProps) => {
  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { getValues } = form;
  const values = getValues();
  const [openPContent, setOpenPContent] = useState<boolean>(false);
  const [openEContent, setOpenEContent] = useState<boolean>(false);
  const { responseId, details } = emergencyResponse || {};
  const { equipment, personnel } = details || {};
  const [hasEquipmentT, setHasEquipmentT] = useState<boolean>(false);
  const hasEquipment = hasResourceAccepted(values.equipment);
  const hasPersonnel = hasResourceAccepted(values.personnel);
  const isAccepted = details?.status === 1;
  const parentPRef = useRef<HTMLDivElement>();
  const parentERef = useRef<HTMLDivElement>();
  // TODO: this will require additional api data
  // const address = [response?.city, response?.state].filter(Boolean).join(', ');
  // const distance = response?.distance ? `${response?.distance} mi` : '';
  // const region = response?.region ? `Region ${response?.region}` : '';

  const resourceTabs = [
    {
      name: 'Personnel',
      resource: personnel || [],
    },
    {
      name: 'Equipment',
      resource: equipment || [],
    },
  ];

  const onAcceptAll = (name: string, accept: boolean) => {
    let formValue = values;
    const defVal = values[name].map((r: IEmergencyResponseResource) => ({
      ...r,
      items: r.items.map((item: any) => ({ ...item, accepted: accept })),
    }));
    if (name === 'equipment') {
      formValue = {
        ...values,
        equipment: defVal,
      };
      form.reset(formValue);
    }
    if (name === 'personnel') {
      formValue = {
        ...values,
        personnel: defVal,
      };
      form.reset(formValue);
    }
  };

  const handleCheck = () => {
    setHasEquipmentT(!hasEquipmentT);
  };

  const handleShowContent = (name: string) => {
    if (name === 'personnel') {
      setOpenPContent(!openPContent);
    }
    if (name === 'equipment') {
      setOpenEContent(!openEContent);
    }
  };

  const ListViewContainer = ({ control }: { control: Control }) => {
    return (
      <List
        sx={{ width: '100%', maxWidth: 600 }}
        aria-labelledby="nested-list-subheader"
      >
        <ListItem
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingLeft: 0,
            paddingRight: 0,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <ResourceTabSnippet
              name="Personnel"
              control={control}
              onCheckAll={onAcceptAll}
            />
          </Box>
          <IconButton onClick={() => handleShowContent('personnel')}>
            {openPContent ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </ListItem>
        <Stack
          className={classes.dropDownContainer}
          ref={parentPRef}
          style={
            openPContent
              ? { height: parentPRef?.current?.scrollHeight + 'px' }
              : { height: '0px' }
          }
        >
          <FormProvider {...form}>
            <Box className={classes.tabContent}>
              <ResourceTabPanel
                active={true}
                name="personnel"
                handleClick={handleCheck}
              />
            </Box>
          </FormProvider>
        </Stack>
        <ListItem
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingLeft: 0,
            paddingRight: 0,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <ResourceTabSnippet
              name="Equipment"
              control={control}
              onCheckAll={onAcceptAll}
            />
          </Box>
          <IconButton onClick={() => handleShowContent('equipment')}>
            {openEContent ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </ListItem>
        <Stack
          className={classes.dropDownContainer}
          ref={parentERef}
          style={
            openEContent
              ? { height: parentERef?.current?.scrollHeight + 'px' }
              : { height: '0px' }
          }
        >
          <FormProvider {...form}>
            <Box className={classes.tabContent}>
              <ResourceTabPanel
                active={true}
                name="equipment"
                handleClick={handleCheck}
              />
            </Box>
          </FormProvider>
        </Stack>
      </List>
    );
  };

  return (
    <Box className={classes.content}>
      <Box className={classes.titleView}>
        <Typo variant="h4">Accept resources</Typo>
        <Typo variant="body2">Please select the needed resources</Typo>
      </Box>
      <Divider sx={{ mt: 1, mb: 2 }} />

      <Box className={classes.grayBox}>
        <Typo variant="h6">Response #{responseIdFormat(responseId || '')}</Typo>
        {/* <Box>
          <Typo variant="body2">{region}</Typo>
          <Typo variant="body2" fontWeight={600} sx={{ mx: 1 }}>
            {address}
          </Typo>
          <Typo variant="body2">{distance}</Typo>
        </Box> */}
      </Box>

      {isMobile && <ListViewContainer control={form.control} />}
      {/* Tab Header */}
      {!isMobile && (
        <Tabs
          value={tabIndex}
          onChange={props.handleTabChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="fresources-tab"
          sx={{ mt: 2 }}
        >
          {resourceTabs.map((tab) => (
            <TabItem
              id={`resources-tab-${tab.name}`}
              key={tab.name}
              aria-controls={`resources-tabpanel-${tab.name}`}
              label={
                <ResourceTabSnippet
                  name={tab.name}
                  control={form.control}
                  onCheckAll={onAcceptAll}
                />
              }
              disableTouchRipple
            />
          ))}
        </Tabs>
      )}

      {/* Tab Content */}
      {!isMobile && (
        <FormProvider {...form}>
          <Box className={classes.tabContent}>
            <ResourceTabPanel
              active={tabIndex === 0}
              name="personnel"
              handleClick={handleCheck}
            />
            <ResourceTabPanel
              active={tabIndex === 1}
              name="equipment"
              handleClick={handleCheck}
            />
          </Box>
        </FormProvider>
      )}

      {/* Footer */}
      <Box className={classes.footer}>
        {isAccepted ? (
          <Button onClick={props.handleAccept(false, values)} fullWidth>
            Close
          </Button>
        ) : (
          <React.Fragment>
            <Button
              onClick={props.handleAccept(false, values)}
              neutral
              fullWidth
            >
              Cancel
            </Button>
            <Button
              progress={props.awaitSave}
              disabled={!hasEquipment && !hasPersonnel}
              onClick={props.handleAccept(true, values)}
              fullWidth
            >
              Accept
            </Button>
          </React.Fragment>
        )}
      </Box>
    </Box>
  );
};

export default AcceptResourcesView;
