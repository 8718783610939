import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { getAccount } from 'services/apiEndpoints';
import apiFetch from 'services/apiFetch';
import { AppState } from 'store/state/AppState';
import useSelectorSafe from 'store/selectors/useSelectorSafe';
import IAccount from 'common/interfaces/Account';
import { pageActions } from 'store/actions';
import { Nullable } from 'common/utils/Nullable';
import { ProfileFormProps } from 'helpers/forms/profileForm';
import {
  CoordinatorProfileProps,
  CoordinatorProfilePublicProps,
} from './CoordinatorProfile.props';
import CoordinatorProfileView from './CoordinatorProfile.view';

const dummyAccount = {
  pvAccountID: 123,
  pvAccountLocked: 0,
  pvAdministrator: 0,
  pvComment: '',
  pvCreateDate: '',
  pvEmail: '',
  pvEmailVerified: true,
  pvFirstName: 'John',
  pvGlobalID: '123-abc-456',
  pvLastName: 'Doe',
  pvLastUpdate: null,
  pvLocation: null,
  pvMiddleName: '',
  pvMobilePhone: '123-4567',
  pvOfficePhone: '123-4567',
  pvOrganizationUnitName: null,
  pvStatus: '',
  pvSupervisor: null,
  pvUsername: 'JA',
  pvAddress: '123 King Street',
};
const CoordinatorProfileContainer: React.FC<CoordinatorProfilePublicProps> = (
  ownProps: CoordinatorProfilePublicProps
) => {
  const dispatch = useDispatch();
  const app = useSelectorSafe((state: AppState) => state);
  const [account, setAccount] = useState<Nullable<IAccount>>(dummyAccount);
  const form = useForm(ProfileFormProps({ formData: dummyAccount }));

  useEffect(() => {
    if (app?.token && !account) {
      apiFetch(getAccount(app.token.id)).then((r) => {
        const records = r?.data?.records || [];
        // Get the first account from the array
        setAccount(records[0]);
      });
    }
  }, []);

  const combinedProps: CoordinatorProfileProps = {
    ...ownProps,
    account,
    form,
  };
  return <CoordinatorProfileView {...combinedProps} />;
};

export default CoordinatorProfileContainer;
