import { Dispatch } from 'redux';
import actions from 'store/actions/notification';
import apiFetch, {
  getNotificationRequestId,
  NotificationParams,
} from 'services/apiFetch';
import { QueryState } from 'common/enums';
import { isEmpty, isObject } from 'lodash';

export const loadNotificationByRequestId =
  (params: NotificationParams) => async (dispatch: Dispatch) => {
    // Initialize State
    dispatch(actions.fetchingNotification());

    const endpoint = getNotificationRequestId(params);
    const res = await apiFetch(endpoint).then((res) => res?.data || null);
    if (!isEmpty(res)) {
      //   console.log(res);
      const initialPageCount = (res && res?.count) || 0;
      const dataSet = res?.dataset || [];

      const notificationData = dataSet.map((v: any) => ({
        ...v,
        contactDetails: JSON.parse(v.contactDetails),
        requestDetails: JSON.parse(v.requestDetails),
        stationDetails: JSON.parse(v.stationDetails),
      }));
      dispatch(
        actions.responseNotification({
          initialPageCount,
          data: notificationData,
        })
      );
    }
  };
